import React, { useEffect } from "react";
import useStore from "../../../stores/store";
import PlayerBehaviour from "../../../library/PlayerBehaviour";
import walk from "../../../assets/images/walk.svg";
import landscapeImg from "../../../assets/images/landscape.svg";
import { useLocation } from "react-router-dom";
import useWindowOrientation from "use-window-orientation";

const MobileUI = () => {
  const toggleShowOnboarding = useStore((state) => state.toggleShowOnboarding);

  const mobileLandscape = useStore<boolean>((state) => state.mobileLandscape);
  //const setMobileLandscape = useStore((state) => state.setMobileLandscape);
  const playerBehaviour = useStore<PlayerBehaviour>(
    (state) => state.playerBehaviour
  );

  const location = useLocation();

  const { orientation, portrait, landscape } = useWindowOrientation();

  const showLoadingScreen = useStore<boolean>(
    (state) => state.showLoadingScreen
  );

  /*
  useEffect(() => {
    // https://stackoverflow.com/a/16567475
    if (window.matchMedia("(orientation: landscape)").matches) {
      setMobileLandscape(true);
    }

    const onOrientationChange = () => {
      setMobileLandscape(window.innerHeight > window.innerWidth);
    };

    window.addEventListener("orientationchange", onOrientationChange);

    return () =>
      window.removeEventListener("orientationchange", onOrientationChange);
  }, []);
*/

  const setMobileLandscape = useStore((state) => state.setMobileLandscape);
  /*
  useEffect(() => {
    // https://stackoverflow.com/a/16567475
    if (window.matchMedia("(orientation: landscape)").matches) {
      setMobileLandscape(true);
    }

    const onOrientationChange = () => {
      setMobileLandscape(window.innerHeight > window.innerWidth);
    };

    window.addEventListener("orientationchange", onOrientationChange);

    return () =>
      window.removeEventListener("orientationchange", onOrientationChange);
  }, []);*/

  const isMobileLandscape = () => {
    // console.log(
    //   "Is Mobile Landscape: " + !!navigator.maxTouchPoints &&
    //     !!window.screen.orientation.angle
    // );
    return !!navigator.maxTouchPoints && !!window.screen.orientation.angle;
  };

  const resizer = () => setMobileLandscape(isMobileLandscape());

  const showModalVideoStreaming = useStore<boolean>(
    (state) => state.showModalVideoStreaming
  );

  const showInfoControls = useStore<boolean>((state) => state.showInfoControls);

  useEffect(() => {
    window.addEventListener("orientationchange", resizer);

    window.onorientationchange = function (event) {
      // console.log(
      //   "the orientation of the device is now " +
      //     window.screen.orientation.angle
      // );
    };

    return window.removeEventListener("orientationchange", resizer);
  });

  return (!landscape || ((location.pathname == "/virtualspacesimplified" || showModalVideoStreaming) && !showLoadingScreen)) ? (
    <div
      className={`absolute bottom-0 right-0 flex w-full justify-center ${location.pathname == "/virtualspace" && showInfoControls ? "z-0" : "z-10"} `}
      style={{
        marginBottom: "2rem",
        //  touchAction: "none"
      }}
    >
      {/* <button
        onTouchStart={toggleShowOnboarding}
        className="w-12 h-12 md:w-16 md:h-16 flex justify-center items-center border-2 border-white rounded-full outline-none"
      >
        <img className="w-3 md:w-5" src={question} alt="Info" />
      </button> */}
      {location.pathname === "/virtualspace" ? (
        <button
          onTouchStart={(ev) => {
            ev.stopPropagation();
            playerBehaviour.direction[2] = 1;
          }}
          onTouchEnd={(ev) => {
            ev.stopPropagation();
            playerBehaviour.direction[2] = 0;
          }}
          className="w-20 h-20 md:w-28 md:h-28 lg:w-36 lg:h-36 flex justify-center items-center rounded-full outline-none select-none"
          style={{
            backgroundColor: "#ae2b42",
            // @ts-ignore
            backgroundImage: `url(${walk})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "2.5rem",
          }}
        ></button>
      ) : null}
    </div>
  ) : (
    <div className="absolute z-50 inset-0 flex flex-col w-full h-full justify-center items-center text-white bg-white">
      <div className="flex flex-col w-1/4">
        <img
          className={``}
          src={landscapeImg}
          alt="Landscape"
          style={{
            width:
              "100%" /* image box size as % of container, see step 1 */,
            height:
              "100%" /* image box size as % of container, see step 1 */,
            objectFit:
              "contain" /* matching of image pixels to image box, see step 2 */,
          }}
        />
      </div>
      <h2 className="mt-6 text-xl md:text-3xl text-rc-dark-gray px-12 text-center">
        {"Gira el dispositivo para continuar la experiencia"}
      </h2>
    </div>
  );
};

export default MobileUI;