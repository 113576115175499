import React from "react";

interface DialogsCtxState {
  isStreamingOn: boolean;
  setIsStreamingOn: React.Dispatch<React.SetStateAction<boolean>>;
}

const initialState: DialogsCtxState = {
  isStreamingOn: false,
  setIsStreamingOn: () => {},
};

export const StreamingContext = React.createContext<DialogsCtxState>(
  initialState
);

export const StreamingContextProvider: React.FC = ({ children }) => {
  const [isStreamingOn, setIsStreamingOn] = React.useState<boolean>(false);

  return (
    <StreamingContext.Provider
      value={{
        isStreamingOn,
        setIsStreamingOn,
      }}
    >
      {children}
    </StreamingContext.Provider>
  );
};
