import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from "react";
import { TextureLoader, GammaEncoding } from "three";
import { useLoader } from "react-three-fiber";
import InteractiveMesh from "./InteractiveMesh";
import useStore from "../../../stores/store";
import PlayerBehaviour from "../../../library/PlayerBehaviour";
import ReactGA from "react-ga";

interface TvProps {
  videoName: string;
  previewPath: string;
  videoPath: string;
  geometry: any;
  flipY?: boolean;
}

const Tv01: React.FC<TvProps & JSX.IntrinsicElements["mesh"]> = ({
  videoName,
  previewPath,
  videoPath,
  position,
  flipY = true,
  ...props
}) => {
  const previewTexture = useLoader(TextureLoader, previewPath);
  const [videoHasStarted, setVideoHasStarted] = useState(false);
  const isLoading = useRef(false);

  const video01 = useStore((state) => state.video01);
  const video02 = useStore((state) => state.video02);
  const video03 = useStore((state) => state.video03);
  const video04 = useStore((state) => state.video04);

  previewTexture.flipY = flipY;

  useEffect(() => {
    video01.src = videoPath;
    video01.muted = false;
    video01.preload = "auto";
    video01.loop = false;
    video01.setAttribute("playsinline", "playsinline");
    video01.crossOrigin = "anonymous";

    const callbackCanPlayThrough = () => {
      // console.log("fnaofasdofjs");
      isLoading.current = false;
    };

    const callbackPlaying = () => {
      //if (video.currentTime < 0.1) setVideoHasStarted(true);
      setVideoHasStarted(true);
      isLoading.current = false;
    };

    const callbackEnded = () => {
      video01.currentTime = 0;
      setVideoHasStarted(false);
    };

    video01.addEventListener("canplaythrough", callbackCanPlayThrough);

    video01.addEventListener("playing", callbackPlaying);

    video01.addEventListener("ended", callbackEnded);
    return () => {
      video01.removeEventListener("canplaythrough", callbackCanPlayThrough);
      video01.removeEventListener("playing", callbackPlaying);
      video01.removeEventListener("ended", callbackEnded);
    };
  }, [video01, videoPath]);

  const videoStreaming = useStore((state) => state.videoStreaming);

  const toggleVideo = () => {
    if (isLoading.current) return;

    if (video01.currentTime < 0.05) isLoading.current = true;

    if (video01.paused || video01.ended) {
      SendEvent(CLICKTV, "Pantalla Video");
      video01.play();
      video02.pause();
      video03.pause();
      video04.pause();
      videoStreaming.pause();
    } else {
      video01.pause();
    }
  };

  const useModalVideo = useStore<boolean>((state) => state.useModalVideo);
  const setShowModalVideo = useStore((state) => state.setShowModalVideo);
  const playerBehaviour = useStore<PlayerBehaviour>(
    (state) => state.playerBehaviour
  );
  const setModalVideoTitle = useStore((state) => state.setModalVideoTitle);
  const setModalVideoUrl = useStore((state) => state.setModalVideoUrl);

  const toggleModalVideo = () => {
    video01.pause();
    video02.pause();
    video03.pause();
    video04.pause();
    videoStreaming.pause();
    SendEvent(CLICKTV, "Pantalla Video");
    playerBehaviour.unlockCursor();
    setModalVideoTitle(videoName);
    setModalVideoUrl(videoPath);
    setShowModalVideo(true);
  };

  const SendEvent = (category: string, label: string) => {
    ReactGA.event({
      category, // Required
      action: "event", // Required
      label,
    });
  };

  const CLICKTV = "clickTV";

  return (
    <group position={position}>
      <InteractiveMesh
        {...props}
        visible={!videoHasStarted}
        onInteracted={useModalVideo ? toggleModalVideo : toggleVideo}
      >
        <meshBasicMaterial attach="material" map={previewTexture} />
      </InteractiveMesh>

      <mesh {...props} visible={videoHasStarted}>
        <meshBasicMaterial attach="material">
          <videoTexture
            attach="map"
            args={[video01]}
            encoding={GammaEncoding}
            flipY={flipY}
          />
        </meshBasicMaterial>
      </mesh>
    </group>
  );
};

export default Tv01;
