import React, { useContext, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import useStore from "../../stores/store";
import { log } from "../../utilities/utils";
import { StreamingContext } from "./StreamingContext";

interface StreamingProps {
  dates: string[];
}

const Streaming: React.FC<StreamingProps> = ({ dates }) => {
  const streamingToday = useStore<boolean>((state) => state.streamingToday);
  const streamingURL = useStore<string>((state) => state.streamingURL);
  const setStreamingToday = useStore((state) => state.setStreamingToday);
  const setStreamingLive = useStore((state) => state.setStreamingLive);
  const currentDate = new Date().toLocaleDateString("es-ES");
  const streamingLive = useStore<boolean>((state) => state.streamingLive);
  const checkIntervalTimeInSeconds = 5000;
  //console.log(currentDate);
  const video01 = useStore((state) => state.video01);
  const video02 = useStore((state) => state.video02);
  const video03 = useStore((state) => state.video03);
  const video04 = useStore((state) => state.video04);
  const videoStreaming = useStore((state) => state.videoStreaming);
  const activeScene = useStore<number>((state) => state.activeScene);

  /** @see {@tutorial https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state} */
  function usePrevious<T>(value: T) {
    const ref = useRef<T>();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current as T;
  }

  const previousActiveScene = usePrevious(activeScene);

  const { isStreamingOn, setIsStreamingOn } = useContext(StreamingContext);

  useEffect(() => {
    if (previousActiveScene !== activeScene) {
      // console.log("Pausing TV Streaming On Scene Change");
      videoStreaming.pause();
      video01.pause();
      video02.pause();
      video03.pause();
      video04.pause();
    }

    setStreamingToday(
      dates.some((streamingDate) => streamingDate === currentDate)
    );

    if (streamingToday) {
      const interval = setInterval(() => {
        log("Streaming is checking Live ON");

        //setStreamingLive(true)
        //return;
        fetch(streamingURL)
          .then(async (response) => {
            if (response.status === 200) {
              console.log("STREAMING");
              // Or what ever you want to check
              if (!isStreamingOn) {
                setStreamingLive(true);
                setIsStreamingOn(true);
              }
              log("Response from MUX Okay");
              return Promise.resolve(null); // This will end up in SUCCESS part
            } else if (response.status === 412) {
              if (isStreamingOn) {
                setStreamingLive(false);
                setIsStreamingOn(false);
              }
              log("Response from MUX 412");
              const responseInJson = await Promise.resolve(response.json());
              return Promise.reject(responseInJson.error.messages);
            } else {
              if (isStreamingOn) {
                setStreamingLive(false);
                setIsStreamingOn(false);
              }

              log("Response from MUX Other Error");
              throw new Error(response.statusText);
            }
          })
          .catch((catchError) => {
            if (isStreamingOn) {
              setStreamingLive(false);
              setIsStreamingOn(false);
            }
            log("Response from MUX Catch Error");
            log(isStreamingOn.toString());
            // console.log("Catch: ", catchError);
          })
          .finally(() => { });
      }, checkIntervalTimeInSeconds);

      return () => clearInterval(interval);
    }
  }, [
    activeScene,
    currentDate,
    dates,
    isStreamingOn,
    previousActiveScene,
    setIsStreamingOn,
    setStreamingLive,
    setStreamingToday,
    streamingToday,
    streamingURL,
    video01,
    video02,
    video03,
    videoStreaming,
  ]);

  return null;
};

export default Streaming;
