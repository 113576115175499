import React, { useEffect, useRef } from "react";
import {
  MemoryRouter,
  Route,
  BrowserRouter as Router,
  Switch,
  useHistory,
} from "react-router-dom";

import Crosshair from "./components/user_interface/layouts/Crosshair";
import DesktopUI from "./components/user_interface/devices/DesktopUI";
import Footer from "./components/user_interface/layouts/Footer";
import MobileUI from "./components/user_interface/devices/MobileUI";
import Onboarding from "./components/pages/Onboarding";
import Scene from "./components/three/common/Scene";
import useStore from "./stores/store";
import VirtualSpace from "./components/three/common/VirtualSpace";
import Header from "./components/user_interface/layouts/Header";
import SceneSelector from "./components/pages/SceneSelector";
import LoadingScene from "./components/user_interface/layouts/LoadingScene";
import VirtualSpaceSimplified from "./components/three/common/VirtualSpaceSimplified";
import Streaming from "./components/streaming/Streaming";
import ModalVideo from "./components/user_interface/modals/ModalVideo";
import ModalVideoStreaming from "./components/user_interface/modals/ModalVideoStreaming";
import Agenda from "./components/pages/Agenda"
import Repositorio from "./components/pages/Repositorio"
// import Chat from "./components/three/Chat";
import {
  checkCurrentPlatformName,
  checkCurrentPlatformOsFamily,
  checkCurrentPlatformOsVersion,
  checkCurrentPlatformProduct,
  isMobile,
  isProblematicIphoneVersion,
} from "./utilities/utils";
import useWindowOrientation from "use-window-orientation";
import CookieBanner from "react-cookie-banner";
import CookieConsent, { Cookies } from "react-cookie-consent";
import {
  StreamingContext,
  StreamingContextProvider,
} from "./components/streaming/StreamingContext";
import ModalVideoWithPDF from "./components/user_interface/modals/ModalVideoWithPDF";
import ModalVideoWelcome from "./components/user_interface/modals/ModalVideoWelcome";
import ModalPDF from "./components/user_interface/modals/ModalPDF";
import ModalImage from "./components/user_interface/modals/ModalImage";
import ModalLink from "./components/user_interface/modals/ModalLink";
import Landing from "./Landing";

function App() {
  const streamingToday = useStore<boolean>((state) => state.streamingToday);
  const smartsuppBtnDivRef = useRef<HTMLDivElement>();
  const smartsuppGnrlDivRef = useRef<HTMLDivElement>();

  const platformName = useStore<string>((state) => state.platformName);
  const platformProduct = useStore<string>((state) => state.platformProduct);
  const platformOsFamily = useStore<string>((state) => state.platformOsFamily);
  const platformOsVersion = useStore<string>(
    (state) => state.platformOsVersion
  );
  const setPlatformName = useStore((state) => state.setPlatformName);
  const setPlatformProduct = useStore((state) => state.setPlatformProduct);
  const setPlatformOsFamily = useStore((state) => state.setPlatformOsFamily);
  const setPlatformOsVersion = useStore((state) => state.setPlatformOsVersion);
  const setUseModalVideo = useStore((state) => state.setUseModalVideo);

  const mobileLandscape = useStore<boolean>((state) => state.mobileLandscape);

  const { orientation, portrait, landscape } = useWindowOrientation();

  const showLanding = useStore<boolean>((state) => state.showLanding);

  useEffect(() => {
    setPlatformName(checkCurrentPlatformName());

    setPlatformProduct(checkCurrentPlatformProduct());

    setPlatformOsFamily(checkCurrentPlatformOsFamily());

    setPlatformOsVersion(checkCurrentPlatformOsVersion());
  }, [
    setPlatformName,
    setPlatformOsFamily,
    setPlatformOsVersion,
    setPlatformProduct,
  ]);

  useEffect(() => {
    if (platformName === "" || platformOsFamily === "") return;

    // setUseModalVideo(
    //   // (platformOsFamily.includes("iOS") &&
    //   //   isProblematicIphoneVersion(platformOsVersion)) ||
    //   //   (platformName.includes("Safari") && platformOsFamily.includes("OS X"))
    //   platformOsFamily.includes("iOS") &&
    //     isProblematicIphoneVersion(platformOsVersion)
    // );

    setUseModalVideo(
      (platformOsFamily.includes("iOS") &&
        isProblematicIphoneVersion(platformOsVersion)) ||
      (platformName.includes("Safari") && platformOsFamily.includes("OS X"))
    );

    //console.log((platformOsFamily.includes("iOS") || platformName.includes("Safari")))
  }, [
    platformName,
    platformOsFamily,
    platformOsVersion,
    platformProduct,
    setUseModalVideo,
  ]);

  // Smartsupp hacking
  useEffect(() => {
    const interval = setInterval(() => {
      const div = document.querySelector<HTMLDivElement>("#chat-application");

      const iframe = document.querySelector<HTMLIFrameElement>(
        "#chat-application-iframe"
      );

      const button = iframe?.contentWindow?.document.querySelector<HTMLDivElement>(
        ".css-stivtw"
      );

      if (div && button) {
        smartsuppGnrlDivRef.current = div;
        smartsuppBtnDivRef.current = button;

        smartsuppGnrlDivRef.current.style.bottom = "0px";
        if (isMobile()) {
          smartsuppGnrlDivRef.current.style.top = "6px";
        }
        else {
          smartsuppGnrlDivRef.current.style.top = "14px";
          smartsuppGnrlDivRef.current.style.right = "84px";
        }
        smartsuppBtnDivRef.current.style.display = "flex";

        button.onclick = () => {
          isMobile()
            ? (div.style.top = "0px")
            : (div.style.top = "14px");
        };

        if (!streamingToday) {
          button.style.display = "none";
        }

        clearInterval(interval);
      }
    }, 250);

    return () => clearInterval(interval);
  }, [streamingToday]);

  /*
  const [state, setState] = useState({
    device: !!navigator.maxTouchPoints ? "mobile" : "computer",
    orientation: !navigator.maxTouchPoints
      ? "desktop"
      : !window.screen.orientation.angle
      ? "portrait"
      : "landscape",
  });

  useEffect(() => {
    window.addEventListener("resize", () =>
      setState({
        device: !!navigator.maxTouchPoints ? "mobile" : "computer",
        orientation: !navigator.maxTouchPoints
          ? "desktop"
          : !window.screen.orientation.angle
          ? "portrait"
          : "landscape",
      })
    );
  }, []);
*/
  /*
  const resizer = () => setUseMobileLandscape(isMobileLandscape());

  useEffect(() => {
    window.addEventListener("resize", resizer);

    return window.removeEventListener("resize", resizer);
  });
*/
  return showLanding ? (
    <Landing />
  ) : (
    <>
      <CookieConsent
        location="bottom"
        buttonText="Seguir navegando"
        cookieName="bi-cookies"
        style={{
          background: "#37424A",
          "margin-bottom": "3rem",
        }}
        buttonStyle={{
          background: "white",
          color: "#37424A",
          fontSize: "13px",
        }}
        expires={150}
      >
        {
          "Utilizamos cookies propias y de terceros con fines analíticos, para mejorar nuestros servicios y hacer un seguimiento del evento. Si continúa navegando, consideraremos que acepta su uso. "
        }
        <span className="underline">
          <a
            href={"https://www.grupoasis.com/politica-de-privacidad/"}
            target="_blank"
            className="cookie-link"
          >
            Más información
              </a>
        </span>
      </CookieConsent>
      <StreamingContextProvider>
        <MemoryRouter>
          <Streaming dates={["19/5/2021", "20/5/2021", "21/5/2021"]} />
          
          <ModalVideo />
          <ModalVideoWelcome />
          <ModalVideoWithPDF />
          <ModalVideoStreaming />
          <ModalPDF />
          <ModalImage />
          <ModalLink />

          <Header />
          <Footer />
          <div
            className="flex absolute inset-0 overflow-auto"
            style={{
              height: "100%",
              marginTop: "0",
              marginBottom: "0",
            }}
          >
            <Switch>
              <Route path="/sceneselector">
                {isMobile() ? <MobileUI /> : <DesktopUI />}
                {<SceneSelector />}
              </Route>
              <Route path="/virtualspace">
                {<LoadingScene />}
                {isMobile() ? <MobileUI /> : <DesktopUI />}
                {<VirtualSpace />}
              </Route>
              <Route path="/virtualspacesimplified">
                {<LoadingScene />}
                {isMobile() ? <MobileUI /> : <DesktopUI />}
                {<VirtualSpaceSimplified />}
              </Route>
              <Route path="/agenda">
                {<Agenda />}
                {isMobile() ? <MobileUI /> : <DesktopUI />}
              </Route>
              <Route path="/repositorio">
                {<Repositorio />}
                {isMobile() ? <MobileUI /> : <DesktopUI />}
              </Route>
              <Route path="/">
                <Onboarding />
              </Route>
            </Switch>
          </div>
        </MemoryRouter>
      </StreamingContextProvider>
    </>
  );
}

export default App;
