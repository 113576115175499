import React from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import useStore from "../../../stores/store";
//import ReactPlayer from 'react-player'
import PlayerBehaviour from "../../../library/PlayerBehaviour";
import Agenda from "../../../assets/images/agenda.jpg";
import { isMobile } from "../../../utilities/utils";

const ModalImage = () => {
  const playerBehaviour = useStore<PlayerBehaviour>(
    (state) => state.playerBehaviour
  );

  const onCloseModal = () => {
    setShowModalImage(false);
    playerBehaviour.lockCursor();
    playerBehaviour.release();
  };

  const showModalImage = useStore<boolean>((state) => state.showModalImage);
  const setShowModalImage = useStore((state) => state.setShowModalImage);

  return (
    <div>
      <Modal
        styles={{ modal: { inset: 0 } }}
        open={showModalImage}
        onClose={onCloseModal}
        center
      >
        <div className="flex w-full h-full items-center justify-center">
          <img
            className={``}
            src={Agenda}
            alt="Menu Main"
            style={{
              width: isMobile()
                ? "75%"
                : "100%" /* image box size as % of container, see step 1 */,
              height: isMobile()
                ? "75%"
                : "100%" /* image box size as % of container, see step 1 */,
              objectFit:
                "contain" /* matching of image pixels to image box, see step 2 */,
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default ModalImage;
