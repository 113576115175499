import React, { useMemo, useState, useEffect, useContext } from "react";
import Hls from "hls.js";
import { TextureLoader, GammaEncoding } from "three";
import { useLoader } from "react-three-fiber";
import InteractiveMesh from "./InteractiveMesh";
import useStore from "../../../stores/store";
import { useHistory } from "react-router-dom";
import PlayerBehaviour from "../../../library/PlayerBehaviour";
import { log } from "../../../utilities/utils";
import { StreamingContext } from "../../streaming/StreamingContext";
import ReactGA from "react-ga";

interface TvStreamingProps {
  previewPath: string;
  streamUrl: string;
  geometry: any;
  flipY?: boolean;
  checkIntervalTimeInSeconds: number;
}

const TvStreaming: React.FC<
  TvStreamingProps & JSX.IntrinsicElements["mesh"]
> = ({
  previewPath,
  streamUrl,
  position,
  flipY = true,
  checkIntervalTimeInSeconds = 1000,
  ...props
}) => {
    const previewTexture = useLoader(TextureLoader, previewPath);
    const [videoHasStarted, setVideoHasStarted] = useState(false);

    const streamingToday = useStore<boolean>((state) => state.streamingToday);

    //const { isStreamingOn } = useContext(StreamingContext);

    const streamingLive = useStore<boolean>((state) => state.streamingLive);
    const setStreamingLive = useStore((state) => state.setStreamingLive);

    const useModalVideo = useStore<boolean>((state) => state.useModalVideo);

    previewTexture.flipY = flipY;

    const setShowModalVideoStreaming = useStore(
      (state) => state.setShowModalVideoStreaming
    );

    const playerBehaviour = useStore<PlayerBehaviour>(
      (state) => state.playerBehaviour
    );

    const hls = new Hls();

    const activeScene = useStore<number>((state) => state.activeScene);

    const videoStreaming = useStore((state) => state.videoStreaming);

    const SendEvent = (category: string, label: string) => {
      ReactGA.event({
        category, // Required
        action: "event", // Required
        label,
      });
    };

    const CLICKTV = "clickTV";

    useEffect(() => {
      if (videoStreaming.paused && videoHasStarted) {
        setVideoHasStarted(false);
      }
    }, [videoHasStarted, videoStreaming.paused]);

    useEffect(() => {
      //console.log("Outside Use Effect");
      //console.log("Streaming Today: " + streamingToday);
      //console.log("Hls Is Supported: " + Hls.isSupported());

      if (!streamingLive) {
        // console.log("TV Streaming is releasing MUX signal");
        hls.detachMedia();
        videoStreaming.pause();
        setVideoHasStarted(false);
      }

      if (streamingLive && !hls.media && videoStreaming.paused) {
        //const interval = setInterval(() => {
        log("TV Streaming is getting MUX signal");
        //setStreamingLive(true)
        //return;
        fetch(streamUrl)
          .then(async (response) => {
            if (response.status === 200) {
              // Or what ever you want to check
              //if (!streamingLive) setStreamingLive(true);
              return Promise.resolve(null); // This will end up in SUCCESS part
            } else if (response.status === 412) {
              if (streamingLive) {
                //setStreamingLive(false);
                hls.detachMedia();
                videoStreaming.pause();
                setVideoHasStarted(false);
              }
              const responseInJson = await Promise.resolve(response.json());
              return Promise.reject(responseInJson.error.messages);
            } else {
              if (streamingLive) {
                //setStreamingLive(false);
                hls.detachMedia();
                videoStreaming.pause();
                setVideoHasStarted(false);
              }
              throw new Error(response.statusText);
            }
          })
          .then(
            (data) => {
              if (streamingLive && !hls.media) {
                hls.attachMedia(videoStreaming);

                hls.on(Hls.Events.MEDIA_ATTACHED, () => {
                  hls.loadSource(streamUrl);
                });

                hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
                  //console.log(
                  //  "manifest loaded, found " +
                  //    data.levels.length +
                  //    " quality level"
                  //);
                  // Disabled Auto Play Feature
                  //video.play();
                  //setVideoHasStarted(true);
                });

                hls.on(Hls.Events.ERROR, (event, data) => {
                  if (data.fatal) {
                    switch (data.type) {
                      case Hls.ErrorTypes.NETWORK_ERROR:
                        // try to recover network error
                        console.log(
                          "fatal network error encountered, try to recover"
                        );
                        hls.startLoad();
                        break;
                      case Hls.ErrorTypes.MEDIA_ERROR:
                        console.log(
                          "fatal media error encountered, try to recover"
                        );
                        hls.recoverMediaError();
                        break;
                      default:
                        // cannot recover
                        console.log("R. I. P.");
                        hls.destroy();
                        break;
                    }
                  }
                });
              }
            },
            (error) => {
              // ERROR part
              // Because we rejected responseInJson.message, error will contain message from api. In this case "Some nasty error message!"
              // console.log("Streaming Status: ", error);
            }
          )
          .catch((catchError) => {
            // console.log("Catch: ", catchError);
          })
          .finally(() => { });
        //}, checkIntervalTimeInSeconds);

        return () => {
          //console.log("Closing TV Streaming");
          //hls.detachMedia();
          //video.pause();
          //setVideoHasStarted(false);
        }; //clearInterval(interval);
      } else if (videoStreaming.canPlayType("application/vnd.apple.mpegurl")) {
        videoStreaming.src = streamUrl;
        // video.addEventListener("loadedmetadata", () => {
        //   video.play();
        // });
      }
    }, [hls, streamUrl, streamingLive, videoStreaming]);

    /*
    useEffect(() => {
      if (showModalVideoStreaming && !video.paused) toggleVideo();
    }, [showModalVideoStreaming]);
  */
    /*
    useEffect(
      () => () => {
        console.log("Closing TV Streaming");
        hls.detachMedia();
        video.pause();
        setVideoHasStarted(false);
      },
      []
    );
  */
    const video01 = useStore((state) => state.video01);
    const video02 = useStore((state) => state.video02);
    const video03 = useStore((state) => state.video03);
    const video04 = useStore((state) => state.video04);

    const toggleVideo = () => {
      if ((videoStreaming.paused || videoStreaming.ended) && streamingLive) {
        SendEvent(CLICKTV, "Pantalla Streaming");
        video01.pause();
        video02.pause();
        video03.pause();
        video04.pause();
        videoStreaming.play();
        setVideoHasStarted(true);
      } else {
        videoStreaming.pause();
        setVideoHasStarted(false);
      }
    };

    const toggleModalVideo = () => {
      video01.pause();
      video02.pause();
      video03.pause();
      video04.pause();
      videoStreaming.pause();
      SendEvent(CLICKTV, "Pantalla Streaming");
      playerBehaviour.unlockCursor();
      setShowModalVideoStreaming(true);
    };

    return (
      <group position={position}>
        <InteractiveMesh
          {...props}
          visible={!videoHasStarted}
          onInteracted={useModalVideo ? toggleModalVideo : toggleVideo}
        >
          <meshBasicMaterial attach="material" map={previewTexture} />
        </InteractiveMesh>

        <mesh {...props}>
          <meshBasicMaterial attach="material" visible={videoHasStarted}>
            <videoTexture
              attach="map"
              args={[videoStreaming]}
              encoding={GammaEncoding}
              flipY={flipY}
            />
          </meshBasicMaterial>
        </mesh>
      </group>
    );
  };

export default TvStreaming;
