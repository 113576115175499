/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useEffect, useRef, useState } from 'react'
import { GLTF, GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { useLoader } from 'react-three-fiber'
import { convertToBasicMaterials } from '../../../utilities/utils'
import InteractiveMesh from "../common/InteractiveMesh";
import useStore from "../../../stores/store";
import TvStreaming from "../common/TvStreaming";
import PlayerBehaviour from "../../../library/PlayerBehaviour";
import ReactGA from "react-ga";
import Countdown from 'react-countdown';

import {
  MSD_StandContent06,
} from "../../contents/SceneConferenceRoom";
import MSD_StandContent06_PreviewImage from "../../../assets/textures/previews/heroThumb.png";

import streamingWaitingThumb from "../../../assets/images/nuevos/streamingWaitingThumb.jpg";
import streamingPlayingThumb from "../../../assets/images/nuevos/streamingPlayingThumb.png";
import Miercoles from "../../../assets/images/streamingTv/miercoles.jpg";
import Jueves from "../../../assets/images/streamingTv/jueves.jpg";
import Viernes from "../../../assets/images/streamingTv/viernes.jpg";
import Sabado from "../../../assets/images/streamingTv/sabado.jpg";
import Domingo from "../../../assets/images/streamingTv/domingo.jpg";

import Tv01 from '../common/Tv01';
import Tv02 from '../common/Tv02';
import { MeshBasicMaterial, Texture, TextureLoader } from 'three';
import Poster from '../common/Poster';
import Trivial from "../../../assets/images/posters/trivial.jpg"
import Poster1 from "../../../assets/images/posters/1.jpg"
import Poster2 from "../../../assets/images/posters/2.jpg"
import Poster3 from "../../../assets/images/posters/3.jpg"
import Poster4 from "../../../assets/images/posters/4.jpg"
import Poster5 from "../../../assets/images/posters/5.jpg"
import Poster6 from "../../../assets/images/posters/6.jpg"
import Poster7 from "../../../assets/images/posters/7.jpg"
import Poster8 from "../../../assets/images/posters/8.jpg"
import Poster9 from "../../../assets/images/posters/9.jpg"
import Poster10 from "../../../assets/images/posters/10.jpg"
import Poster11 from "../../../assets/images/posters/11.jpg"
import Poster12 from "../../../assets/images/posters/12.jpg"

import VideoTv from "../../../assets/videos/videoTv.mp4"
import PreviewTv from "../../../assets/videos/previewTv.png"


type GLTFResult = GLTF & {
  nodes: {
    wallframe07: THREE.Mesh
    stream: THREE.Mesh
    wallframe06: THREE.Mesh
    Glass: THREE.Mesh
    wallframe05: THREE.Mesh
    wallframe00: THREE.Mesh
    wallframe01: THREE.Mesh
    wallframe02: THREE.Mesh
    wallframe03: THREE.Mesh
    wallframe04: THREE.Mesh
    video1: THREE.Mesh
    Pantallacompleta: THREE.Mesh
    concurso: THREE.Mesh
    frame11: THREE.Mesh
    frame10: THREE.Mesh
    frame09: THREE.Mesh
    frame08: THREE.Mesh
    frame07: THREE.Mesh
    frame06: THREE.Mesh
    frame05: THREE.Mesh
    frame03: THREE.Mesh
    frame00: THREE.Mesh
    frame01: THREE.Mesh
    frame02: THREE.Mesh
    exterior: THREE.Mesh
    carteles: THREE.Mesh
    frame04: THREE.Mesh
    booths: THREE.Mesh
    cabinas: THREE.Mesh
    Totem: THREE.Mesh
    video2: THREE.Mesh
    Stand: THREE.Mesh
    Arboles: THREE.Mesh
  }
  materials: {
    wallframe07: THREE.MeshStandardMaterial
    Stream: THREE.MeshStandardMaterial
    wallframe06: THREE.MeshStandardMaterial
    Glass: THREE.MeshStandardMaterial
    wallframe05: THREE.MeshStandardMaterial
    wallframe00: THREE.MeshStandardMaterial
    wallframe01: THREE.MeshStandardMaterial
    wallframe02: THREE.MeshStandardMaterial
    wallframe03: THREE.MeshStandardMaterial
    wallframe04: THREE.MeshStandardMaterial
    video1: THREE.MeshStandardMaterial
    pantallacompleta: THREE.MeshStandardMaterial
    Concurso: THREE.MeshStandardMaterial
    frame11: THREE.MeshStandardMaterial
    frame10: THREE.MeshStandardMaterial
    frame09: THREE.MeshStandardMaterial
    frame08: THREE.MeshStandardMaterial
    frame07: THREE.MeshStandardMaterial
    frame06: THREE.MeshStandardMaterial
    frame05: THREE.MeshStandardMaterial
    frame03: THREE.MeshStandardMaterial
    frame00: THREE.MeshStandardMaterial
    frame01: THREE.MeshStandardMaterial
    frame02: THREE.MeshStandardMaterial
    exterior: THREE.MeshStandardMaterial
    carteles: THREE.MeshStandardMaterial
    frame04: THREE.MeshStandardMaterial
    booths: THREE.MeshStandardMaterial
    cabinas: THREE.MeshStandardMaterial
    totem: THREE.MeshStandardMaterial
    Video2: THREE.MeshStandardMaterial
    stand: THREE.MeshStandardMaterial
    arboles: THREE.MeshStandardMaterial
  }
}

export default function Model(props: JSX.IntrinsicElements['group']) {
  const group = useRef<THREE.Group>()
  const { nodes, materials } = useLoader<GLTFResult>(
    GLTFLoader,
    "gltf/fitur/FITUR_IVS.gltf"
  );
  convertToBasicMaterials(materials);

  const streamingLive = useStore<boolean>((state) => state.streamingLive);
  const streamingURL = useStore<string>((state) => state.streamingURL);
  const playerBehaviour = useStore<PlayerBehaviour>(
    (state) => state.playerBehaviour
  );
  const videoStreaming = useStore((state) => state.videoStreaming);
  const showModalVideoStreaming = () => {
    playerBehaviour.unlockCursor();
    playerBehaviour.stop();
    setShowModalVideoStreaming(true);
  };

  const setShowModalVideoStreaming = useStore(
    (state) => state.setShowModalVideoStreaming
  );

  const setModalStreamingURL = useStore((state) => state.setModalStreamingURL);

  const textureMiercoles: Texture = useLoader(
    TextureLoader,
    Miercoles
  );
  textureMiercoles.flipY = false;

  const textureJueves: Texture = useLoader(
    TextureLoader,
    Jueves
  );
  textureJueves.flipY = false;

  const textureViernes: Texture = useLoader(
    TextureLoader,
    Viernes
  );
  textureViernes.flipY = false;

  const textureSabado: Texture = useLoader(
    TextureLoader,
    Sabado
  );
  textureSabado.flipY = false;

  const textureDomingo: Texture = useLoader(
    TextureLoader,
    Domingo
  );
  textureDomingo.flipY = false;

  const [dayTextureStreaming, setDayTextureStreaming] = useState(textureMiercoles)

  const [dayImageStreaming, setDayImageStreaming] = useState(Miercoles)

  useEffect(() => {
    GetImageByDate()
    GetStreamingModalURL()
  }, [streamingLive]);


  function GetImageByDate() {
    const currentDate = new Date().toLocaleDateString("es-ES");
    if (currentDate === "19/5/2021") { setDayTextureStreaming(textureMiercoles); setDayImageStreaming(Miercoles);}
    else if (currentDate === "20/5/2021") { setDayTextureStreaming(textureJueves); setDayImageStreaming(Jueves);}
    else if (currentDate === "21/5/2021") { setDayTextureStreaming(textureViernes); setDayImageStreaming(Viernes);}
    else if (currentDate === "22/5/2021") { setDayTextureStreaming(textureSabado); setDayImageStreaming(Sabado); }
    else if (currentDate === "23/5/2021") { setDayTextureStreaming(textureDomingo); setDayImageStreaming(Domingo); }
  };

  // function GetStreamingModalURL() {
  //   if (streamingLive) { setModalStreamingURL("https://stream.mux.com/esqo01mjgm8A01OF1b5XK4xIaDeWGhb02KmcGFJsYuvOaA.m3u8") }
  //   else {
  //     const currentDate = new Date().toLocaleDateString("es-ES");;
  //     if (currentDate === "19/5/2021") { setModalStreamingURL("https://www.youtube.com/embed/wHn1_QVoXGM"); }
  //     else if (currentDate === "20/5/2021") { setModalStreamingURL("https://www.youtube.com/embed/N2bEpRB4J68"); }
  //     else if (currentDate === "21/5/2021") { setModalStreamingURL("https://www.youtube.com/embed/2tR8SUdiZxc"); }
  //     else if (currentDate === "22/5/2021") { setModalStreamingURL("https://www.youtube.com/embed/2tR8SUdiZxc"); }
  //     else if (currentDate === "23/5/2021") { setModalStreamingURL("https://www.youtube.com/embed/2tR8SUdiZxc"); }
  //   }
  // };

  function GetStreamingModalURL() {
    if (streamingLive) { setModalStreamingURL("https://stream.mux.com/v4yTDYk7g8HWFz6JuFEqlmKYoTnd301rlYQ01ZcNb88oE.m3u8") }
  };

  const CLICKPOSTER = "clickPoster";
  const CLICKTV = "clickTV";
  const SendEvent = (category: string, label: string) => {
    ReactGA.event({
      category, // Required
      action: "event", // Required
      label,
    });
  };

  const setShowModalVideo = useStore((state) => state.setShowModalVideo);

  const renderer = ({ days, hours, minutes, seconds, completed }: { days: any, hours: any, minutes: any, seconds: any, completed: any }) => {
    if (completed) {
        console.log("7");
        setModalStreamingURL("https://www.youtube.com/embed/Ay5gx3KfjoA")

        return <></>
    } 
    else {
      if(((hours==5 && minutes<=30) || (hours<5)) && days==0 && !streamingLive){
        console.log("1");
        setModalStreamingURL("https://www.youtube.com/embed/VQWX2rUwVQ8")
      }
      else if(((hours==6 && minutes==0) || hours<6) && days==0 && !streamingLive){
        console.log("2");
        setModalStreamingURL("https://www.youtube.com/embed/zlfiz-9dOlY")
      }
      else if(((hours==6 && minutes<=30) || (hours<6)) && days==0 && !streamingLive){
        console.log("3");
        setModalStreamingURL("https://www.youtube.com/embed/JPuac7h0dE8")
      }
      else if(((hours==8 && minutes==0) || hours<8) && days==0 && !streamingLive){
        console.log("4");
        setModalStreamingURL("https://www.youtube.com/embed/qrqueQOcShk")
      }
      else if(((hours==11 && minutes==0) || hours<11) && days==0 && !streamingLive){
        console.log("5");
        setModalStreamingURL("https://www.youtube.com/embed/hTeQ6VmwSfY")
      }
      else{
        console.log("6");
        setModalStreamingURL("https://www.youtube.com/embed/u1XLIev3NBE")
      }
      
      return <></>

    }
  };


  return (
    <group ref={group} {...props} dispose={null}>
      <Countdown
        date={'2021-05-20T23:00:00'}
        renderer={renderer}
      />
      {new Date().toLocaleDateString("es-ES") === "22/5/2021" || new Date().toLocaleDateString("es-ES") === "23/5/2021" ? (
        <InteractiveMesh
          material={new MeshBasicMaterial({ map: dayTextureStreaming })}
          geometry={nodes.stream.geometry}
          position={[-7.744, 3.713, 7.682]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[1.000, 1.000, 1.000]}
          onInteracted={() => {
            playerBehaviour.stop();

            if (!videoStreaming.paused) {
              videoStreaming.pause();
            }

            SendEvent(CLICKTV, "Pantalla Streaming");
            showModalVideoStreaming();
          }}
        />

        // <Tv02
        //   videoName={VideoTv}
        //   previewPath={dayImageStreaming}
        //   videoPath={VideoTv}
        //   geometry={nodes.stream.geometry}
        //   position={[-7.744, 3.713, 7.682]}
        //   rotation={[Math.PI / 2, 0, 0]}
        //   scale={[1.000, 1.000, 1.000]}
        //   flipY={false}
        // />
      ) : (
        <>
          {streamingLive ? (
            <>
              <TvStreaming
                previewPath={
                  dayImageStreaming
                }
                streamUrl={streamingURL}
                geometry={nodes.stream.geometry}
                position={[-7.744, 3.713, 7.682]}
                rotation={[Math.PI / 2, 0, 0]}
                scale={[1.000, 1.000, 1.000]}
                flipY={false}
                checkIntervalTimeInSeconds={5000}
              />
            </>
          ) : (
            <>
              <InteractiveMesh
                material={new MeshBasicMaterial({ map: dayTextureStreaming })}
                geometry={nodes.stream.geometry}
                position={[-7.744, 3.713, 7.682]}
                rotation={[Math.PI / 2, 0, 0]}
                scale={[1.000, 1.000, 1.000]}
                onInteracted={() => {
                  playerBehaviour.stop();

                  if (!videoStreaming.paused) {
                    videoStreaming.pause();
                  }

                  SendEvent(CLICKTV, "Pantalla Streaming");
                  showModalVideoStreaming();
                }}
              />
            </>
          )}
        </>
      )}

      <InteractiveMesh
        material={materials.pantallacompleta}
        geometry={nodes.Pantallacompleta.geometry}
        position={[-7.920, 0.685, 7.756]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[1.000, 1.000, 1.000]}
        onInteracted={() => {
          playerBehaviour.stop();

          if (!videoStreaming.paused) {
            videoStreaming.pause();
          }

          SendEvent(CLICKTV, "Pantalla Streaming");
          showModalVideoStreaming();
        }}
      />

      <Tv01
        videoName={VideoTv}
        previewPath={PreviewTv}
        videoPath={VideoTv}
        geometry={nodes.video1.geometry}
        position={[1.5071, 1.834549, 4.427701]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[1.000, 1.000, 1.000]}
        flipY={false}
      />

      <Poster
        name="trivial"
        texturePath={Trivial}
        geometry={nodes.concurso.geometry}
        position={[-11.242, 0.749, 7.371]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[1.000, 1.000, 1.000]}
        flipY={false}
        onInteracted={() => {
          SendEvent(CLICKPOSTER, "Trivial");
          window.open("https://pr.easypromosapp.com/p/918659", "_blank")
        }}
      />

      <Poster
        name="p1"
        geometry={nodes.frame00.geometry}
        texturePath={Poster1}
        position={[-13.531, 1.631, 7.973]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[1, 1, 1]}
        flipY={false}
        onInteracted={() => {
          SendEvent(CLICKPOSTER, "Poster 1");
          window.open("https://www.turismodearagon.com/", "_blank")
        }}
      />

      <Poster
        name="p2"
        geometry={nodes.frame01.geometry}
        texturePath={Poster2}
        position={[-15.347, 1.631, 7.543]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[1, 1, 1]}
        onInteracted={() => {
          SendEvent(CLICKPOSTER, "Poster 2");
          window.open("https://www.zaragoza.es/sede/portal/turismo/", "_blank")
        }}
        flipY={false}
      />

      <Poster
        name="p3"
        geometry={nodes.frame02.geometry}
        texturePath={Poster3}
        position={[-16.448, 1.631, 6.123]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[1, 1, 1]}
        onInteracted={() => {
          SendEvent(CLICKPOSTER, "Poster 3");
          window.open("https://www.dphuesca.es/turismo", "_blank")
        }}
        flipY={false}
      />

      <Poster
        name="p4"
        geometry={nodes.frame03.geometry}
        texturePath={Poster4}
        position={[-16.866, 1.631, 4.324]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[1, 1, 1]}
        onInteracted={() => {
          SendEvent(CLICKPOSTER, "Poster 4");
          window.open("https://www.sienteteruel.es/", "_blank")
        }}
        flipY={false}
      />

      <Poster
        name="p5"
        geometry={nodes.frame04.geometry}
        texturePath={Poster5}
        position={[-16.866, 1.651, 2.502]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[1, 1, 1]}
        onInteracted={() => {
          SendEvent(CLICKPOSTER, "Poster 5");
          window.open("https://www.turismodearagon.com/aragon/provincia-de-huesca/", "_blank")
        }}
        flipY={false}
      />

      <Poster
        name="p6"
        geometry={nodes.frame05.geometry}
        texturePath={Poster6}
        position={[-16.866, 1.631, 0.860]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[1, 1, 1]}
        onInteracted={() => {
          SendEvent(CLICKPOSTER, "Poster 6");
          window.open("https://ordesasobrarbe.com/fitur", "_blank")
        }}
        flipY={false}
      />

      <Poster
        name="p7"
        geometry={nodes.frame06.geometry}
        texturePath={Poster7}
        position={[-16.866, 1.631, -0.782]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[1, 1, 1]}
        onInteracted={() => {
          SendEvent(CLICKPOSTER, "Poster 7");
          window.open("http://comarcacalatayud.com/", "_blank")
        }}
        flipY={false}
      />

      <Poster
        name="p8"
        geometry={nodes.frame07.geometry}
        texturePath={Poster8}
        position={[-16.866, 1.631, -2.591]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[1, 1, 1]}
        onInteracted={() => {
          SendEvent(CLICKPOSTER, "Poster 8");
          window.open("http://tdaragon.com/", "_blank")
        }}
        flipY={false}
      />

      <Poster
        name="p9"
        geometry={nodes.frame08.geometry}
        texturePath={Poster9}
        position={[-16.866, 1.631, -4.233]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[1, 1, 1]}
        onInteracted={() => {
          SendEvent(CLICKPOSTER, "Poster 9");
          window.open("https://www.campingsaragon.com/", "_blank")
        }}
        flipY={false}
      />

      <Poster
        name="p10"
        geometry={nodes.frame09.geometry}
        texturePath={Poster10}
        position={[-16.866, 1.631, -6.063]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[1, 1, 1]}
        onInteracted={() => {
          SendEvent(CLICKPOSTER, "Poster 10");
          window.open("https://aragonincoming.com/", "_blank")
        }}
        flipY={false}
      />

      <Poster
        name="p11"
        geometry={nodes.frame10.geometry}
        texturePath={Poster11}
        position={[-15.742, 1.631, -7.107]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[1, 1, 1]}
        onInteracted={() => {
          SendEvent(CLICKPOSTER, "Poster 11");
          window.open("http://www.enoturismodearagon.com/", "_blank")
        }}
        flipY={false}
      />

      <Poster
        name="p12"
        geometry={nodes.frame11.geometry}
        texturePath={Poster12}
        position={[-14.100, 1.631, -7.107]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[1, 1, 1]}
        onInteracted={() => {
          SendEvent(CLICKPOSTER, "Poster 12");
          window.open("https://www.birdingaragon.com/", "_blank")
        }}
        flipY={false}
      />

      <mesh
        geometry={nodes.wallframe07.geometry}
        material={materials.wallframe07}
        position={[2.0653, 1.414301, -3.5133]}
        rotation={[Math.PI / 2, 0, 0]}
      />
      {/* <mesh
        geometry={nodes.stream.geometry}
        material={materials.Stream}
        position={[-7.74365, 3.713449, 7.682001]}
        rotation={[Math.PI / 2, 0, 0]}
      /> */}
      <mesh
        geometry={nodes.wallframe06.geometry}
        material={materials.wallframe06}
        position={[3.852554, 2.857481, 7.042]}
        rotation={[Math.PI / 2, 0, 0]}
      />
      <mesh
        geometry={nodes.Glass.geometry}
        material={materials.Glass}
        position={[2.43515, 0.97058, 0.6403]}
        rotation={[Math.PI / 2, 0, 0]}
      />
      <mesh
        geometry={nodes.wallframe05.geometry}
        material={materials.wallframe05}
        position={[-7.6299, 2.249301, -7.5581]}
        rotation={[Math.PI / 2, 0, 0]}
      />
      <mesh
        geometry={nodes.wallframe00.geometry}
        material={materials.wallframe00}
        position={[4.233705, 2.519131, -5.763299]}
        rotation={[Math.PI / 2, 0, 0]}
      />
      <mesh
        geometry={nodes.wallframe01.geometry}
        material={materials.wallframe01}
        position={[-0.7939, 4.293091, 5.947092]}
        rotation={[Math.PI / 2, 0, 0]}
      />
      <mesh
        geometry={nodes.wallframe02.geometry}
        material={materials.wallframe02}
        position={[0.3746, 3.964275, 0.279351]}
        rotation={[Math.PI / 2, 0, 0]}
      />
      <mesh
        geometry={nodes.wallframe03.geometry}
        material={materials.wallframe03}
        position={[2.0653, 3.9643, -3.513299]}
        rotation={[Math.PI / 2, 0, 0]}
      />
      <mesh
        geometry={nodes.wallframe04.geometry}
        material={materials.wallframe04}
        position={[1.5431, 1.97224, 1.846754]}
        rotation={[Math.PI / 2, 0, 0]}
      />
      {/* <mesh
        geometry={nodes.video1.geometry}
        material={materials.video1}
        position={[1.5071, 1.834549, 4.427701]}
        rotation={[Math.PI / 2, 0, 0]}
      /> */}
      <mesh
        geometry={nodes.Pantallacompleta.geometry}
        material={materials.pantallacompleta}
        position={[-7.92045, 0.684699, 7.7563]}
        rotation={[Math.PI / 2, 0, 0]}
      />
      <mesh
        geometry={nodes.concurso.geometry}
        material={materials.Concurso}
        position={[-11.241699, 0.749382, 7.390975]}
        rotation={[Math.PI / 2, 0, 0]}
      />
      {/* <mesh
        geometry={nodes.frame11.geometry}
        material={materials.frame11}
        position={[-14.100301, 1.631401, -7.10655]}
        rotation={[Math.PI / 2, 0, 0]}
      />
      <mesh
        geometry={nodes.frame10.geometry}
        material={materials.frame10}
        position={[-15.7423, 1.631401, -7.10655]}
        rotation={[Math.PI / 2, 0, 0]}
      />
      <mesh
        geometry={nodes.frame09.geometry}
        material={materials.frame09}
        position={[-16.866249, 1.631401, -6.062799]}
        rotation={[Math.PI / 2, 0, 0]}
      />
      <mesh
        geometry={nodes.frame08.geometry}
        material={materials.frame08}
        position={[-16.866249, 1.631401, -4.2334]}
        rotation={[Math.PI / 2, 0, 0]}
      />
      <mesh
        geometry={nodes.frame07.geometry}
        material={materials.frame07}
        position={[-16.866249, 1.6314, -2.5914]}
        rotation={[Math.PI / 2, 0, 0]}
      />
      <mesh
        geometry={nodes.frame06.geometry}
        material={materials.frame06}
        position={[-16.866249, 1.6314, -0.7817]}
        rotation={[Math.PI / 2, 0, 0]}
      />
      <mesh
        geometry={nodes.frame05.geometry}
        material={materials.frame05}
        position={[-16.866249, 1.6314, 0.8603]}
        rotation={[Math.PI / 2, 0, 0]}
      />
      <mesh
        geometry={nodes.frame03.geometry}
        material={materials.frame03}
        position={[-16.866249, 1.631399, 4.323801]}
        rotation={[Math.PI / 2, 0, 0]}
      />
      <mesh
        geometry={nodes.frame00.geometry}
        material={materials.frame00}
        position={[-13.5308, 1.631399, 7.97345]}
        rotation={[Math.PI / 2, 0, 0]}
      />
      <mesh
        geometry={nodes.frame01.geometry}
        material={materials.frame01}
        position={[-15.34675, 1.631399, 7.543351]}
        rotation={[Math.PI / 2, 0, 0]}
      />
      <mesh
        geometry={nodes.frame02.geometry}
        material={materials.frame02}
        position={[-16.447674, 1.631399, 6.123451]}
        rotation={[Math.PI / 2, 0, 0]}
      /> */}
      <mesh
        geometry={nodes.exterior.geometry}
        material={materials.exterior}
        position={[0.325233, 13.401976, 0.927786]}
        rotation={[Math.PI / 2, 0, 0]}
      />
      <mesh
        geometry={nodes.carteles.geometry}
        material={materials.carteles}
        position={[-15.957839, 2.544815, -0.523298]}
        rotation={[Math.PI / 2, 0, 0]}
      />
      {/* <mesh
        geometry={nodes.frame04.geometry}
        material={materials.frame04}
        position={[-16.866249, 1.6314, 2.5023]}
        rotation={[Math.PI / 2, 0, 0]}
      /> */}
      <mesh
        geometry={nodes.booths.geometry}
        material={materials.booths}
        position={[-0.258947, 1.746062, 4.836162]}
        rotation={[Math.PI / 2, 0, 0]}
      />
      <mesh
        geometry={nodes.cabinas.geometry}
        material={materials.cabinas}
        position={[-15.913948, 1.337541, 1.6786]}
        rotation={[Math.PI / 2, 0, 0]}
      />
      <mesh
        geometry={nodes.Totem.geometry}
        material={materials.totem}
        position={[-2.015954, 1.346372, 7.34894]}
        rotation={[Math.PI / 2, 0, 0]}
      />
      <mesh
        geometry={nodes.video2.geometry}
        material={materials.Video2}
        position={[-2.0516, 1.536949, 7.224501]}
        rotation={[Math.PI / 2, 0, 0]}
      />
      <mesh
        geometry={nodes.Stand.geometry}
        material={materials.stand}
        position={[-1.119211, 2.210024, 1.62075]}
        rotation={[Math.PI / 2, 0, 0]}
      />
      <mesh
        geometry={nodes.Arboles.geometry}
        material={materials.arboles}
        position={[-4.624524, 2.603387, 0.713918]}
        rotation={[Math.PI / 2, 0, 0]}
      />
    </group>
  )
}

