import React from "react";
import useStore from "../../../stores/store";
import esc from "../assets/images/esc.svg";
import question from "../assets/images/question.svg";
import Instructions from "../instructions/Instructions";
import { useLocation } from "react-router-dom";
import RepeatVideo from "../../../assets/images/repeat_video.png";
import { AnimatePresence, motion } from "framer-motion";

const DesktopUI = () => {
  const toggleShowOnboarding = useStore((state) => state.toggleShowOnboarding);

  const location = useLocation();

  const setShowModalVideoWelcome = useStore(
    (state) => state.setShowModalVideoWelcome
  );
  const setModalVideoWelcomeTitle = useStore(
    (state) => state.setModalVideoWelcomeTitle
  );
  const setModalVideoWelcomeVideoUrl = useStore(
    (state) => state.setModalVideoWelcomeVideoUrl
  );

  const currentDate = new Date().getTime();

  const timeToShowVideo = new Date(
    "Tue Apr 13 2021 15:15:00 GMT+0200"
  ).getTime();

  // const timeToShowVideo = new Date(
  //   "Tue Apr 6 2021 15:15:00 GMT+0200"
  // ).getTime();

  const showInfoControls = useStore<boolean>((state) => state.showInfoControls);

  return (
    <>
      {/*<div className="absolute top-0 left-0 mt-4 ml-4 flex items-end">
        <img className="w-8" src={esc} alt="Escape" />
  </div>*/}

      {location.pathname === "/virtualspace" && !showInfoControls ? (
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1}}
            exit={{ opacity: 0 }}
            className="absolute bottom-0 bg-gray-50 left-0 py-2 px-2 opacity-90 flex flex-col rounded-md justify-center items-center bg-no-repeat bg-center bg-cover z-10"
            style={{
              marginBottom: "1.5rem",
              marginLeft: "1.5rem",
            }}
          >
            <Instructions />
          </motion.div>
        </AnimatePresence>
       
      ) : null}

      <div className="absolute bottom-0 right-0 mb-20 mr-4">
        {/* <button
          onClick={toggleShowOnboarding}
          className="w-12 h-12 flex justify-center items-center border-2 border-white rounded-full outline-none"
        >
          <img className="w-3" src={question} alt="Info" />
        </button> */}
      </div>
    </>
  );
};

export default DesktopUI;
