import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { isMobile } from "../../../utilities/utils";
import useStore from "../../../stores/store";
import { Modal } from "react-responsive-modal";
import useWindowOrientation from "use-window-orientation";

import logo from "../../../assets/images/infoControls/logo-ted.jpg";
import bg from "../../../assets/images/infoControls/bg-onboarding-ted.jpg";
import touchswipe from "../../../assets/images/infoControls/touchswipe.svg";
import walk from "../../../assets/images/infoControls/walk2.svg";
import PlayerBehaviour from "../../../library/PlayerBehaviour";
//import "../styles/ted.css"
import Arrows from "../../../assets/images/SVG/avanzar_teclas.svg";
import Puntero from "../../../assets/images/SVG/puntero_icon.svg";
import Esc from "../../../assets/images/SVG/verpuntero_esc.svg";
import Mover from "../../../assets/images/SVG/mover_icon.svg";
import Mouse from "../../../assets/images/SVG/girar_raton.svg";
import GirarMouse from "../../../assets/images/SVG/girar_icon.svg";
import MovilAvanza from "../../../assets/images/SVG/onb_movil_avanza.svg";
import MovilArrastra from "../../../assets/images/SVG/onb_movil_arrastra.svg";
import MovilToca from "../../../assets/images/SVG/onb_movil_toca.svg";
import Flecha from "../../../assets/images/SVG/flecha_icon.svg";
import Bolo1 from "../../../assets/images/SVG/bolo_onb.svg";
import Bolo2 from "../../../assets/images/SVG/bolo_onb2.svg";

const InfoControls = () => {
  const showInfoControls = useStore<boolean>((state) => state.showInfoControls);
  const setShowInfoControls = useStore((state) => state.setShowInfoControls);
  const playerBehaviour = useStore<PlayerBehaviour>((state) => state.playerBehaviour);
  const mobile = isMobile();

  const start = () => {
    if (!mobile) playerBehaviour.lockCursor();
    setShowInfoControls(false);
  };


  const [mytest, setmytest] = useState(0);
  const stopInfoControls = () => {
    setmytest(mytest + 1);
    if (mytest == 2) {
      start();
    }
  };
  const { landscape } = useWindowOrientation();

  return (
    <AnimatePresence>
      {/*Desktop*/}
      {showInfoControls && !mobile && (
        <motion.div
          initial={{ opacity: 1 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="absolute inset-0 z-0 flex flex-col justify-center bg-no-repeat bg-center bg-cover bg-black bg-opacity-75" 
          style={{bottom:"6vw", top:"6vw"}}
        >

          <div className="flex flex-col items-center text-black bg-white" style={{ margin: "0vw 17vw" }}>
            <div style={{ padding: "2vw 0vw" }}>
              <div className="text-center font-montserrat-bold" style={{ fontSize: "2vw", paddingBottom: "4vw" }}>
                <p>Explora el stand virtual de Aragón</p>
              </div>
              <div className="flex justify-center">
                <div className="flex flex-col items-center gap-2 w-1/3 mr-6">
                  <img
                    style={{ width: "7vw" }}
                    src={Arrows}
                    alt="Arrows"
                  />
                  <div className="flex flex-row items-center gap-2">
                    <img
                      style={{ width: "1.3vw" }}
                      src={Mover}
                      alt="Arrows"
                    />
                    <div className="font-montserrat-bold" style={{ fontSize: "1.3vw" }}>
                      avanzar
                      </div>
                  </div>
                  <p className="text-center font-montserrat-normal" style={{ fontSize: "0.9vw" }}>
                    Utiliza las flechas A,W,S,D para moverte por la sala
                    </p>
                </div>
                <div className="flex flex-col items-center gap-2 w-1/3 mr-6">
                  <img
                    style={{ width: "5.72vw" }}
                    src={Mouse}
                    alt="Arrows"
                  />
                  <div className="flex flex-row items-center gap-2">
                    <img
                      style={{ width: "1.3vw" }}
                      src={GirarMouse}
                      alt="Arrows"
                    />
                    <div className="font-montserrat-bold" style={{ fontSize: "1.3vw" }}>
                      girar
                      </div>
                  </div>
                  <p className="text-center font-montserrat-normal" style={{ fontSize: "0.9vw" }}>
                    Mueve el ratón para cambiar la vista
                    </p>
                </div>
                <div className="flex flex-col items-center gap-2 w-1/3">
                  <img
                    style={{ width: "5.15vw" }}
                    src={Esc}
                    alt="Arrows"
                  />
                  <div className="flex flex-row items-center gap-2">
                    <img
                      style={{ width: "1vw" }}
                      src={Puntero}
                      alt="Arrows"
                    />
                    <div className="font-montserrat-bold" style={{ fontSize: "1.3vw" }}>
                      ver puntero
                      </div>
                  </div>
                  <p className="text-center font-montserrat-normal" style={{ fontSize: "0.9vw" }}>
                    Recupera el puntero para interactuar
                  </p>
                </div>
              </div>

              <div className="flex justify-center" style={{ paddingTop: "2vw" }}>
                <button
                  onClick={() =>
                    start()
                  }
                  className="flex justify-center text-white rounded-full font-montserrat-normal
                              transition duration-500 transform hover:scale-110"
                  style={{ background: "#ae2b42", padding: "1vw 1.5vw 1vw 1.5vw", fontSize: "1.3vw" }}
                >
                  Comenzar
                </button>
              </div>
            </div>
          </div>
        </motion.div>
      )
      }

      {/*Mobile*/}
      {
        showInfoControls && mobile && (
          <motion.div
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="absolute inset-0 z-0 flex flex-col justify-center bg-no-repeat bg-center bg-cover bg-black bg-opacity-75 top-16 md:top-32"
          >

            <div className="flex flex-col rounded items-center text-black bg-white mx-4 md:mx-44 lg:mx-56">
              <div className="px-4 py-4 md:px-8 md:pt-8 md:pb-4" >
                {mytest === 0 && (
                  <>
                    <div className="text-center text-xl font-montserrat-bold mb-6 lg:mb-12 md:text-3xl lg:text-4xl">
                      <p>Explora el stand virtual de Aragón</p>
                    </div>
                    <div className="flex flex-col justify-center items-center mx-4">
                      <img
                        className="h-36 md:h-44 lg:h-52 mb-8 lg:mb-8"
                        src={MovilAvanza}
                        alt=""
                      />
                      <p className="font-montserrat-bold md:text-xl lg:text-2xl">Mantén presionado</p>
                      <p className="text-center mb-4 font-montserrat-normal md:text-xl lg:text-2xl">
                        para avanzar
                        </p>
                    </div>
                    <div className="flex flex-row justify-center mb-8 lg:mb-12 gap-1">
                      <img
                        className="h-2 -mt-0.5"
                        src={Bolo1}
                        alt=""
                      />
                      <img
                        className="h-1"
                        src={Bolo2}
                        alt=""
                      />
                      <img
                        className="h-1"
                        src={Bolo2}
                        alt=""
                      />
                    </div>
                    <div className="flex flex-row justify-end">
                      <button onClick={() => {
                        stopInfoControls()
                      }}>
                        <div className="flex flex-row">
                          <div className="font-montserrat-bold text-sm mr-2 md:text-xl lg:text-2xl" style={{ color: "#c1213d" }}>Siguiente</div>
                          <div>
                            <img
                              className="h-6 lg:h-8"
                              src={Flecha}
                              alt="Arrows"
                            />
                          </div>
                        </div>
                      </button>
                    </div>
                  </>
                )}
                {mytest === 1 && (
                  <>
                    <div className="text-center text-xl font-montserrat-bold mb-6 md:text-3xl lg:mb-12 lg:text-4xl">
                      <p>Explora el stand virtual de Aragón</p>
                    </div>
                    <div className="flex flex-col items-center mx-4">
                      <img
                        className="h-36 md:h-44 mb-8 lg:h-52 lg:mb-8"
                        src={MovilArrastra}
                        alt=""
                      />
                      <p className="font-montserrat-bold md:text-xl  lg:text-2xl">Arrastra</p>
                      <p className="text-center mb-4 font-montserrat-normal md:text-xl  lg:text-2xl">
                        para cambiar de dirección
                        </p>
                    </div>
                    <div className="flex flex-row justify-center mb-8 lg:mb-12 gap-1">
                      <img
                        className="h-1"
                        src={Bolo2}
                        alt=""
                      />
                      <img
                        className="h-2 -mt-0.5"
                        src={Bolo1}
                        alt=""
                      />
                      <img
                        className="h-1"
                        src={Bolo2}
                        alt=""
                      />
                    </div>
                    <div className="flex flex-row justify-end">
                      <button onClick={() => {
                        stopInfoControls()
                      }}>
                        <div className="flex flex-row">
                          <div className="font-montserrat-bold text-sm mr-2 md:text-xl lg:text-2xl" style={{ color: "#c1213d" }}>Siguiente</div>
                          <div>
                            <img
                              className="h-6 lg:h-8"
                              src={Flecha}
                              alt="Arrows"
                            />
                          </div>
                        </div>
                      </button>
                    </div>
                  </>
                )}
                {mytest === 2 && (
                  <>
                    <div className="text-center text-xl font-montserrat-bold mb-6 md:text-3xl lg:mb-12 lg:text-4xl">
                      <p>Explora el stand virtual de Aragón</p>
                    </div>
                    <div className="flex flex-col items-center mx-4">
                      <img
                        className="h-36 md:h-44 mb-4 lg:h-52 lg:mb-8"
                        src={MovilToca}
                        alt=""
                      />
                      <p className="md:text-xl lg:text-2xl"><strong>Apunta</strong> con el cursor y</p>
                      <p className="text-center mb-4 font-montserrat-normal md:text-xl  lg:text-2xl">
                        <strong>toca</strong> para interactuar
                        </p>
                    </div>
                    <div className="flex flex-row justify-center mb-6 lg:mb-12 gap-1">
                      <img
                        className="h-1"
                        src={Bolo2}
                        alt=""
                      />
                      <img
                        className="h-1"
                        src={Bolo2}
                        alt=""
                      />
                      <img
                        className="h-2 -mt-0.5"
                        src={Bolo1}
                        alt=""
                      />
                    </div>
                    <div className="flex flex-row justify-center">
                      <button className="rounded-full px-4 py-3 lg:px-4 lg:py-4" style={{ background: "#ae2b42" }}
                        onClick={() => {
                          stopInfoControls()
                        }}>
                        <div className="flex flex-row">
                          <div className="text-base text-white font-montserrat-normal lg:text-2xl">Comenzar</div>
                        </div>
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </motion.div>
        )
      }
    </AnimatePresence >
  );

};

export default InfoControls;