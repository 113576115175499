import React, { useEffect, useRef, useState } from "react";
import conferenceRoom from "../../assets/images/conferenceRoom2D.png";
import PlayerBehaviour from "../../library/PlayerBehaviour";
import useStore from "../../stores/store";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import {
  VIDEO_LINK_Event01,
  VIDEO_LINK_Event02,
  VIDEO_LINK_Event03,
} from "../contents/SceneConferenceRoom";
import ReactPlayer from "react-player";
import ReactGA from "react-ga";
import { isMobile } from "../../utilities/utils";
import Directo2 from "../../assets/images/SVG/directo_v2.svg"
import Flecha from "../../assets/images/SVG/flecha_icon.svg"
import Countdown from 'react-countdown';


const SceneConferenceRoom2D = () => {
  const playerBehaviour = useStore<PlayerBehaviour>(
    (state) => state.playerBehaviour
  );

  const onCloseModal = () => {
    setShowModalVideoStreaming(false);
    //setShowChat(false);
    playerBehaviour.lockCursor();
    playerBehaviour.release();
  };

  const showModalVideoStreaming = useStore<boolean>(
    (state) => state.showModalVideoStreaming
  );
  const streamingURL = useStore<string>((state) => state.streamingURL);
  const setShowModalVideoStreaming = useStore(
    (state) => state.setShowModalVideoStreaming
  );
  const setShowChat = useStore((state) => state.setShowChat);

  const container = useRef<any>(null);

  const SendEvent = (category: string, label: string) => {
    ReactGA.event({
      category, // Required
      action: "event", // Required
      label,
    });
  };

  const mobile = isMobile();

  const history = useHistory();

  const setShowLoadingScreen = useStore((state) => state.setShowLoadingScreen);

  const CLICKBUTTON = "clickButton";
  const CLICKPOSTER = "clickPoster";

  const streamingLive = useStore<boolean>((state) => state.streamingLive);

  const [dayStreaming, setDayStreaming] = useState("")

  useEffect(() => {
    SendEvent("Sala2D", "Streaming")
    //GetStreamingByDate()
  }, []);

  // function GetStreamingByDate() {
  //   const currentDate = new Date().toLocaleDateString("es-ES");
  //   if (currentDate === "19/5/2021") { setDayStreaming("https://www.youtube.com/embed/wHn1_QVoXGM")}
  //   else if (currentDate === "20/5/2021") { setDayStreaming("https://www.youtube.com/embed/N2bEpRB4J68") }
  //   else if (currentDate === "21/5/2021") { setDayStreaming("https://www.youtube.com/embed/2tR8SUdiZxc"); }
  //   else if (currentDate === "22/5/2021") { setDayStreaming("https://www.youtube.com/embed/N2bEpRB4J68") }
  //   else if (currentDate === "23/5/2021") { setDayStreaming("https://www.youtube.com/embed/_mOAXaPVtD0"); }
  // };

  function GetStreamingByDate() {
    const currentDate = new Date().toLocaleDateString("es-ES");
    if (currentDate === "19/5/2021") { setDayStreaming("")}
    else if (currentDate === "20/5/2021") { setDayStreaming("") }
    else if (currentDate === "21/5/2021") { setDayStreaming(""); }
    else if (currentDate === "22/5/2021") { setDayStreaming("") }
    else if (currentDate === "23/5/2021") { setDayStreaming(""); }
  };

  const renderer = ({ days, hours, minutes, seconds, completed }: { days: any, hours: any, minutes: any, seconds: any, completed: any }) => {
    if (completed) {
      console.log("7");
      setDayStreaming("https://www.youtube.com/embed/Ay5gx3KfjoA")

      return <></>
  } 
  else {
    if(((hours==5 && minutes<=30) || (hours<5)) && days==0 && !streamingLive){
      console.log("1");
      setDayStreaming("https://www.youtube.com/embed/VQWX2rUwVQ8")
    }
    else if(((hours==6 && minutes==0) || hours<6) && days==0 && !streamingLive){
      console.log("2");
      setDayStreaming("https://www.youtube.com/embed/zlfiz-9dOlY")
    }
    else if(((hours==6 && minutes<=30) || (hours<6)) && days==0 && !streamingLive){
      console.log("3");
      setDayStreaming("https://www.youtube.com/embed/JPuac7h0dE8")
    }
    else if(((hours==8 && minutes==0) || hours<8) && days==0 && !streamingLive){
      console.log("4");
      setDayStreaming("https://www.youtube.com/embed/qrqueQOcShk")
    }
    else if(((hours==11 && minutes==0) || hours<11) && days==0 && !streamingLive){
      console.log("5");
      setDayStreaming("https://www.youtube.com/embed/hTeQ6VmwSfY")
    }
    else{
      console.log("6");
      setDayStreaming("https://www.youtube.com/embed/u1XLIev3NBE")
    }
      
      return <></>

    }
  };
  

  return !mobile ? (
    <div
      className="flex flex-row h-full justify-center items-center"
      style={{ backgroundColor: "#f2f2f2" }}
      ref={container}
    >
      <Countdown
        date={'2021-05-20T23:00:00'}
        renderer={renderer}
      />
      <div className={`flex flex-col w-2/6 items-start`} style={{ paddingLeft: "7vw" }}>
        <img
          style={{ width: "6vw", paddingBottom: "0.5vw" }}
          src={Directo2}
          alt="Arrows"
        />
        <p className="font-montserrat-bold" style={{ fontSize: "2vw" }}>Evento en directo</p>
        <p className="font-monserrat-normal" style={{ fontSize: "1vw", paddingBottom: "10vw" }}>🟢 EMITIENDO</p>
        <p className="font-monserrat-normal" style={{ fontSize: "1.3vw", paddingBottom: "2vw" }}> TAMBIÉN TE SUGERIMOS:</p>
        <button className="flex flex-row transition duration-500 transform hover:scale-110">
          <div className="font-montserrat-bold" style={{ color: "#c1213d", fontSize: "1.2vw", paddingRight: "1vw", paddingBottom: "1vw" }}
            onClick={() => {
              history.push("/agenda");
              setShowLoadingScreen(true);
            }}
          >Agenda</div>
          <div>
            <img
              style={{ height: "1.5vw" }}
              src={Flecha}
              alt="Arrows"
            />
          </div>
        </button>
        <button className="flex flex-row transition duration-500 transform hover:scale-110">
          <div className="font-montserrat-bold" style={{ color: "#c1213d", fontSize: "1.2vw", paddingRight: "1vw", paddingBottom: "1vw" }}
            onClick={() => {
              history.push("/repositorio");
              setShowLoadingScreen(true);
            }}
          >Ver Presentaciones</div>
          <div>
            <img
              style={{ height: "1.5vw" }}
              src={Flecha}
              alt="Arrows"
            />
          </div>
        </button>
      </div>
      <div
        className="flex w-full h-full justify-evenly items-center"
      >
        <ReactPlayer
          playsinline
          controls
          playing
          url={streamingLive ? streamingURL : dayStreaming}
          id="my-video"
          className="video-js vjs-default-skin vjs-16-9 vjs-big-play-centered"
          width={"80%"}
          height={"60%"}
          margin="auto"
          style={{
            objectFit: "contain",
          }}
        />
      </div>
    </div>
  ) : (
    <div
      className="flex flex-col h-full justify-center items-start"
      style={{ backgroundColor: "#f2f2f2" }}
      ref={container}
    >
      <Countdown
        date={'2021-05-20T23:00:00'}
        renderer={renderer}
      />
      <div className="flex flex-col h-4/5 w-full">
        <div className="flex flex-row items-start pl-6 pt-6 md:pl-20 mb-12">
          <img
            className="h-14 pt-2 mr-2 md:h-20 lg:h-28"
            src={Directo2}
            alt="Arrows"
          />
          <div>
            <p className="text-2xl md:text-4xl lg:text-5xl font-montserrat-bold pt-2">Evento en directo</p>
            <p className="text-sm md:text-xl lg:text-2xl font-monserrat-normal" style={{ paddingBottom: "2vh" }}>🟢 EMITIENDO</p>
          </div>
        </div>
        <div
          className="flex w-full justify-evenly items-center md:mt-12 md:mb-12 lg:mt-20 lg:mb-20"
          style={{
            height: "40%",
            paddingBottom: "20vw"
          }}
        >
          <ReactPlayer
            playsinline
            controls
            playing
            url={streamingLive ? streamingURL : dayStreaming}
            id="my-video"
            className="video-js vjs-default-skin vjs-16-9 vjs-big-play-centered"
            width={"80%"}
            height="auto"
            margin="auto"
            style={{
              objectFit: "contain",
            }}
          />
        </div>
        <div className="items-start justify-start md:pl-12">
          <p className="mb-4 pl-8 text-sm font-monserrat-light md:text-2xl lg:text-3xl"> TAMBIÉN TE SUGERIMOS:</p>
          <div className="flex flex-row">
            <div className="font-montserrat-bold text-sm pl-8 mr-2 mb-2 md:text-2xl lg:text-3xl" style={{ color: "#c1213d" }}
              onClick={() => {
                history.push("/agenda");
                setShowLoadingScreen(true);
              }}
            >Agenda</div>
            <div>
              <img
                className="h-5 md:h-8 lg:h-10"
                src={Flecha}
                alt="Arrows"
              />
            </div>
          </div>
          <div className="flex flex-row">
            <div className="font-montserrat-bold text-sm pl-8 mr-2 mb-2 md:text-2xl lg:text-3xl" style={{ color: "#c1213d" }}
              onClick={() => {
                history.push("/repositorio");
                setShowLoadingScreen(true);
              }}
            >Ver Presentaciones</div>
            <div>
              <img
                className="h-5 md:h-8 lg:h-10"
                src={Flecha}
                alt="Arrows"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SceneConferenceRoom2D;