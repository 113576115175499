import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { isMobile } from "../../../utilities/utils";
import useStore from "../../../stores/store";
import PlayerBehaviour from "../../../library/PlayerBehaviour";
import Spinner from "../../../assets/images/nuevos/spinner.png"
import "../../../styles/spinner.css"
import { PropagateLoader, ClipLoader } from "react-spinners";
{/*import SpinningImage from 'react-native-spinning-image';*/ }

const LoadingScene = () => {
  const playerBehaviour = useStore<PlayerBehaviour>(
    (state) => state.playerBehaviour
  );
  const mobile = isMobile();

  const showLoadingScreen = useStore<boolean>(
    (state) => state.showLoadingScreen
  );
  const setShowLoadingScreen = useStore((state) => state.setShowLoadingScreen);

  const visualizationMode3D = useStore<boolean>(
    (state) => state.visualizationMode3D
  );

  useEffect(() => {
    if (showLoadingScreen) {
      setTimeout(() => {
        if (!mobile && visualizationMode3D) playerBehaviour.lockCursor();

        setShowLoadingScreen(false);
      }, 7000);
    }
  }, [
    mobile,
    playerBehaviour,
    setShowLoadingScreen,
    showLoadingScreen,
    visualizationMode3D,
  ]);

  return (
    <AnimatePresence>
      {showLoadingScreen && (
        <motion.div
          initial={{ opacity: 1 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="flex h-full w-full justify-center items-center z-20"
          style={{ background: "#f2f2f2" }}
        >
          <div className={`flex flex-col ${isMobile() ? "w-2/3" : "w-1/2"
            } h-full justify-center items-center text-center`}>

            <img
              className={`${isMobile() ? "h-10 md:h-14 lg:h-16" : "h-14"} App-logo`}
              src={Spinner}
              alt="Spinner"
            />

            <p
              className={`${isMobile() ? "text-xl mb-8 md:text-4xl md:mb-16 lg:text-5xl lg:mb-16" : "text-4xl mb-16"
                } text-black font-black font-monserrat-bold`}
            >
              Cargando
            </p>

            <p
              className={`${isMobile() ? "text-sm md:text-xl lg:text-3xl" : "text-xl"
                } text-black font-montserrat-normal`}
            >
              Dependiendo del dispositivo y de tu conexión a Internet esto puede tardar unos instantes
            </p>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default LoadingScene;
