import Crosshair from "../../user_interface/layouts/Crosshair";
import React from "react";
import Scene from "../../three/common/Scene";
import InfoControls from "../../user_interface/instructions/InfoControls"

const VirtualSpace = () => {
  return (
    <div className="absolute inset-0">
      <Scene />
      <Crosshair />
      <InfoControls />
    </div>
  );
};

export default VirtualSpace;
