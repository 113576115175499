import React, { useRef, useEffect } from "react";
import { Mesh } from "three";
import useStore from "../../../stores/store";
import { Console } from "console";

export interface IInteractableMesh extends Mesh {
  onInteracted: () => void;
}

interface InteractiveMeshProps {
  onInteracted: () => void;
}

const InteractiveMesh: React.FC<
  InteractiveMeshProps & JSX.IntrinsicElements["mesh"]
> = ({ onInteracted, children, ...props }) => {
  const addInteractiveObj = useStore((state) => state.addInteractiveObj);
  const removeInteractiveObj = useStore((state) => state.removeInteractiveObj);
  const meshRef = useRef<IInteractableMesh>();

  useEffect(() => {
    if (!meshRef.current) return;

    meshRef.current.onInteracted = onInteracted;
    addInteractiveObj(meshRef.current);

    return () => removeInteractiveObj(meshRef.current);
  }, [addInteractiveObj, onInteracted, removeInteractiveObj]);

  return (
    <mesh ref={meshRef} {...props}>
      {children}
    </mesh>
  );
};

export default InteractiveMesh;
