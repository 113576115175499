import React, { useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { isMobile } from "./utilities/utils";
import Header from "./assets/images/nuevos/header.jpg";
import HeaderMobile from "./assets/images/nuevos/headerMovil.jpg";
import FinalImage1 from "./assets/images/nuevos/imagen_final1.png";
import FinalImage2 from "./assets/images/nuevos/imagen_final2.png";
import Miercoles from "./assets/images/landing/miercoles.png";
import Jueves from "./assets/images/landing/jueves.png";
import Viernes from "./assets/images/landing/viernes.png";
import Sabado from "./assets/images/landing/sabado.png";
import Domingo from "./assets/images/landing/domingo.png";
import Logo from "./assets/images/nuevos/logo.png";
import "./styles/custom.css"
import Countdown from 'react-countdown';
import useWindowOrientation from "use-window-orientation";
import useStore from "./stores/store";



const Landing = () => {

    const setShowLanding = useStore((state) => state.setShowLanding);

    const noChatLanding = useStore<boolean>((state) => state.noChatLanding);
    const smartsuppBtnDivRef = useRef<HTMLDivElement>();
    const smartsuppGnrlDivRef = useRef<HTMLDivElement>();
    useEffect(() => {
        const interval = setInterval(() => {
            const div = document.querySelector<HTMLDivElement>("#chat-application");

            const iframe = document.querySelector<HTMLIFrameElement>(
                "#chat-application-iframe"
            );

            const button = iframe?.contentWindow?.document.querySelector<HTMLDivElement>(
                ".css-stivtw"
            );

            if (div && button) {
                smartsuppGnrlDivRef.current = div;
                smartsuppBtnDivRef.current = button;

                smartsuppGnrlDivRef.current.style.bottom = "0px";
                if (isMobile()) {
                    smartsuppGnrlDivRef.current.style.top = "6px";
                }
                else {
                    smartsuppGnrlDivRef.current.style.top = "14px";
                    smartsuppGnrlDivRef.current.style.right = "84px";
                }
                smartsuppBtnDivRef.current.style.display = "flex";

                button.onclick = () => {
                    isMobile()
                        ? (div.style.top = "0px")
                        : (div.style.top = "14px");
                };

                if (noChatLanding) {
                    button.style.display = "none";
                }

                clearInterval(interval);
            }
        }, 250);

        return () => clearInterval(interval);
    }, [noChatLanding]);

    const { landscape } = useWindowOrientation();
    const date = '2021-05-19T09:30:00';

    // Renderer callback with condition
    const renderer = ({ days, hours, minutes, seconds, completed }: { days: any, hours: any, minutes: any, seconds: any, completed: any }) => {
        if (completed) {
            if (!isMobile() || isMobile() && landscape) {
                // Render a completed state
                return (
                    <div style={{ paddingTop: "1.5vw" }}>
                        <button
                            onClick={() =>
                                setShowLanding(false)
                            }
                            style={{ fontSize: "1.2vw", padding: "0.7vw 1vw 0.7vw 1vw" }}
                            className="rounded-full buttonEnterSpace"

                        >
                            ENTRAR AL ESPACIO 3D
                        </button>
                    </div>
                );
            }
            else {
                // Render a completed state
                return (
                    <div>
                        <button
                            onClick={() =>
                                setShowLanding(false)
                            }
                            style={{ fontSize: "4vw", padding: "2vw 4vw 2vw 4vw" }}
                            className="rounded-full buttonEnterSpace"

                        >
                            ENTRAR AL ESPACIO 3D
                        </button>
                    </div>
                );
            }

        } else {
            if (!isMobile() || isMobile() && landscape) {
                // Render a countdown
                return (
                    <div className="animate-pulse flex flex-row justify-center font-montserrat-bold" style={{ fontSize: "1.3vw" }}>
                        <div className=" flex flex-row gap-8" style={{ padding: "1vw 1vw 1vw 1vw", color: "#ef9c27" }}>
                            <div>ACCESO AL EVENTO EN</div>
                            <div>{days}d {hours}h {minutes}min</div>
                        </div>
                    </div>
                );
            }
            else {
                return (
                    <div className="animate-pulse flex flex-row justify-center font-montserrat-bold" style={{ fontSize: "4vw" }}>
                        <div className=" flex flex-row gap-4" style={{ padding: "1vw 1vw 1vw 1vw", color: "#ef9c27" }}>
                            <div>ACCESO AL EVENTO EN</div>
                            <div>{days}d {hours}h {minutes}min</div>
                        </div>
                    </div>
                );
            }

        }
    };

    return (!isMobile() || (isMobile() && landscape)) ? (
        <>
            <div
                className="flex w-full bg-white top-0 z-40 items-center" style={{ height: "5vw" }}
            >
                <div className="flex w-1/6 pl-12">
                    <img
                        style={{
                            width:
                                "60%" /* image box size as % of container, see step 1 */,
                            height:
                                "60%" /* image box size as % of container, see step 1 */,
                            objectFit:
                                "contain" /* matching of image pixels to image box, see step 2 */,
                        }}
                        src={Logo}
                        alt="Inicio"
                    />
                </div>
            </div>

            <div
                className="flex flex-col absolute inset-0"
                style={{
                    height: "100% - 5vw",
                    marginTop: "5vw",
                    marginBottom: "0rem",
                }}
            >
                <div>
                    <img
                        className="w-full"
                        src={Header}
                        alt="Inicio"
                    />
                    <div className="flex flex-col absolute w-full bg-black text-white bg-opacity-75 text-center" style={{ marginTop: "-22vw" }}>
                        <div className="w-full jusitfy-center items-center" style={{ paddingTop: "2vw", paddingBottom: "2vw", paddingLeft: "8vw", paddingRight: "8vw" }}>
                            <p style={{ fontSize: "2vw" }} className="font-montserrat-bold">BIENVENIDOS AL ESPACIO VIRTUAL</p>
                            <p style={{ fontSize: "1vw" }} className="font-montserrat-normal">
                            El 275 aniversario del nacimiento de Goya, el Camino de Santiago y el turismo sostenible serán los protagonistas del stand de este año, que representará la diversidad natural y paisajística del territorio. Esta edición se celebrará del 19 al 23 de mayo en IFEMA.
                            </p>
                            <Countdown
                                date={date}
                                renderer={renderer}
                            />
                        </div>
                    </div>


                </div>
                    
                


                <div className="w-1/5 flag_section" style={{ marginTop: "-2.8vw", zIndex: 1 }}>
                    <div className="items-center text-white font-montserrat-bold" style={{ fontSize: "2vw", padding: "1vw 1vw 1vw 1vw" }}>
                        PROGRAMA
                    </div>
                </div>
                <div>
                    <hr
                        style={{
                            height: "1vw",
                            backgroundColor: "#ef9c27",
                            border: "none",
                            marginTop: "-2.8vw"
                        }}
                    >
                    </hr>
                </div>



                <div className="flex flex-col pt-20 gap-8 px-44">
                    <div className="flex flex-row justify-evenly items-center">
                        <div className="flex w-1/3 flex-col">
                            <img
                                className="rounded-lg"
                                src={Miercoles}
                                alt="Inicio"
                            />
                        </div>
                        <div className="flex flex-row items-start gap-4 font-montserrat-normal" style={{ fontSize: "1vw" }}>
                            <div className="flex flex-col items-start font-montserrat-bold gap-2">
                                <div>11:00</div>
                                <div>11:30</div>
                                <div>12:30</div>
                                <div>13:30</div>
                                <div>13:30</div>
                                <div>14:00</div>
                                <div>16:00</div>
                                <div>16:30</div>
                                <div>16:30</div>
                                <div>17:30</div>
                                <div>18:00</div>
                            </div>
                            <div className="flex flex-col items-start gap-2">
                                <div>Calatayud Destino Turístico Sostenible 2023</div>
                                <div>Premios Excelencia al Desarrollo del Turismo Sostenible. Premios Edelweis</div>
                                <div>Cata a ciegas</div>
                                <div>Un destino una cocina: Huesca</div>
                                <div>Cata Enoturismo Aragón</div>
                                <div>Un destino una cocina: Zaragoza</div>
                                <div>La Carrasca milenaria de Lecina, punto de partida de una gran historia</div>
                                <div>Comarca SOMONTANO: El Valle de Rodellar</div>
                                <div>Cata a ciegas</div>
                                <div>Un destino una cocina: Teruel</div>
                                <div>Cata enoturismo Aragón</div>
                            </div>

                        </div>
                    </div>
                    <div className="flex flex-row justify-evenly py-8 items-center" style={{ backgroundColor: "#f2f2f2" }}>
                        <div className="flex flex-row items-start gap-4 font-montserrat-normal" style={{ fontSize: "1vw" }}>
                            <div className="flex flex-col items-start font-montserrat-bold gap-2">
                                <div>11:00</div>
                                <div>11:30</div>
                                <div>12:00</div>
                                <div>12:30</div>
                                <div>13:00</div>
                                <div>13:30</div>
                                <div>14:00</div>
                                <div>16:00</div>
                                <div>16:30</div>
                                <div>16:30</div>
                                <div>17:00</div>
                                <div>17:30</div>
                                <div>17:30</div>
                                <div>18:00</div>
                            </div>
                            <div className="flex flex-col items-start gap-2">
                                <div>Dinópolis: 20 Aniversario</div>
                                <div>Categorización Jamón y Paleta D.O.</div>
                                <div>El Origen de un Genio</div>
                                <div>Cata a ciegas</div>
                                <div>Un destino una cocina: Teruel</div>
                                <div>Cata Enoturismo Aragón</div>
                                <div>Un destino una cocina: Huesca</div>
                                <div>Birding Aragón. Esperiencias de ecoturismo</div>
                                <div>Teruel Es</div>
                                <div>Cata a ciegas</div>
                                <div>Guía Siente Teruel</div>
                                <div>Dos lenguajes y una experiencia única</div>
                                <div>Un destino una cocina: Zaragoza</div>
                                <div>Cata Enoturismo Aragón</div>
                            </div>
                        </div>
                        <div className="flex w-1/3 flex-col">
                            <img
                                className="rounded-lg"
                                src={Jueves}
                                alt="Inicio"
                            />
                        </div>
                    </div>
                    <div className="flex flex-row justify-evenly items-center">
                        <div className="flex w-1/3 flex-col">
                            <img
                                className="rounded-lg"
                                src={Viernes}
                                alt="Inicio"
                            />
                        </div>
                        <div className="flex flex-row items-start gap-4 font-montserrat-normal" style={{ fontSize: "1vw" }}>
                            <div className="flex flex-col items-start font-montserrat-bold gap-2">
                                <div>11:30</div>
                                <div>12:00</div>
                                <div>12:05</div>
                                <div>12:25</div>
                                <div>12:30</div>
                                <div>12:45</div>
                                <div>13:00</div>
                                <div>13:15</div>
                                <div>16:00</div>
                                <div>16:30</div>
                                <div>17:30</div>
                                <div>18:00</div>
                            </div>
                            <div className="flex flex-col items-start gap-2">
                                <div>Recorrido Institucional</div>
                                <div>Presentación de Goya</div>
                                <div>Bienvenida a cargo de la Directora General de Turismo, Elena Allué</div>
                                <div>Goya: Intervención artística</div>
                                <div>Presentación del Camino de Santiago</div>
                                <div>Discurso del Vicepresidente de Aragón, Arturo Aliaga</div>
                                <div>Presentación Enoturismo: Vinos de Aragón</div>
                                <div>Cata a ciegas y experiencia culinaria</div>
                                <div>Ruta del vino de Somontano. No te lo puedes perder</div>
                                <div>Aragón Incoming</div>
                                <div>Un destino una cocina</div>
                                <div>Cata Enoturismo Aragón</div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row justify-evenly py-8 items-center" style={{ backgroundColor: "#f2f2f2" }}>
                        <div className="flex flex-row items-center gap-4 font-montserrat-normal" style={{ fontSize: "1vw" }}>
                            <div className="flex flex-col items-start font-montserrat-bold gap-2">
                                <div>12:30</div>
                                <div>13:00</div>
                                <div>13:30</div>
                                <div>14:00</div>
                                <div>16:30</div>
                                <div>17:30</div>
                                <div>18:00</div>
                            </div>
                            <div className="flex flex-col items-start gap-2">
                                <div>Cata a ciegas</div>
                                <div>Un destino una cocina: Huesca</div>
                                <div>Cata Enoturismo Aragón</div>
                                <div>Un destino una cocina: Zaragoza</div>
                                <div>Cata a ciegas</div>
                                <div>Un destino una cocina: Teruel</div>
                                <div>Cata Enoturismo Aragón</div>
                            </div>
                        </div>
                        <div className="flex w-1/3 flex-col">
                            <img
                                className="rounded-lg"
                                src={Sabado}
                                alt="Inicio"
                            />
                        </div>
                    </div>
                    <div className="flex flex-row justify-evenly items-center">
                        <div className="flex w-1/3 flex-col">
                            <img
                                className="rounded-lg"
                                src={Domingo}
                                alt="Inicio"
                            />
                        </div>
                        <div className="flex flex-row items-center gap-4 font-montserrat-normal" style={{ fontSize: "1vw" }}>
                            <div className="flex flex-col items-start font-montserrat-bold gap-2">
                                <div>12:30</div>
                                <div>13:00</div>
                                <div>13:30</div>
                                <div>14:00</div>
                                <div>16:30</div>
                                <div>17:30</div>
                                <div>18:00</div>
                            </div>
                            <div className="flex flex-col items-start gap-2">
                                <div>Cata a ciegas</div>
                                <div>Un destino una cocina: Teruel</div>
                                <div>Cata Enoturismo Aragón</div>
                                <div>Un destino una cocina: Huesca</div>
                                <div>Cata a ciegas</div>
                                <div>Un destino una cocina: Zaragoza</div>
                                <div>Cata Enoturismo Aragón</div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="flex flex-row justify-evenly mt-28">
                    <div className="flex w-1/6">
                        <img
                            style={{
                                width:
                                    "80%" /* image box size as % of container, see step 1 */,
                                height:
                                    "80%" /* image box size as % of container, see step 1 */,
                                objectFit:
                                    "contain" /* matching of image pixels to image box, see step 2 */,
                            }}
                            src={FinalImage1}
                            alt="Inicio"
                        />
                    </div>
                    <div className="flex w-1/6">
                        <img
                            style={{
                                width:
                                    "70%" /* image box size as % of container, see step 1 */,
                                height:
                                    "70%" /* image box size as % of container, see step 1 */,
                                objectFit:
                                    "contain" /* matching of image pixels to image box, see step 2 */,
                            }}
                            src={FinalImage2}
                            alt="Inicio"
                        />
                    </div>
                </div>

                <div className="bottom-0 right-0 left-0 flex z-40 justify-center bg-gray-500 text-white items-center">
                    <div style={{ marginTop: "0.7vw", marginBottom: "0.7vw", fontSize: "1.1vw" }}>
                        &copy; 2021&nbsp;Turismo de Aragón, Todos los derechos reservados
                    </div>
                </div>
            </div>
        </>
    ) : (
        <>
            <div
                className="flex w-full bg-white top-0 z-40 items-center justify-start" style={{ height: "20vw" }}
            >
                <div className="flex items-start pl-4 md:w-1/2 md:pl-8">
                    <img
                        style={{
                            width:
                                "50%" /* image box size as % of container, see step 1 */,
                            height:
                                "50%" /* image box size as % of container, see step 1 */,
                            objectFit:
                                "contain" /* matching of image pixels to image box, see step 2 */,
                        }}
                        src={Logo}
                        alt="Inicio"
                    />
                </div>
            </div>

            <div
                className="flex flex-col absolute inset-0"
                style={{
                    height: "100% - 20vw",
                    marginTop: "20vw",
                    marginBottom: "0rem",
                }}
            >
                <div>
                    <img
                        src={HeaderMobile}
                        alt="Inicio"
                    />
                    <div className="flex flex-col absolute w-full bg-black text-white bg-opacity-75 text-center" style={{ marginTop: "-55vw" }}>
                        <div className="jusitfy-center items-center" style={{ paddingTop: "4vw", paddingBottom: "4vw", paddingLeft: "4vw", paddingRight: "4vw" }}>
                            <p style={{ fontSize: "4.5vw", paddingBottom: "2vw" }} className="font-montserrat-bold">BIENVENIDOS AL ESPACIO VIRTUAL</p>
                            <p style={{ fontSize: "3vw", paddingBottom: "3vw" }} className="font-montserrat-normal">
                            El 275 aniversario del nacimiento de Goya, el Camino de Santiago y el turismo sostenible serán los protagonistas del stand de este año, que representará la diversidad natural y paisajística del territorio. Esta edición se celebrará del 19 al 23 de mayo en IFEMA.
                            </p>
                            <Countdown
                                date={date}
                                renderer={renderer}
                            />
                        </div>
                    </div>
                </div>


                <div className="w-1/3 flag_section" style={{ marginTop: "-4.1vw", zIndex: 1 }}>
                    <div className="items-center text-white font-montserrat-bold" style={{ fontSize: "4vw", padding: "1vw 1vw 1vw 1vw" }}>
                        PROGRAMA
                    </div>
                </div>
                <div>
                    <hr
                        style={{
                            height: "1vw",
                            backgroundColor: "#ef9c27",
                            border: "none",
                            marginTop: "-4.1vw"
                        }}
                    >
                    </hr>
                </div>



                <div className="flex flex-col pt-6 gap-12">
                    <div className="flex flex-col justify-center items-center">
                        <div className="flex w-4/5 flex-col mb-4">
                            <img
                                className="rounded-lg"
                                src={Miercoles}
                                alt="Inicio"
                            />
                        </div>
                        <div className="flex w-full flex-col justify-center items-center gap-2 font-montserrat-normal" style={{ fontSize: "3vw" }}>
                            <div className="flex flex-row w-3/4 items-center gap-3 align-items">
                                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">11:00</div>
                                <div>Calatayud Destino Turístico Sostenible 2023</div>
                            </div>
                            <div className="flex flex-row w-3/4 items-center gap-3">
                                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">11:30</div>
                                <div>Premios Excelencia al Desarrollo del Turismo Sostenible. Premios Edelweis</div>
                            </div>
                            <div className="flex flex-row w-3/4 items-center gap-3">
                                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">12:30</div>
                                <div>Cata a ciegas</div>
                            </div>
                            <div className="flex flex-row w-3/4 items-center gap-3">
                                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">13:30</div>
                                <div>Un destino una cocina: Huesca</div>
                            </div>
                            <div className="flex flex-row w-3/4 items-center gap-3">
                                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">13:30</div>
                                <div>Cata Enoturismo Aragón</div>
                            </div>
                            <div className="flex flex-row w-3/4 items-center gap-3">
                                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">14:00</div>
                                <div>Un destino una cocina: Zaragoza</div>
                            </div>
                            <div className="flex flex-row w-3/4 items-center gap-3">
                                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">16:00</div>
                                <div>La Carrasca milenaria de Lecina, punto de partida de una gran historia</div>
                            </div>
                            <div className="flex flex-row w-3/4 items-center gap-3">
                                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">16:30</div>
                                <div>Comarca SOMONTANO: El Valle de Rodellar</div>
                            </div>
                            <div className="flex flex-row w-3/4 items-center gap-3">
                                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">16:30</div>
                                <div>Cata a ciegas</div>
                            </div>
                            <div className="flex flex-row w-3/4 items-center gap-3">
                                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">17:30</div>
                                <div>Un destino una cocina: Teruel</div>
                            </div>
                            <div className="flex flex-row w-3/4 items-center gap-3">
                                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">18:00</div>
                                <div>Cata enoturismo Aragón</div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col justify-center items-center">
                        <div className="flex w-4/5 flex-col mb-4">
                            <img
                                className="rounded-lg"
                                src={Jueves}
                                alt="Inicio"
                            />
                        </div>
                        <div className="flex w-full flex-col justify-center items-center gap-2 font-montserrat-normal" style={{ fontSize: "3vw" }}>
                            <div className="flex flex-row w-3/4 items-center gap-3 align-items">
                                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">11:00</div>
                                <div>Dinópolis: 20 Aniversario</div>
                            </div>
                            <div className="flex flex-row w-3/4 items-center gap-3">
                                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">11:30</div>
                                <div>Categorización Jamón y Paleta D.O.</div>
                            </div>
                            <div className="flex flex-row w-3/4 items-center gap-3">
                                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">12:00</div>
                                <div>El Origen de un Genio</div>
                            </div>
                            <div className="flex flex-row w-3/4 items-center gap-3">
                                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">12:30</div>
                                <div>Cata a ciegas</div>
                            </div>
                            <div className="flex flex-row w-3/4 items-center gap-3">
                                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">13:00</div>
                                <div>Un destino una cocina: Teruel</div>
                            </div>
                            <div className="flex flex-row w-3/4 items-center gap-3">
                                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">13:30</div>
                                <div>Cata Enoturismo Aragón</div>
                            </div>
                            <div className="flex flex-row w-3/4 items-center gap-3">
                                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">14:00</div>
                                <div>Un destino una cocina: Huesca</div>
                            </div>
                            <div className="flex flex-row w-3/4 items-center gap-3">
                                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">16:00</div>
                                <div>Birding Aragón. Esperiencias de ecoturismo</div>
                            </div>
                            <div className="flex flex-row w-3/4 items-center gap-3">
                                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">16:30</div>
                                <div>Teruel Es</div>
                            </div>
                            <div className="flex flex-row w-3/4 items-center gap-3">
                                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">16:30</div>
                                <div>Cata a ciegas</div>
                            </div>
                            <div className="flex flex-row w-3/4 items-center gap-3">
                                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">17:00</div>
                                <div>Guía Siente Teruel</div>
                            </div>
                            <div className="flex flex-row w-3/4 items-center gap-3">
                                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">17:30</div>
                                <div>Dos lenguajes y una experiencia única</div>
                            </div>
                            <div className="flex flex-row w-3/4 items-center gap-3">
                                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">17:30</div>
                                <div>Un destino una cocina: Zaragoza</div>
                            </div>
                            <div className="flex flex-row w-3/4 items-center gap-3">
                                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">18:00</div>
                                <div>Cata Enoturismo Aragón</div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col justify-center items-center">
                        <div className="flex w-4/5 flex-col mb-4">
                            <img
                                className="rounded-lg"
                                src={Viernes}
                                alt="Inicio"
                            />
                        </div>
                        <div className="flex w-full flex-col justify-center items-center gap-2 font-montserrat-normal" style={{ fontSize: "3vw" }}>
                            <div className="flex flex-row w-3/4 items-center gap-3 align-items">
                                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">11:30</div>
                                <div>Recorrido Institucional</div>
                            </div>
                            <div className="flex flex-row w-3/4 items-center gap-3">
                                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">12:00</div>
                                <div>Presentación de Goya</div>
                            </div>
                            <div className="flex flex-row w-3/4 items-center gap-3">
                                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">12:05</div>
                                <div>Bienvenida a cargo de la Directora General de Turismo, Elena Allué</div>
                            </div>
                            <div className="flex flex-row w-3/4 items-center gap-3">
                                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">12:25</div>
                                <div>Goya: Intervención artística</div>
                            </div>
                            <div className="flex flex-row w-3/4 items-center gap-3">
                                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">12:30</div>
                                <div>Presentación del Camino de Santiago</div>
                            </div>
                            <div className="flex flex-row w-3/4 items-center gap-3">
                                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">12:45</div>
                                <div>Discurso del Vicepresidente de Aragón, Arturo Aliaga</div>
                            </div>
                            <div className="flex flex-row w-3/4 items-center gap-3">
                                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">13:00</div>
                                <div>Presentación Enoturismo: Vinos de Aragón</div>
                            </div>
                            <div className="flex flex-row w-3/4 items-center gap-3">
                                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">13:15</div>
                                <div>Cata a ciegas y experiencia culinaria</div>
                            </div>
                            <div className="flex flex-row w-3/4 items-center gap-3">
                                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">16:00</div>
                                <div>Ruta del vino de Somontano. No te lo puedes perder</div>
                            </div>
                            <div className="flex flex-row w-3/4 items-center gap-3">
                                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">16:30</div>
                                <div>Aragón Incoming</div>
                            </div>
                            <div className="flex flex-row w-3/4 items-center gap-3">
                                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">17:30</div>
                                <div>Un destino una cocina</div>
                            </div>
                            <div className="flex flex-row w-3/4 items-center gap-3">
                                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">18:00</div>
                                <div>Cata Enoturismo Aragón</div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col justify-center items-center">
                        <div className="flex w-4/5 flex-col mb-4">
                            <img
                                className="rounded-lg"
                                src={Sabado}
                                alt="Inicio"
                            />
                        </div>
                        <div className="flex w-full flex-col justify-center items-center gap-2 font-montserrat-normal" style={{ fontSize: "3vw" }}>
                            <div className="flex flex-row w-3/4 items-center gap-3 align-items">
                                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">12:30</div>
                                <div>Cata a ciegas</div>
                            </div>
                            <div className="flex flex-row w-3/4 items-center gap-3">
                                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">13:00</div>
                                <div>Un destino una cocina: Huesca</div>
                            </div>
                            <div className="flex flex-row w-3/4 items-center gap-3">
                                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">13:30</div>
                                <div>Cata Enoturismo Aragón</div>
                            </div>
                            <div className="flex flex-row w-3/4 items-center gap-3">
                                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">14:00</div>
                                <div>Un destino una cocina: Zaragoza</div>
                            </div>
                            <div className="flex flex-row w-3/4 items-center gap-3">
                                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">16:30</div>
                                <div>Cata a ciegas</div>
                            </div>
                            <div className="flex flex-row w-3/4 items-center gap-3">
                                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">17:30</div>
                                <div>Un destino una cocina: Teruel</div>
                            </div>
                            <div className="flex flex-row w-3/4 items-center gap-3">
                                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">18:00</div>
                                <div>Cata Enoturismo Aragón</div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col justify-center items-center">
                        <div className="flex w-4/5 flex-col mb-4">
                            <img
                                className="rounded-lg"
                                src={Domingo}
                                alt="Inicio"
                            />
                        </div>
                        <div className="flex w-full flex-col justify-center items-center gap-2 font-montserrat-normal" style={{ fontSize: "3vw" }}>
                            <div className="flex flex-row w-3/4 items-center gap-3 align-items">
                                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">12:30</div>
                                <div>Cata a ciegas</div>
                            </div>
                            <div className="flex flex-row w-3/4 items-center gap-3">
                                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">13:00</div>
                                <div>Un destino una cocina: Teruel</div>
                            </div>
                            <div className="flex flex-row w-3/4 items-center gap-3">
                                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">13:30</div>
                                <div>Cata Enoturismo Aragón</div>
                            </div>
                            <div className="flex flex-row w-3/4 items-center gap-3">
                                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">14:00</div>
                                <div>Un destino una cocina: Huesca</div>
                            </div>
                            <div className="flex flex-row w-3/4 items-center gap-3">
                                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">16:30</div>
                                <div>Cata a ciegas</div>
                            </div>
                            <div className="flex flex-row w-3/4 items-center gap-3">
                                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">17:30</div>
                                <div>Un destino una cocina: Zaragoza</div>
                            </div>
                            <div className="flex flex-row w-3/4 items-center gap-3">
                                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">18:00</div>
                                <div>Cata Enoturismo Aragón</div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="flex flex-row justify-evenly mt-16">
                    <div className="flex w-1/2 justify-center">
                        <img
                            style={{
                                width:
                                    "75%" /* image box size as % of container, see step 1 */,
                                height:
                                    "75%" /* image box size as % of container, see step 1 */,
                                objectFit:
                                    "contain" /* matching of image pixels to image box, see step 2 */,
                            }}
                            src={FinalImage1}
                            alt="Inicio"
                        />
                    </div>
                    <div className="flex w-1/2 justify-center">
                        <img
                            style={{
                                width:
                                    "60%" /* image box size as % of container, see step 1 */,
                                height:
                                    "60%" /* image box size as % of container, see step 1 */,
                                objectFit:
                                    "contain" /* matching of image pixels to image box, see step 2 */,
                            }}
                            src={FinalImage2}
                            alt="Inicio"
                        />
                    </div>
                </div>

                <div className="bottom-0 right-0 left-0 flex z-40 justify-center bg-gray-500 text-white items-center">
                    <div style={{ marginTop: "2vw", marginBottom: "2vw", fontSize: "3vw" }}>
                        &copy; 2021&nbsp;Turismo de Aragón, Todos los derechos reservados
                    </div>
                </div>
            </div>
        </>
    )
};

export default Landing;
