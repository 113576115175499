import React, { useContext, useEffect } from "react";
import logo from "../../../assets/images/detalle.svg";
import logOut from "../../../assets/images/log-out.png";
import useStore from "../../../stores/store";
import { useHistory, useLocation } from "react-router-dom";
import { isMobile } from "../../../utilities/utils";
import useWindowOrientation from "use-window-orientation";
import Inicio from "../../../assets/images/SVG/inicio_icon.svg";
import { StreamingContext } from "../../streaming/StreamingContext";
import { motion, AnimatePresence } from "framer-motion";

const Header = () => {
  const userLoggedStatus = useStore<boolean>((state) => state.userLoggedStatus);
  const userLoggedStatusKey = useStore<string>(
    (state) => state.userLoggedStatusKey
  );
  const userLoggedStatusValue = useStore<string>(
    (state) => state.userLoggedStatusValue
  );
  const setUserLoggedStatus = useStore((state) => state.setUserLoggedStatus);

  const history = useHistory();

  const location = useLocation();

  const visualizationMode3DKey = useStore<string>(
    (state) => state.visualizationMode3DKey
  );

  const { landscape } = useWindowOrientation();

  const setMobileLandscape = useStore((state) => state.setMobileLandscape);

  const isMobileLandscape = () => {
    // console.log(
    //   "Is Mobile Landscape: " + !!navigator.maxTouchPoints &&
    //     !!window.screen.orientation.angle
    // );
    return !!navigator.maxTouchPoints && !!window.screen.orientation.angle;
  };

  const resizer = () => setMobileLandscape(isMobileLandscape());

  const showInfoControls = useStore<boolean>((state) => state.showInfoControls);
  const setShowInfoControls = useStore((state) => state.setShowInfoControls);
  const { isStreamingOn } = useContext(StreamingContext);
  const showLoadingScreen = useStore<boolean>(
    (state) => state.showLoadingScreen
  );

  const streamingToday = useStore<boolean>((state) => state.streamingToday);

  useEffect(() => {
    window.addEventListener("orientationchange", resizer);

    window.onorientationchange = function (event) {
      // console.log(
      //   "the orientation of the device is now " +
      //     window.screen.orientation.angle
      // );
    };

    return window.removeEventListener("orientationchange", resizer);
  });

  return isMobile() && !landscape ? (
    <>
      <AnimatePresence>
        {showInfoControls && location.pathname == "/virtualspace" && !showLoadingScreen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.75 }}
            exit={{ opacity: 0 }}
            className={`flex absolute flex-row w-full flex-wrap h-16 md:h-32 top-0 right-0 left-0 z-40 items-center bg-black`}
          ></motion.div>
        )}

      </AnimatePresence>

      <div
        className={`flex absolute flex-row w-full flex-wrap h-16 md:h-32 top-0 right-0 left-0 z-30 items-center`}
        style={{ backgroundColor: `${location.pathname == "/agenda" || location.pathname == "/repositorio" ? "#f2f2f2" : "transparent"}` }}
      >
        {location.pathname !== "/sceneselector" && (
          <div className="absolute flex w-1/3 justify-start md:w-1/2" style={{ paddingLeft: "3vw" }}>
            <button
              className="text-black rounded-full font-montserrat-regular text-lg bg-white"
              onClick={() => {
                history.push("/");
                setShowInfoControls(true);
              }}
            >
              <div className="flex flex-row mx-3 my-3 md:mx-4 md:my-4 lg:mx-5 lg:my-5 items-center">
                <img
                  className={`h-6 md:h-8 lg:h-12`}
                  src={Inicio}
                  alt="Inicio"
                />
              </div>
            </button>
          </div>
        )}
        {location.pathname !== "/virtualspacesimplified" && (
          <div className="flex w-full justify-center">
            <div className="text-black rounded-full bg-white bg-opacity-80 font-montserrat-sm text-center">
              <div style={{fontSize:"3vw"}}>
                {streamingToday ? (
                  <div style={{ padding: "2vw 3vw 2vw 3vw" }}>
                    🟢 &nbsp; Estamos en directo
                  </div>
                ) :
                <div style={{ padding: "2vw 3vw 2vw 3vw" }}>
                ⚫ &nbsp; Sin retransmisión
                </div>
              }
              </div>
            </div>
          </div>
        )}

      </div>
    </>

  ) : (
    <>
      <AnimatePresence>
        {showInfoControls && location.pathname == "/virtualspace" && !showLoadingScreen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.75 }}
            exit={{ opacity: 0 }}
            className={`absolute flex-wrap top-0 right-0 left-0 flex z-40 bg-black`}
            style={{ height: "6vw" }}
          ></motion.div>
        )}

      </AnimatePresence>
      
    
      <div
        className={`absolute flex-wrap h-28 top-0 right-0 left-0 flex z-30 items-center grid grid-cols-7`}
        style={{ height: "6vw", backgroundColor: `${location.pathname == "/agenda" || location.pathname == "/repositorio" ? "#f2f2f2" : "transparent"}` }}
      >
        {location.pathname !== "/sceneselector" && !isMobile() ? (
          <div className="flex col-start-1 justify-center">
            <button
              className="text-black rounded-full font-montserrat-normal transition duration-500 transform hover:scale-110"
              style={{ backgroundColor: "white" }}
              onClick={() => {
                history.push("/");
                setShowInfoControls(true);
              }}
            >
              <div className="flex flex-row items-center" style={{ padding: "0.5vw  1vw 0.5vw 1vw" }}>
                <img
                  style={{ width: "2.1vw", paddingRight: "0.8vw" }}
                  src={Inicio}
                  alt="Inicio"
                />
                <span style={{ fontSize: "1.1vw" }}>{"Inicio"}</span>
              </div>
            </button>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default Header;
