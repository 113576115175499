import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import useStore from "../../../stores/store";
import { isMobile, log } from "../../../utilities/utils";
import { StreamingContext } from "../../streaming/StreamingContext";
import useWindowOrientation from "use-window-orientation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import RoyalLogo from "../../../assets/images/royal_logo.png";
import MSDLogo from "../../../assets/images/msd_logo.png";
import AgesvetLogo from "../../../assets/images/agesvet_logo.png";
import AragonLogo from "../../../assets/images/nuevos/aragon_logo.png";
import { useLocation } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";

const Footer = () => {
  const streamingToday = useStore<boolean>((state) => state.streamingToday);
  const userLoggedStatus = useStore<boolean>((state) => state.userLoggedStatus);
  const setActiveScene = useStore((state) => state.setActiveScene);
  const setShowLoadingScreen = useStore((state) => state.setShowLoadingScreen);
  const location = useLocation();

  const history = useHistory();


  /** @see {@tutorial https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state} */
  /*
  function usePrevious<T>(value: T) {
    const ref = useRef<T>();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current as T;
  }
*/
  const activeScene = useStore<number>((state) => state.activeScene);

  const visualizationMode3D = useStore<boolean>(
    (state) => state.visualizationMode3D
  );

  const setVisualizationMode3D = useStore(
    (state) => state.setVisualizationMode3D
  );

  //const previousActiveScene = usePrevious(activeScene);

  const visualizationMode3DKey = useStore<string>(
    (state) => state.visualizationMode3DKey
  );

  function LoadScene2D(scene: number) {
    log("Button Scene " + scene + " Clicked!");

    if (
      scene !== activeScene ||
      (activeScene === 6 &&
        history.location.pathname !== "/virtualspacesimplified")
    ) {
      if (visualizationMode3D === true) {
        setVisualizationMode3D(false);

        var vMode3D = false;

        localStorage.setItem(visualizationMode3DKey, vMode3D.toString());
      }

      setActiveScene(scene);

      history.push("/virtualspacesimplified");

      setShowLoadingScreen(true);
    }
  }
  const showLoadingScreen = useStore<boolean>(
    (state) => state.showLoadingScreen
  );

  const { isStreamingOn } = useContext(StreamingContext);

  const { landscape } = useWindowOrientation();

  const setMobileLandscape = useStore((state) => state.setMobileLandscape);

  const isMobileLandscape = () => {
    // console.log(
    //   "Is Mobile Landscape: " + !!navigator.maxTouchPoints &&
    //     !!window.screen.orientation.angle
    // );
    return !!navigator.maxTouchPoints && !!window.screen.orientation.angle;
  };

  const resizer = () => setMobileLandscape(isMobileLandscape());

  const showInfoControls = useStore<boolean>((state) => state.showInfoControls);


  useEffect(() => {
    window.addEventListener("orientationchange", resizer);

    window.onorientationchange = function (event) {
      // console.log(
      //   "the orientation of the device is now " +
      //     window.screen.orientation.angle
      // );
    };

    return window.removeEventListener("orientationchange", resizer);
  });

  return isMobile() ? (
    <div
      className="absolute flex-wrap bottom-0 right-0 left-0 flex flex-col z-40 items-center"
      style={{ paddingBottom: "2vh" }}
    >
      {(location.pathname === "/virtualspace" && !showLoadingScreen) || landscape || location.pathname === "/agenda" || location.pathname === "/repositorio" ? null : (
        <div className="flex w-2/5 md:w-1/5 text-center justify-center">
          <div className="flex flex-col">
            <img
              className={``}
              src={AragonLogo}
              alt="Menu Main"
              style={{
                width:
                  "100%" /* image box size as % of container, see step 1 */,
                height:
                  "100%" /* image box size as % of container, see step 1 */,
                objectFit:
                  "contain" /* matching of image pixels to image box, see step 2 */,
              }}
            />
          </div>
        </div>
      )}
    </div>

  ) : (
    <>
      <AnimatePresence>
        {showInfoControls && location.pathname == "/virtualspace" && !showLoadingScreen &&(
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.75 }}
            exit={{ opacity: 0 }}
            className="absolute flex-wrap bottom-0 right-0 left-0 flex z-40 items-center grid grid-cols-7 bg-black"
            style={{ height: "6vw"}}
          ></motion.div>
        )}
      </AnimatePresence>
      

      <div
        className="absolute flex-wrap bottom-0 right-0 left-0 flex z-30 items-center grid grid-cols-7"
        style={{ height: "6vw", backgroundColor: "transparent" }}
      >
        {!streamingToday && location.pathname !== "/virtualspacesimplified" ? (
          <div className="text-black rounded-full font-montserrat-regular text-center col-start-4 bg-white bg-opacity-80"
            style={{ fontSize: "1vw" }}>
            <div style={{ padding: "0.6vw 0.6vw 0.6vw 0.6vw" }}>
              ⚫ &nbsp; Sin retransmisión
            </div>
          </div>

        ) : null}
        {streamingToday && location.pathname !== "/virtualspacesimplified" ? (
          <div className="text-black-gray rounded-full font-montserrat-regular col-start-4 bg-white bg-opacity-80"
            style={{ fontSize: "1vw" }}>
            <div className="text-center" style={{ padding: "0.6vw 0.6vw 0.6vw 0.6vw" }}>
              🟢 &nbsp; Estamos en directo
            </div>
          </div>
        ) : null}

        <img
          className="col-start-7"
          style={{
            width:
              "70%" /* image box size as % of container, see step 1 */,
            height:
              "70%" /* image box size as % of container, see step 1 */,
            objectFit:
              "contain" /* matching of image pixels to image box, see step 2 */,
          }}
          src={AragonLogo}
          alt="Inicio"
        />
      </div>
    </>
  );
};

export default Footer;
