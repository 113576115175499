import React, { useRef } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import useStore from "../../../stores/store";
import PlayerBehaviour from "../../../library/PlayerBehaviour";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import BackgroundDetail from "../../../assets/images/background_detail.svg";
import { isMobile } from "../../../utilities/utils";

const ModalPDF = () => {
  const playerBehaviour = useStore<PlayerBehaviour>(
    (state) => state.playerBehaviour
  );

  const onCloseModal = () => {
    setModalPDFTitle("");
    setModalPDFFileName("");
    setModalPDFFileUrl("");
    setShowModalPDF(false);
    playerBehaviour.lockCursor();
    playerBehaviour.release();
  };

  const modalPDFTitle = useStore<string>((state) => state.modalPDFTitle);
  const setModalPDFTitle = useStore((state) => state.setModalPDFTitle);

  const showModalPDF = useStore<boolean>((state) => state.showModalPDF);
  const setShowModalPDF = useStore((state) => state.setShowModalPDF);

  const modalPDFFileName = useStore<string>((state) => state.modalPDFFileName);
  const setModalPDFFileName = useStore((state) => state.setModalPDFFileName);

  const modalPDFFileUrl = useStore<string>((state) => state.modalPDFFileUrl);
  const setModalPDFFileUrl = useStore((state) => state.setModalPDFFileUrl);

  const container = useRef<any>(null);

  return (
    <div>
      <Modal
        open={showModalPDF}
        onClose={onCloseModal}
        container={container.current}
        center
        // animationDuration={0}
        classNames={{
          modal: "",
          modalContainer: "",
        }}
        styles={{
          overlay: {},
          closeButton: {
            color: "white",
            backgroundColor: "white",
          },
          closeIcon: {
            color: "white",
            backgroundColor: "white",
          },
          modalContainer: {
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
          modal: {
            inset: 0,
            width: "80vmin",
            maxWidth: "80vmin",
            height: "80vmin" /* Same as width */,
            maxHeight: "80vmin",
            padding: "2rem",
            display: "table",
            backgroundColor: "white",
            color: "black",
            margin: "auto",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
      >
        <h1
          className={`font-montserrat-bold ${isMobile() ? "text-xs" : "text-3xl"
            }`}
        >
          {modalPDFTitle}
        </h1>

        {/* <img
          className={`absolute right-0 bottom-0 z-0 ${
            isMobile() ? "h-12 w-12" : "h-48 w-48"
          }`}
          style={{
            transform: "scaleX(-1)",
          }}
          src={BackgroundDetail}
          alt="Background Detail"
        /> */}

        <div className="flex justify-center items-center h-full w-full">
          <div>
            <h2
              className={`font-montserrat-bold ${isMobile() ? "text-xs" : "text-2xl"
                }`}
            >
              {modalPDFFileName}
            </h2>
            <div className="flex justify-start items-center w-full">
              <button
                className={`max-w-min text-white mt-4 py-1 border rounded-full text-lg disabled:opacity-50 bg-rc-medium-gray hover:bg-white hover:border-2 hover:border-rc-medium-gray hover:text-rc-medium-gray`}
                onClick={() => {
                  window.open(modalPDFFileUrl);
                }}
              >
                <div
                  className={`flex items-center ${isMobile() ? "text-xs" : ""}`}
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{"DESCARGAR"}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <FontAwesomeIcon icon={faDownload} />
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </div>
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ModalPDF;
