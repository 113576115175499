import { useMemo, useEffect } from "react";
import { Raycaster, Vector2 } from "three";
import { useThree, useFrame } from "react-three-fiber";
import useStore from "../../../stores/store";
import { IInteractableMesh } from "./InteractiveMesh";

const CameraRaycaster = () => {
  const interactiveObjs = useStore((state) => state.interactiveObjs);
  const activeInteractiveObj = useStore((state) => state.activeInteractiveObj);
  const setActiveInteractiveObj = useStore(
    (state) => state.setActiveInteractiveObj
  );
  const { camera } = useThree();

  const raycaster = useMemo(() => {
    return new Raycaster();
  }, []);

  useEffect(() => {
    const onPlayerClick = () => {
      if (!activeInteractiveObj) return;
      // console.log("playerClick");
      activeInteractiveObj.onInteracted();
    };

    document.addEventListener("playerClick", onPlayerClick);

    return () => {
      document.removeEventListener("playerClick", onPlayerClick);
    };
  }, [activeInteractiveObj]);

  useFrame(() => {
    raycaster.setFromCamera(new Vector2(0, 0), camera);
    var intersects = raycaster.intersectObjects(interactiveObjs);

    setActiveInteractiveObj(
      intersects.length ? (intersects[0].object as IInteractableMesh) : null
    );
  });

  return null;
};

export default CameraRaycaster;
