import React, { useEffect, useLayoutEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { isMobile } from "../../utilities/utils";
import useStore from "../../stores/store";
import logo from "../assets/images/logo.png";
import PlayerBehaviour from "../../library/PlayerBehaviour";
import { Link, Redirect } from "react-router-dom";
import SignIn from "./sign_in/SignIn";
import { RGBA_ASTC_10x5_Format, Scene } from "three";
import { Router } from "react-router";
import { useHistory } from "react-router-dom";

const Onboarding = () => {
  const eventStarted = useStore<boolean>((state) => state.eventStarted);
  const showOnboarding = useStore<boolean>((state) => state.showOnboarding);
  const toggleShowOnboarding = useStore((state) => state.toggleShowOnboarding);

  const playerBehaviour = useStore<PlayerBehaviour>(
    (state) => state.playerBehaviour
  );
  const setEventHasStarted = useStore((state) => state.setEventHasStarted);

  const mobile = isMobile();

  const history = useHistory();

  const start = () => {
    //if (!mobile) playerBehaviour.lockCursor();
    history.push("/sceneselector");
    //toggleShowOnboarding();
    //setEventHasStarted();
    //video.play();
  };

  const eventStartDateTime = useStore<string>(
    (state) => state.eventStartDateTime
  );

  const setEventStarted = useStore((state) => state.setEventStarted);

  const eventStartTime = new Date(eventStartDateTime).getTime();

  const timeNow = new Date().getTime();

  const countDownTime = eventStartTime - timeNow;

  const userLoggedStatusKey = useStore<string>(
    (state) => state.userLoggedStatusKey
  );

  const visualizationMode3DKey = useStore<string>(
    (state) => state.visualizationMode3DKey
  );

  const cachedUserLoggedStatus = localStorage.getItem(userLoggedStatusKey);

  const cachedVisualizationMode3D = localStorage.getItem(
    visualizationMode3DKey
  );

  const setUserLoggedStatusValue = useStore(
    (state) => state.setUserLoggedStatusValue
  );

  const setVisualizationMode3DValue = useStore(
    (state) => state.setVisualizationMode3DValue
  );

  const setUserLoggedStatus = useStore((state) => state.setUserLoggedStatus);

  const setVisualizationMode3D = useStore(
    (state) => state.setVisualizationMode3D
  );

  useLayoutEffect(() => {
    // console.log(countDownTime);

    if (countDownTime <= 0) {
      setEventStarted();

      if (cachedUserLoggedStatus) {
        setUserLoggedStatusValue(cachedUserLoggedStatus);

        setUserLoggedStatus(true);

        var isVisualizationMode3D = cachedVisualizationMode3D === "true";

        setVisualizationMode3DValue(isVisualizationMode3D);

        setVisualizationMode3D(isVisualizationMode3D);

        history.push("/sceneselector");
      }
    }
  }, [
    cachedUserLoggedStatus,
    cachedVisualizationMode3D,
    countDownTime,
    history,
    setEventStarted,
    setUserLoggedStatus,
    setUserLoggedStatusValue,
    setVisualizationMode3DValue,
    userLoggedStatusKey,
  ]);

  useEffect(() => {
    history.push("/sceneselector");
  }, []);

  return (<></>

    // <AnimatePresence>
    //   <motion.div
    //     initial={{ opacity: 0 }}
    //     animate={{ opacity: 1 }}
    //     exit={{ opacity: 0 }}
    //     className="flex h-full justify-center items-center"
    //   >
    //     <SignIn
    //       // onSuccess={() => toggleShowOnboarding()}
    //       onSuccess={() => {
    //         //toggleShowOnboarding();
    //         start();
    //       }}
    //       // onError={() => toggleShowOnboarding()}
    //       onFailure={() => {}}
    //     />
    //   </motion.div>
    // </AnimatePresence>
  );
};

export default Onboarding;
