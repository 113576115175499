import React, { Suspense, useEffect } from "react";
import { Canvas } from "react-three-fiber";
import { Physics } from "use-cannon";
// import { isIos, isAndroid } from "../../utils";
import useStore from "../../../stores/store";
import CameraRaycaster from "./CameraRaycaster";
import Cubemap from "./Cubemap";
import PlayerController from "./PlayerController";
import SceneSwitcher from "./SceneSwitcher";
import Colliders from "../colliders/ColliderConferenceRoom3D"
//import FiturModel from '../scenes/FITUR_GLTF';
import FiturModel from '../scenes/FITUR_IVS';
import ColliderConferenceRoom3D from "../colliders/ColliderConferenceRoom3D";


function SceneFallback() {
  const sceneHasLoaded = useStore((state) => state.sceneHasLoaded);

  // We wait until the fallback is unmounted + 1 second to mark the scene as loaded
  useEffect(() => {
    return () => {
      setTimeout(() => {
        sceneHasLoaded();
      }, 1000);
    };
  }, [sceneHasLoaded]);

  return null;
}

function Scene() {
  const audioListener = useStore((state) => state.audioListener);

  // const pixelRatio = useMemo(() => {
  //   if (isIos()) return 2;
  //   else if (isAndroid() && window.devicePixelRatio > 2) return 2;
  //   else return 1.25;
  // }, []);

  const pixelRatio = 1.25;

  return (
    <Canvas
      colorManagement
      pixelRatio={pixelRatio}
      className="w-full h-full"
      onCreated={({ gl, camera }) => {
        gl.setClearColor("#FFFFFF");
        gl.toneMapping = 0;
        camera.add(audioListener);
      }}
    >
      <hemisphereLight args={[0xf1f1f1, 0x212121, 2.0]} />

      <Physics>
        <PlayerController height={1.7} />

        <CameraRaycaster />

        <Suspense fallback={<SceneFallback />}>
          <Cubemap />
          <FiturModel />
        </Suspense>

        <ColliderConferenceRoom3D />
      </Physics>
    </Canvas>
  );
}

export default Scene;
