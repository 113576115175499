import React from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import useStore from "../../../stores/store";
//import ReactPlayer from 'react-player'
import PlayerBehaviour from "../../../library/PlayerBehaviour";

const ModalVideo = () => {
  const playerBehaviour = useStore<PlayerBehaviour>(
    (state) => state.playerBehaviour
  );

  const onCloseModal = () => {
    setShowModalVideo(false);
    playerBehaviour.lockCursor();
    playerBehaviour.release();
  };

  const showModalVideo = useStore<boolean>((state) => state.showModalVideo);
  const setShowModalVideo = useStore((state) => state.setShowModalVideo);

  //const modalVideoTitle = useStore<string>((state) => state.modalVideoTitle);
  const modalVideoUrl = useStore<string>((state) => state.modalVideoUrl);

  return (
    <div>
      <Modal
        styles={{ modal: { inset: 0 } }}
        open={showModalVideo}
        onClose={onCloseModal}
        center
      >
        {/* <h2>{modalVideoTitle}</h2> */}
        {/*<ReactPlayer playsinline controls playing url='https://stream.mux.com/z6ovUq1bqhTu74DPlZ6wVfUQBcDxomHvAWSM17ke8Ag.m3u8' />*/}
        <video
          id="my-video"
          className="video-js vjs-default-skin vjs-16-9 vjs-big-play-centered"
          controls
          preload="auto"
          poster=""
          data-setup="{}"
        >
          <source src={modalVideoUrl} />
          <p className="vjs-no-js">
            To view this video please enable JavaScript, and consider upgrading
            to a web browser that
            <a href="https://videojs.com/html5-video-support/">
              supports HTML5 video
            </a>
          </p>
        </video>
      </Modal>
    </div>
  );
};

export default ModalVideo;
