import React from "react";
import { useLoader } from "react-three-fiber";
import { TextureLoader } from "three";
import InteractiveMesh from "./InteractiveMesh";
import ReactGA from "react-ga";
interface PosterProps {
  texturePath: string;
  onInteracted?: () => void;
  name: string;
  flipY?: boolean;
}

const Poster: React.FC<PosterProps & JSX.IntrinsicElements["mesh"]> = ({
  texturePath,
  onInteracted,
  flipY = true,
  name,
  ...props
}) => {
  const texture = useLoader(TextureLoader, texturePath);
  texture.flipY = flipY;

  function analytics(name: string) {
    if (name !== "") {
      ReactGA.event({
        category: "Posters", // Required
        action: "event", // Required
        label: name,
      });
    }
  }

  if (onInteracted) {
    return (
      <InteractiveMesh
        {...props}
        onInteracted={() => {
          if (onInteracted) {
            onInteracted();
          }
          // analytics(name);
        }}
      >
        <meshBasicMaterial attach="material" map={texture} />
      </InteractiveMesh>
    );
  } else {
    return (
      <mesh {...props}>
        <meshBasicMaterial attach="material" map={texture} />
      </mesh>
    );
  }
};

export default Poster;
