import useStore from "../../../stores/store";
import InteractDesktopSpanish from "../../../assets/images/interact_desktop_es.png";
import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";

const Crosshair = () => {
  const activeInteractiveObj = useStore((state) => state.activeInteractiveObj);

  const variants = {
    normal: {
      scale: 1,
      background: "rgba(255,255,255, 1)",
      borderColor: "rgba(255,255,255, 0)",
    },
    hover: {
      scale: 2,
      background: "rgba(255,255,255, 0)",
      borderColor: "rgba(255,255,255, 1)",
    },
  };

  const [animationEnded, setAnimationEnded] = useState(false);

  // const variantsVisibility = {
  //   initial: {
  //     opacity: 0,
  //   },
  //   enter: {
  //     opacity: 1,
  //     transition: {
  //       duration: 0.3,
  //       delay: 0.3,
  //       when: "beforeChildren",
  //     },
  //   },
  //   exit: {
  //     opacity: 0,
  //     transition: { duration: 0.3 },
  //   },
  // };

  /** @see {@tutorial https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state} */
  function usePrevious<T>(value: T) {
    const ref = useRef<T>();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current as T;
  }

  const previousActiveInteractiveObj = usePrevious(activeInteractiveObj);

  useEffect(() => {
    if (!activeInteractiveObj) {
      setAnimationEnded(false);
    }
  }, [activeInteractiveObj]);

  return (
    <React.Fragment>
      <motion.div
        initial={"normal"}
        animate={activeInteractiveObj ? "hover" : "normal"}
        variants={variants}
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          width: "0.7rem",
          height: "0.7rem",
          borderWidth: "2px",
          transform: "translate(-50%, -50%)",
          transformOrigin: "50% 50%",
          borderRadius: "50%",
          boxShadow: "0 0 4px rgba(0, 0, 0, 0.5)",
        }}
      />
      {activeInteractiveObj &&
        activeInteractiveObj !== previousActiveInteractiveObj &&
        !animationEnded ? (
        <motion.div
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 1, scale: 1 }}
          transition={{
            duration: 1,
            type: "tween",
            ease: [0, 1, 1, 1],
          }}
          onAnimationComplete={() => {
            setAnimationEnded(true);
          }}
          className="flex justify-center items-center absolute inset-0 mt-24 pointer-events-none"
        >
          <img
            className="w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/5 xl:w-1/6"
            src={InteractDesktopSpanish}
            alt="Click to interact"
          />
        </motion.div>
      ) : (
        <motion.div
          initial={{ opacity: 1, scale: 1 }}
          animate={{ opacity: 0, scale: 0 }}
          exit={{ opacity: 0, scale: 0 }}
          transition={{
            duration: 0.5,
            type: "tween",
            ease: [1, 2 / 3, 1 / 3, 0],
          }}
          onAnimationComplete={() => {
            setAnimationEnded(false);
          }}
          className="flex justify-center items-center absolute inset-0 mt-24 pointer-events-none"
        >
          <img
            className="w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/5 xl:w-1/6"
            src={InteractDesktopSpanish}
            alt="Click to interact"
          />
        </motion.div>
      )}
    </React.Fragment>
  );
};

export default Crosshair;
