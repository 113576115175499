import React, { useRef } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import useStore from "../../../stores/store";
import PlayerBehaviour from "../../../library/PlayerBehaviour";
import ReactPlayer from "react-player";
import { isMobile } from "../../../utilities/utils";
import useWindowOrientation from "use-window-orientation";

interface ModalVideoStreamingProps { }

const ModalVideoStreaming: React.FC<ModalVideoStreamingProps> = () => {
  const playerBehaviour = useStore<PlayerBehaviour>(
    (state) => state.playerBehaviour
  );

  const modalStreamingURL = useStore<string>((state) => state.modalStreamingURL);

  const onCloseModal = () => {
    setShowModalVideoStreaming(false);
    //setShowChat(false);
    playerBehaviour.lockCursor();
    playerBehaviour.release();
  };

  const showModalVideoStreaming = useStore<boolean>(
    (state) => state.showModalVideoStreaming
  );
  const streamingURL = useStore<string>((state) => state.streamingURL);
  const setShowModalVideoStreaming = useStore(
    (state) => state.setShowModalVideoStreaming
  );
  const setShowChat = useStore((state) => state.setShowChat);

  const container = useRef<any>(null);

  const { orientation, portrait, landscape } = useWindowOrientation();

  const streamingLive = useStore<boolean>((state) => state.streamingLive);

  return isMobile() && !landscape ? (
    <div className="flex justify-center items-center" ref={container}>
      <Modal
        open={showModalVideoStreaming}
        onClose={onCloseModal}
        container={container.current}
        center
        // animationDuration={0}
        classNames={{
          modal: "",
          modalContainer: "",
        }}
        styles={{
          overlay: {},
          closeButton: {
            color: "white",
            backgroundColor: "white",
          },
          closeIcon: {
            fill: "white",
            backgroundColor: "black",
          },
          modalContainer: {
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
          modal: {
            inset: 0,
            width: "80%",
            maxWidth: "80%",
            height: "80%" /* Same as width */,
            maxHeight: "80%",
            padding: "2rem",
            display: "table",
            backgroundColor: "black",
            color: "white",
            margin: "auto",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
      >
        <h1
          className={`${isMobile() ? "text-xs" : "text-xl"}`}
          style={{
            height: "2.5%",
          }}
        >
          FITUR - Directo
        </h1>
        <div
          className="flex items-center justify-evenly"
          style={{
            height: "97.5%",
          }}
        >
          <ReactPlayer
            playsinline
            controls
            playing
            url={streamingLive ? streamingURL : modalStreamingURL}
            id="my-video"
            className="video-js vjs-default-skin vjs-16-9 vjs-big-play-centered"
            // width={isMobile() ? "75%" : "100%"}
            // height={isMobile() ? "62.5%" : "85%"}
            width={isMobile() ? "100%" : "100%"}
            height={"80%"}
            margin="auto"
            style={{
              objectFit: "contain",
            }}
          />
        </div>
      </Modal>
    </div>
  ):(
    <div className="absolute inset-0" ref={container}>
      <Modal
        open={showModalVideoStreaming}
        onClose={onCloseModal}
        container={container.current}
        center
        // animationDuration={0}
        classNames={{
          modal: "",
          modalContainer: "",
        }}
        styles={{
          overlay: {},
          closeButton: {
            color: "white",
            backgroundColor: "white",
          },
          closeIcon: {
            fill: "white",
            backgroundColor: "black",
          },
          modalContainer: {
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
          modal: {
            inset: 0,
            width: "80%",
            maxWidth: "80%",
            height: "80%" /* Same as width */,
            maxHeight: "80%",
            padding: "2rem",
            display: "table",
            backgroundColor: "black",
            color: "white",
            margin: "auto",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
      >
        <h1
          className={`${isMobile() ? "text-xs" : "text-xl"}`}
          style={{
            height: "2.5%",
          }}
        >
          FITUR - Directo
        </h1>
        <div
          className="flex items-center justify-evenly"
          style={{
            height: "97.5%",
          }}
        >
          <ReactPlayer
            playsinline
            controls
            playing
            url={streamingLive ? streamingURL : modalStreamingURL}
            id="my-video"
            className="video-js vjs-default-skin vjs-16-9 vjs-big-play-centered"
            // width={isMobile() ? "75%" : "100%"}
            // height={isMobile() ? "62.5%" : "85%"}
            width={isMobile() ? "100%" : "100%"}
            height={"80%"}
            margin="auto"
            style={{
              objectFit: "contain",
            }}
          />
        </div>
      </Modal>
    </div>
  )
};

export default ModalVideoStreaming;

//  <videojs
//   id="my-video"
//   className="flex pt-4 pr-4 w-2/3 video-js vjs-default-skin vjs-16-9 vjs-big-play-centered"
//   controls
//   preload="auto"
//   poster=""
//   data-setup="{}"
// >
//   <source src="https://stream.mux.com/z6ovUq1bqhTu74DPlZ6wVfUQBcDxomHvAWSM17ke8Ag.m3u8" />
//   <p className="vjs-no-js">
//     To view this video please enable JavaScript, and consider
//     upgrading to a web browser that
//     <a href="https://videojs.com/html5-video-support/">
//       supports HTML5 video
//     </a>
//   </p>
// </videojs>
