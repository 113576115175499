import create from "zustand";
import { AudioListener } from "three";
import PlayerBehaviour from "../library/PlayerBehaviour";
import { IInteractableMesh } from "../components/three/common/InteractiveMesh";

export const audioListener = new AudioListener();

const videoStreaming = document.createElement("video");
videoStreaming.setAttribute("playsinline", "playsinline");
videoStreaming.setAttribute("preload", "auto");
videoStreaming.setAttribute("crossorigin", "anonymous");

const video01 = document.createElement("video");
const video02 = document.createElement("video");
const video03 = document.createElement("video");
const video04 = document.createElement("video");

const [useStore] = create((set, get) => ({
  stageVideo: null,
  setStageVideo: (videoAlt: any) => set(() => ({ stageVideo: videoAlt })),

  currentVideoName: "",
  setCurrentVideoName: (videoName: string) =>
    set(() => ({ currentVideoName: videoName })),

  // Event
  eventStartDateTime: "Dec 11, 2019 12:30:00",
  daysLeft: 0,
  setDaysLeft: (daysLeft: number) => set(() => ({ daysLeft: daysLeft })),
  hoursLeft: 0,
  setHoursLeft: (hoursLeft: number) => set(() => ({ hoursLeft: hoursLeft })),
  minutesLeft: 0,
  setMinutesLeft: (minutesLeft: number) =>
    set(() => ({ minutesLeft: minutesLeft })),
  secondsLeft: 0,
  setSecondsLeft: (secondsLeft: number) =>
    set(() => ({ secondsLeft: secondsLeft })),
  eventStarted: false,
  setEventStarted: () => set(() => ({ eventStarted: true })),

  // User Logged Status
  userLoggedStatus: false,
  setUserLoggedStatus: (state: boolean) =>
    set(() => ({ userLoggedStatus: state })),
  userLoggedStatusKey: "userLogged",
  userLoggedStatusValue: "",
  setUserLoggedStatusValue: (state: string) =>
    set(() => ({ userLoggedStatusValue: state })),

  // Active Scene
  activeScene: 0,
  setActiveScene: (activeScene: number) =>
    set(() => ({ activeScene: activeScene })),

  // Loading Screen
  showLoadingScreen: false,
  setShowLoadingScreen: (showLoadingScreen: boolean) =>
    set(() => ({ showLoadingScreen: showLoadingScreen })),

  // Mobile Landscape
  mobileLandscape: false,
  setMobileLandscape: (mobileLandscape: boolean) =>
    set(() => ({ mobileLandscape: mobileLandscape })),

  // Visualization Mode 3D

  visualizationMode3D: false,
  setVisualizationMode3D: (visualizationMode3D: boolean) =>
    set(() => ({ visualizationMode3D: visualizationMode3D })),
  visualizationMode3DKey: "mode3D",
  visualizationMode3DValue: false,
  setVisualizationMode3DValue: (state: boolean) =>
    set(() => ({ visualizationMode3DValue: state })),

  streamingLive: false,
  setStreamingLive: (value: boolean) => set(() => ({ streamingLive: value })),

  modalVideoTitle: "",
  setModalVideoTitle: (value: string) =>
    set(() => ({ modalVideoTitle: value })),

  modalPDFTitle: "",
  setModalPDFTitle: (value: string) => set(() => ({ modalPDFTitle: value })),

  modalLinkTitle: "",
  setModalLinkTitle: (value: string) => set(() => ({ modalLinkTitle: value })),

  modalVideoUrl: "",
  setModalVideoUrl: (value: string) => set(() => ({ modalVideoUrl: value })),

  modalVideoWelcomeTitle: "",
  setModalVideoWelcomeTitle: (value: string) =>
    set(() => ({ modalVideoWelcomeTitle: value })),

  modalVideoWelcomeVideoUrl: "",
  setModalVideoWelcomeVideoUrl: (value: string) =>
    set(() => ({ modalVideoWelcomeVideoUrl: value })),

  modalVideoWithPDFVideoUrl: "",
  setModalVideoWithPDFVideoUrl: (value: string) =>
    set(() => ({ modalVideoWithPDFVideoUrl: value })),
  modalVideoWithPDFFileName: "",
  setModalVideoWithPDFFileName: (value: string) =>
    set(() => ({ modalVideoWithPDFFileName: value })),
  modalVideoWithPDFFileUrl: "",
  setModalVideoWithPDFFileUrl: (value: string) =>
    set(() => ({ modalVideoWithPDFFileUrl: value })),
  modalVideoWithPDFLinkName: "",
  setModalVideoWithPDFLinkName: (value: string) =>
    set(() => ({ modalVideoWithPDFLinkName: value })),
  modalVideoWithPDFLinkUrl: "",
  setModalVideoWithPDFLinkUrl: (value: string) =>
    set(() => ({ modalVideoWithPDFLinkUrl: value })),
  modalVideoWithPDFIncludesLink: false,
  setModalVideoWithPDFIncludesLink: (value: boolean) =>
    set(() => ({ modalVideoWithPDFIncludesLink: value })),

  modalPDFFileName: "",
  setModalPDFFileName: (value: string) =>
    set(() => ({ modalPDFFileName: value })),
  modalPDFFileUrl: "",
  setModalPDFFileUrl: (value: string) =>
    set(() => ({ modalPDFFileUrl: value })),

  modalLinkName: "",
  setModalLinkName: (value: string) => set(() => ({ modalLinkName: value })),
  modalLinkUrl: "",
  setModalLinkUrl: (value: string) => set(() => ({ modalLinkUrl: value })),

  showOnDifferentTab: false,
  setShowOnDifferentTab: (value: boolean) =>
    set(() => ({ showOnDifferentTab: value })),
  //"http://qthttp.apple.com.edgesuite.net/1010qwoeiuryfg/sl.m3u8",

  streamingURL:
    //"https://multiplatform-f.akamaihd.net/i/multi/will/bunny/big_buck_bunny_,640x360_400,640x360_700,640x360_1000,950x540_1500,.f4v.csmil/master.m3u8",
    "https://stream.mux.com/v4yTDYk7g8HWFz6JuFEqlmKYoTnd301rlYQ01ZcNb88oE.m3u8",
  setStreamingURL: (value: string) => set(() => ({ streamingURL: value })),

  modalStreamingURL:
    //"https://stream.mux.com/z6ovUq1bqhTu74DPlZ6wVfUQBcDxomHvAWSM17ke8Ag.m3u8",
    //"https://www.youtube.com/embed/wHn1_QVoXGM",
    "",
  setModalStreamingURL: (value: string) => set(() => ({ modalStreamingURL: value })),

  useModalVideo: false,
  setUseModalVideo: (value: boolean) => set(() => ({ useModalVideo: value })),
  platformName: "",
  setPlatformName: (value: string) => set(() => ({ platformName: value })),
  platformProduct: "",
  setPlatformProduct: (value: string) =>
    set(() => ({ platformProduct: value })),
  platformOsFamily: "",
  setPlatformOsFamily: (value: string) =>
    set(() => ({ platformOsFamily: value })),
  platformOsVersion: "",
  setPlatformOsVersion: (value: string) =>
    set(() => ({ platformOsVersion: value })),
  showModalVideo: false,
  setShowModalVideo: (value: boolean) => set(() => ({ showModalVideo: value })),

  showModalImage: false,
  setShowModalImage: (value: boolean) => set(() => ({ showModalImage: value })),

  showModalVideoWelcome: false,
  setShowModalVideoWelcome: (value: boolean) =>
    set(() => ({ showModalVideoWelcome: value })),

  showModalVideoWithPDF: false,
  setShowModalVideoWithPDF: (value: boolean) =>
    set(() => ({ showModalVideoWithPDF: value })),

  showModalPDF: false,
  setShowModalPDF: (value: boolean) => set(() => ({ showModalPDF: value })),

  showModalLink: false,
  setShowModalLink: (value: boolean) => set(() => ({ showModalLink: value })),

  showModalVideoStreaming: false,
  setShowModalVideoStreaming: (value: boolean) =>
    set(() => ({ showModalVideoStreaming: value })),
    

  streamingToday: false,
  setStreamingToday: (value: boolean) => set(() => ({ streamingToday: value })),

  noChatLanding: true,
  setNoChatLanding: (value: boolean) => set(() => ({ noChatLanding: value })),

  showLanding: true,
  setShowLanding: (value: boolean) => set(() => ({ showLanding: value })),

  // Event
  showModal: false,
  toggleModal: () => set((state) => ({ showModal: !state.showModal })),

  showStreamingModal: false,
  toggleStreamingModal: (estado: boolean) =>
    set(() => ({
      showStreamingModal: estado,
    })),
  showChat: false,
  setShowChat: (value: boolean) => set({ showChat: value }),
  toggleChat: (state: boolean) => set(() => ({ showChat: state })),
  // streamingcurrent: 0,
  // setstreamingcurrent: (current: number) => set(() => ({ streamingcurrent: current })),
  finishQuiz: false,
  setFinishQuiz: (estado: boolean) => set((state) => ({ finishQuiz: estado })),
  finStreaming: false,
  setFinStreaming: (estado: boolean) =>
    set((state) => ({ finStreaming: estado })),
  // eventStartDate: new Date("Nov 5, 2020 15:45:00"),
  eventStartDate: new Date(Date.now()),
  eventTimeElapsed: () => Date.now() - get().eventStartDate.getTime(),
  // eventFooterDate: new Date("Nov 5, 2020 13:00:00"),
  eventFooterDate: new Date(Date.now() + 30 * 1000),
  eventFooterTimeElapsed: () => Date.now() - get().eventFooterDate.getTime(),
  setEventHasStarted: () => set(() => ({ hasEventStarted: true })),
  enableSorteo: false,
  enableSorteoMeshes: () => set(() => ({ enableSorteo: true })),
  enableImascono: false,
  enableImasconoMeshes: () => set(() => ({ enableImascono: true })),
  estadoTV: 0,
  setEstadoTV: (num: any) => set(() => ({ estadoTV: num })),
  // UI
  showOnboarding: true,
  toggleShowOnboarding: () =>
    set((state) => ({ showOnboarding: !state.showOnboarding })),
  showInfoControls: true,
  setShowInfoControls: (value: boolean) => set(() => ({ showInfoControls: value })),
  toggleShowInfoControls: () =>
    set((state) => ({ showInfoControls: !state.showInfoControls })),
  modalMode: "",
  modalModeName: (name: any) => set(() => ({ modalMode: name })),
  // THREE
  isSceneLoaded: false,
  sceneHasLoaded: () => set(() => ({ isSceneLoaded: true })),
  audioListener,
  playerBehaviour: new PlayerBehaviour(),
  videoStreaming,
  video01,
  video02,
  video03,
  video04,

  interactiveObjs: [],
  addInteractiveObj: (obj: any) =>
    set((state) => ({
      interactiveObjs: [obj, ...state.interactiveObjs],
    })),
  removeInteractiveObj: (obj: any) =>
    set((state) => ({
      interactiveObjs: state.interactiveObjs.filter((o: any) => o !== obj),
    })),

  activeInteractiveObj: null,
  setActiveInteractiveObj: (obj: IInteractableMesh | null) =>
    set(() => ({ activeInteractiveObj: obj })),
}));

export default useStore;
