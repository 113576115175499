import React, { useMemo } from "react";
import { useBox, usePlane } from "use-cannon";
import { MeshBasicMaterial, MathUtils } from "three";

interface WallProps {
  pos: number[];
  rot: number[];
  scale: number[];
  material: MeshBasicMaterial;
}

const Wall: React.FC<WallProps> = ({ pos, rot, scale, material }) => {
  const [bodyRef] = useBox(() => ({
    args: scale.map((n) => n),
    position: pos,
    rotation: rot,
    type: "Kinematic",
    material: { friction: 0 },
  }));

  return (
    <mesh ref={bodyRef} material={material} visible={false}>
      <boxBufferGeometry
        attach="geometry"
        args={[scale[0] * 2, scale[1] * 2, scale[2] * 2]}
      />
    </mesh>
  );
};

const Floor = () => {
  const [bodyRef] = usePlane(() => ({
    rotation: [-Math.PI / 2, 0, 0],
    type: "Kinematic",
    material: { friction: 0 },
  }));

  return (
    <mesh ref={bodyRef} visible={false}>
      <planeBufferGeometry attach="geometry" args={[100, 100]} />
      <meshBasicMaterial attach="material" color="red" />
    </mesh>
  );
};

const ColliderConferenceRoom3D = () => {
  const wallCollidersData = [
    {
      pos: [-0.300, 0.452, -6.335],
      rot: [
        MathUtils.DEG2RAD * 0.0,
        MathUtils.DEG2RAD * -26.47,
        MathUtils.DEG2RAD * 0.0,
      ],
      scale: [5.545, 1.000, 1.000],
    },
    {
      pos: [-3.155, 0.452, -6.163],
      rot: [
        MathUtils.DEG2RAD * 0.0,
        MathUtils.DEG2RAD * 0.0,
        MathUtils.DEG2RAD * 0.0,
      ],
      scale: [1.000, 1.000, 5.881],
    },
    {
      pos: [-7.943, 0.452, -3.717],
      rot: [
        MathUtils.DEG2RAD * 0,
        MathUtils.DEG2RAD * 89.80,
        MathUtils.DEG2RAD * 0,
      ],
      scale: [1.000, 1.000, 9.541],
    },
    {
      pos: [-12.315, 0.452, -6.163],
      rot: [
        MathUtils.DEG2RAD * 0.0,
        MathUtils.DEG2RAD * 0.0,
        MathUtils.DEG2RAD * 0.0,
      ],
      scale: [1.000, 1.000, 5.881],
    },
    {
      pos: [-14.711, 0.302, -7.034],
      rot: [
        MathUtils.DEG2RAD * -180.00,
        MathUtils.DEG2RAD * 90.00,
        MathUtils.DEG2RAD * -180.00,
      ],
      scale: [1.000, 1.000, 4.321],
    },
    {
      pos: [-16.812, 0.452, -0.686],
      rot: [
        MathUtils.DEG2RAD * 0.0,
        MathUtils.DEG2RAD * 0.0,
        MathUtils.DEG2RAD * 0.0,
      ],
      scale: [1.271, 1.000, 12.788],
    },
    {
      pos: [-15.427, 0.452, 6.756],
      rot: [
        MathUtils.DEG2RAD * 0.0,
        MathUtils.DEG2RAD * 44.99,
        MathUtils.DEG2RAD * 0.0,
      ],
      scale: [1.000, 1.000, 5.881],
    },
    {
      pos: [-7.896, 0.452, 7],
      rot: [
        MathUtils.DEG2RAD * 0.0,
        MathUtils.DEG2RAD * -89.80,
        MathUtils.DEG2RAD * 0.0,
      ],
      scale: [1.000, 1.000, 17.221],
    },
    {
      pos: [-2.333, 0.452, 7.011],
      rot: [
        MathUtils.DEG2RAD * 166.78,
        MathUtils.DEG2RAD * -18.92,
        MathUtils.DEG2RAD * 178.32,
      ],
      scale: [5.545, 1.000, 1.000],
    },
    {
      pos: [0.198, 0.452, -0.262],
      rot: [
        MathUtils.DEG2RAD * 0.0,
        MathUtils.DEG2RAD * 0.0,
        MathUtils.DEG2RAD * 0.0,
      ],
      scale: [1.271, 1.000, 12.788],
    },
    {
      pos: [-0.569, 0.254, 2.030],
      rot: [
        MathUtils.DEG2RAD * 0.0,
        MathUtils.DEG2RAD * 0.0,
        MathUtils.DEG2RAD * 0.0,
      ],
      scale: [1.000, 1.000, 1.000],
    }
    
  ];

  const wallMaterial = useMemo(() => {
    return new MeshBasicMaterial({ color: "green" });
  }, []);

  return (
    <>
      {wallCollidersData.map((c, i) => (
        <Wall key={`wall-${i}`} {...c} material={wallMaterial} />
      ))}

      <Floor />
    </>
  );
};

export default ColliderConferenceRoom3D;
