import React, { useRef } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import useStore from "../../../stores/store";
import PlayerBehaviour from "../../../library/PlayerBehaviour";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faDownload } from "@fortawesome/free-solid-svg-icons";
import ReactPlayer from "react-player";
import { isMobile } from "../../../utilities/utils";

const ModalVideoWelcome = () => {
  const playerBehaviour = useStore<PlayerBehaviour>(
    (state) => state.playerBehaviour
  );

  const onCloseModal = () => {
    setShowModalVideoWelcome(false);
    localStorage.setItem("firstTime", "false");
    // playerBehaviour.lockCursor();
    // playerBehaviour.release();
  };

  const showModalVideoWelcome = useStore<boolean>(
    (state) => state.showModalVideoWelcome
  );
  const setShowModalVideoWelcome = useStore(
    (state) => state.setShowModalVideoWelcome
  );

  const modalVideoWelcomeTitle = useStore<string>(
    (state) => state.modalVideoWelcomeTitle
  );
  const modalVideoWelcomeVideoUrl = useStore<string>(
    (state) => state.modalVideoWelcomeVideoUrl
  );

  const container = useRef<any>(null);

  const showLoadingScreen = useStore<boolean>(
    (state) => state.showLoadingScreen
  );

  return (
    <div>
      <Modal
        open={showModalVideoWelcome && !showLoadingScreen}
        onClose={onCloseModal}
        container={container.current}
        center
        // animationDuration={0}
        classNames={{
          modal: "",
          modalContainer: "",
        }}
        styles={{
          overlay: {},
          closeButton: {
            color: "white",
            backgroundColor: "white",
          },
          closeIcon: {
            color: "white",
            backgroundColor: "white",
          },
          modalContainer: {
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
          modal: {
            inset: 0,
            width: "80%",
            maxWidth: "80%",
            height: "80%" /* Same as width */,
            maxHeight: "80%",
            padding: "2rem",
            display: "table",
            backgroundColor: "white",
            color: "black",
            margin: "auto",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
      >
        <h1
          className={`${isMobile() ? "text-xs" : "text-xl"}`}
          style={{
            height: "2.5%",
          }}
        >
          {modalVideoWelcomeTitle}{" "}
        </h1>
        <div
          className="flex items-center justify-evenly"
          style={{
            height: "97.5%",
          }}
        >
          <ReactPlayer
            playsinline
            controls
            playing
            url={modalVideoWelcomeVideoUrl}
            id="my-video"
            className="video-js vjs-default-skin vjs-16-9 vjs-big-play-centered"
            width={isMobile() ? "100%" : "100%"}
            height={"80%"}
            margin="auto"
            style={{
              objectFit: "contain",
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default ModalVideoWelcome;
