import React, { useEffect } from "react";
import { useLoader, useThree } from "react-three-fiber";
import { CubeTextureLoader, GammaEncoding } from "three";

import px from "../../../assets/textures/cubemap/px.png";
import nx from "../../../assets/textures/cubemap/nx.png";
import py from "../../../assets/textures/cubemap/py.png";
import ny from "../../../assets/textures/cubemap/ny.png";
import pz from "../../../assets/textures/cubemap/pz.png";
import nz from "../../../assets/textures/cubemap/nz.png";

const Cubemap: React.FC = () => {
  // @ts-ignore
  const [envMap] = useLoader(CubeTextureLoader, [[px, nx, py, ny, pz, nz]]);

  const { scene } = useThree();

  useEffect(() => {
    envMap.encoding = GammaEncoding;
    scene.background = envMap;
    scene.environment = envMap;
  }, [envMap, scene]);

  return null;
};

export default Cubemap;
