import React, { useRef } from "react";
import conferenceRoom from "../../assets/images/conferenceRoom2D.png";
import PlayerBehaviour from "../../library/PlayerBehaviour";
import useStore from "../../stores/store";
import ReactPlayer from "react-player";
import ReactGA from "react-ga";
import { isMobile } from "../../utilities/utils";
import AgendaLogo from "../../assets/images/SVG/agenda_v2.svg";
import Miercoles from "../../assets/images/landing/miercoles.png";
import Jueves from "../../assets/images/landing/jueves.png";
import Viernes from "../../assets/images/landing/viernes.png";
import Sabado from "../../assets/images/landing/sabado.png";
import Domingo from "../../assets/images/landing/domingo.png";
import RepositorioLogo from "../../assets/images/SVG/repositorio.svg";

const Repositorio = () => {
  const playerBehaviour = useStore<PlayerBehaviour>(
    (state) => state.playerBehaviour
  );

  const onCloseModal = () => {
    setShowModalVideoStreaming(false);
    //setShowChat(false);
    playerBehaviour.lockCursor();
    playerBehaviour.release();
  };

  const showModalVideoStreaming = useStore<boolean>(
    (state) => state.showModalVideoStreaming
  );
  const streamingURL = useStore<string>((state) => state.streamingURL);
  const setShowModalVideoStreaming = useStore(
    (state) => state.setShowModalVideoStreaming
  );
  const setShowChat = useStore((state) => state.setShowChat);

  const container = useRef<any>(null);

  const mobile = isMobile();

  const SendEvent = (category: string, label: string) => {
    ReactGA.event({
      category, // Required
      action: "event", // Required
      label,
    });
  };

  return !isMobile() ? (
    <div
      className={`flex flex-col w-full h-full items-start`}
      style={{ background: "#f2f2f2" }}
    >
      <div className="mt-36" style={{ background: "#f2f2f2" }}>
        <div className="flex flex-row" style={{ paddingLeft: "3vw" }}>
          <img
            className="h-14" 
            style={{paddingRight:"0.7vw"}}
            src={RepositorioLogo}
            alt="Agenda Logo"
          />
          <p className="text-2xl font-montserrat-bold">Ver Presentaciones</p>
        </div>
        <div className="flex flex-col pt-12 gap-8 pb-36 px-44">
          <div className="flex flex-row justify-evenly items-center">
            <div className="flex w-1/3 flex-col">
              <img
                className="rounded-lg"
                src={Miercoles}
                alt="Inicio"
              />
            </div>
            <div className="flex flex-row items-start gap-4 font-montserrat-normal" style={{ fontSize: "1vw" }}>
              <div className="flex flex-col items-start font-montserrat-bold gap-2">
                <div>11:00</div>
                <div className="invisible" style={{ fontSize: "1.65vw" }}>11:00</div>
                <div>11:30</div>
                <div className="invisible" style={{ fontSize: "1.65vw" }}>11:30</div>
                <div>12:30</div>
                <div>13:30</div>
                <div>13:30</div>
                <div>14:00</div>
                <div>16:00</div>
                <div className="invisible" style={{ fontSize: "1.65vw" }}>11:30</div>
                <div>16:30</div>
                <div className="invisible" style={{ fontSize: "1.65vw" }}>11:30</div>
                <div>16:30</div>
                <div>17:30</div>
                <div>18:00</div>
              </div>
              <div className="flex flex-col items-start gap-2">
                
                <div>Calatayud Destino Turístico Sostenible 2023</div>
                <button className="border-2 border-red-600 bg-red-600 text-white rounded-full hover:bg-white hover:text-red-600 hover:border-red-600" 
                onClick={()=>{
                  window.open("https://youtu.be/-QAKw3LMhWw", "_blank")
                }}>
                  <div style={{fontSize:"0.8vw", padding:"0.5vw 0.8vw 0.5vw 0.8vw"}}>
                    Ver directo
                  </div>
                  
                </button>
                
                <div>Premios Excelencia al Desarrollo del Turismo Sostenible. Premios Edelweis</div>
                <button className="border-2 border-red-600 bg-red-600 text-white rounded-full hover:bg-white hover:text-red-600 hover:border-red-600" 
                onClick={()=>{
                  window.open("https://youtu.be/GdETf-1siRU", "_blank")
                }}>
                  <div style={{fontSize:"0.8vw", padding:"0.5vw 0.8vw 0.5vw 0.8vw"}}>
                    Ver directo
                  </div>
                  
                </button>
                
                <div>Cata a ciegas</div>
                <div>Un destino una cocina: Huesca</div>
                <div>Cata Enoturismo Aragón</div>
                <div>Un destino una cocina: Zaragoza</div>

                <div>La Carrasca milenaria de Lecina, punto de partida de una gran historia</div>
                <button className="border-2 border-red-600 bg-red-600 text-white rounded-full hover:bg-white hover:text-red-600 hover:border-red-600" 
                onClick={()=>{
                  window.open("https://youtu.be/Nix7FxCy3wA", "_blank")
                }}>
                  <div style={{fontSize:"0.8vw", padding:"0.5vw 0.8vw 0.5vw 0.8vw"}}>
                    Ver directo
                  </div>
                  
                </button>
                <div>Comarca SOMONTANO: El Valle de Rodellar</div>
                <button className="border-2 border-red-600 bg-red-600 text-white rounded-full hover:bg-white hover:text-red-600 hover:border-red-600" 
                onClick={()=>{
                  window.open("https://youtu.be/4snlXbVOzEs", "_blank")
                }}>
                  <div style={{fontSize:"0.8vw", padding:"0.5vw 0.8vw 0.5vw 0.8vw"}}>
                    Ver directo
                  </div>
                  
                </button>
                <div>Cata a ciegas</div>
                <div>Un destino una cocina: Teruel</div>
                <div>Cata enoturismo Aragón</div>
              </div>
            </div>
          </div>
          <hr
            style={{
                height: "0.05vw",
                backgroundColor: "#ae2b42",
                border: "none",
            }}
          >
          </hr>
          <div className="flex flex-row justify-evenly items-center" style={{ backgroundColor: "#f2f2f2" }}>
            <div className="flex flex-row items-start gap-4 font-montserrat-normal" style={{ fontSize: "1vw" }}>
              <div className="flex flex-col items-start font-montserrat-bold gap-2">
                <div>11:00</div>
                <div>11:30</div>
                <div className="invisible" style={{ fontSize: "1.65vw" }}>11:00</div>
                <div>12:00</div>
                <div className="invisible" style={{ fontSize: "1.65vw" }}>11:00</div>
                <div>12:30</div>
                <div>13:00</div>
                <div>13:30</div>
                <div>14:00</div>
                <div>16:00</div>
                <div className="invisible" style={{ fontSize: "1.65vw" }}>11:00</div>
                <div>16:30</div>
                <div className="invisible" style={{ fontSize: "1.65vw" }}>11:00</div>
                <div>16:30</div>
                <div>17:00</div>
                <div className="invisible" style={{ fontSize: "1.65vw" }}>11:00</div>
                <div>17:30</div>
                <div className="invisible" style={{ fontSize: "1.65vw" }}>11:00</div>
                <div>17:30</div>
                <div>18:00</div>
              </div>
              <div className="flex flex-col items-start gap-2">
                <div>Dinópolis: 20 Aniversario</div>
                <div>Categorización Jamón y Paleta D.O.</div>
                <button className="border-2 border-red-600 bg-red-600 text-white rounded-full hover:bg-white hover:text-red-600 hover:border-red-600" 
                onClick={()=>{
                  window.open("https://youtu.be/u1XLIev3NBE", "_blank")
                }}>
                  <div style={{fontSize:"0.8vw", padding:"0.5vw 0.8vw 0.5vw 0.8vw"}}>
                    Ver directo
                  </div>
                  
                </button>
                <div>El Origen de un Genio</div>
                <button className="border-2 border-red-600 bg-red-600 text-white rounded-full hover:bg-white hover:text-red-600 hover:border-red-600" 
                onClick={()=>{
                  window.open("https://youtu.be/hTeQ6VmwSfY", "_blank")
                }}>
                  <div style={{fontSize:"0.8vw", padding:"0.5vw 0.8vw 0.5vw 0.8vw"}}>
                    Ver directo
                  </div>
                  
                </button>
                <div>Cata a ciegas</div>
                <div>Un destino una cocina: Teruel</div>
                <div>Cata Enoturismo Aragón</div>
                <div>Un destino una cocina: Huesca</div>
                <div>Birding Aragón. Esperiencias de ecoturismo</div>
                <button className="border-2 border-red-600 bg-red-600 text-white rounded-full hover:bg-white hover:text-red-600 hover:border-red-600" 
                onClick={()=>{
                  window.open("https://youtu.be/qrqueQOcShk", "_blank")
                }}>
                  <div style={{fontSize:"0.8vw", padding:"0.5vw 0.8vw 0.5vw 0.8vw"}}>
                    Ver directo
                  </div>
                  
                </button>
                <div>Teruel Es</div>
                <button className="border-2 border-red-600 bg-red-600 text-white rounded-full hover:bg-white hover:text-red-600 hover:border-red-600" 
                onClick={()=>{
                  window.open("https://youtu.be/JPuac7h0dE8", "_blank")
                }}>
                  <div style={{fontSize:"0.8vw", padding:"0.5vw 0.8vw 0.5vw 0.8vw"}}>
                    Ver directo
                  </div>
                  
                </button>
                <div>Cata a ciegas</div>
                <div>Guía Siente Teruel</div>
                <button className="border-2 border-red-600 bg-red-600 text-white rounded-full hover:bg-white hover:text-red-600 hover:border-red-600" 
                onClick={()=>{
                  window.open("https://youtu.be/zlfiz-9dOlY", "_blank")
                }}>
                  <div style={{fontSize:"0.8vw", padding:"0.5vw 0.8vw 0.5vw 0.8vw"}}>
                    Ver directo
                  </div>
                  
                </button>
                <div>Dos lenguajes y una experiencia única</div>
                <button className="border-2 border-red-600 bg-red-600 text-white rounded-full hover:bg-white hover:text-red-600 hover:border-red-600" 
                onClick={()=>{
                  window.open("https://youtu.be/VQWX2rUwVQ8", "_blank")
                }}>
                  <div style={{fontSize:"0.8vw", padding:"0.5vw 0.8vw 0.5vw 0.8vw"}}>
                    Ver directo
                  </div>
                  
                </button>
                <div>Un destino una cocina: Zaragoza</div>
                <div>Cata Enoturismo Aragón</div>
              </div>
            </div>
            <div className="flex w-1/3 flex-col">
              <img
                className="rounded-lg"
                src={Jueves}
                alt="Inicio"
              />
            </div>
          </div>
          <hr
            style={{
                height: "0.05vw",
                backgroundColor: "#ae2b42",
                border: "none",
            }}
          >
          </hr>
          <div className="flex flex-row justify-evenly items-center">
            <div className="flex w-1/3 flex-col">
              <img
                className="rounded-lg"
                src={Viernes}
                alt="Inicio"
              />
            </div>
            <div className="flex flex-col gap-2">
              <button className="border-2 border-red-600 bg-red-600 text-white rounded-full hover:bg-white hover:text-red-600 hover:border-red-600 w-1/2" 
                  onClick={()=>{
                    window.open("https://youtu.be/Ay5gx3KfjoA", "_blank")
                  }}>
                    <div style={{fontSize:"0.8vw", padding:"0.5vw 0.8vw 0.5vw 0.8vw"}}>
                      Ver directo de todo el día
                    </div>
                    
              </button>
              <div className="flex flex-row items-start gap-4 font-montserrat-normal" style={{ fontSize: "1vw" }}>
                <div className="flex flex-col items-start font-montserrat-bold gap-2">
                  <div>11:30</div>
                  <div>12:00</div>
                  <div>12:05</div>
                  <div>12:25</div>
                  <div>12:30</div>
                  <div>12:45</div>
                  <div>13:00</div>
                  <div>13:15</div>
                  <div>16:00</div>
                  <div>16:30</div>
                  <div>17:30</div>
                  <div>18:00</div>
                </div>
                <div className="flex flex-col items-start gap-2">
                  <div>Recorrido Institucional</div>
                  <div>Presentación de Goya</div>
                  <div>Bienvenida a cargo de la Directora General de Turismo, Elena Allué</div>
                  <div>Goya: Intervención artística</div>
                  <div>Presentación del Camino de Santiago</div>
                  <div>Discurso del Vicepresidente de Aragón, Arturo Aliaga</div>
                  <div>Presentación Enoturismo: Vinos de Aragón</div>
                  <div>Cata a ciegas y experiencia culinaria</div>
                  <div>Ruta del vino de Somontano. No te lo puedes perder</div>
                  <div>Aragón Incoming</div>
                  <div>Un destino una cocina</div>
                  <div>Cata Enoturismo Aragón</div>
                </div>
              </div>
            </div>
          </div>
           
          <hr
            style={{
                height: "0.05vw",
                backgroundColor: "#ae2b42",
                border: "none",
            }}
          >
          </hr>
          <div className="flex flex-row justify-evenly items-center" style={{ backgroundColor: "#f2f2f2" }}>
            <div className="flex flex-row items-center gap-4 font-montserrat-normal" style={{ fontSize: "1vw" }}>
              <div className="flex flex-col items-start font-montserrat-bold gap-2">
                <div>12:30</div>
                <div>13:00</div>
                <div>13:30</div>
                <div>14:00</div>
                <div>16:30</div>
                <div>17:30</div>
                <div>18:00</div>
              </div>
              <div className="flex flex-col items-start gap-2">
                <div>Cata a ciegas</div>
                <div>Un destino una cocina: Huesca</div>
                <div>Cata Enoturismo Aragón</div>
                <div>Un destino una cocina: Zaragoza</div>
                <div>Cata a ciegas</div>
                <div>Un destino una cocina: Teruel</div>
                <div>Cata Enoturismo Aragón</div>
              </div>
            </div>
            <div className="flex w-1/3 flex-col">
              <img
                className="rounded-lg"
                src={Sabado}
                alt="Inicio"
              />
            </div>
          </div>
          <hr
            style={{
                height: "0.05vw",
                backgroundColor: "#ae2b42",
                border: "none",
            }}
          >
          </hr>
          <div className="flex flex-row justify-evenly items-center">
            <div className="flex w-1/3 flex-col">
              <img
                className="rounded-lg"
                src={Domingo}
                alt="Inicio"
              />
            </div>
            <div className="flex flex-row items-center gap-4 font-montserrat-normal" style={{ fontSize: "1vw" }}>
              <div className="flex flex-col items-start font-montserrat-bold gap-2">
                <div>12:30</div>
                <div>13:00</div>
                <div>13:30</div>
                <div>14:00</div>
                <div>16:30</div>
              </div>
              <div className="flex flex-col items-start gap-2">
                <div>Cata a ciegas</div>
                <div>Un destino una cocina: Teruel</div>
                <div>Cata Enoturismo Aragón</div>
                <div>Un destino una cocina: Huesca</div>
                <div>Un destino una cocina: Zaragoza</div>
              </div>

            </div>
          </div>
        </div>
      </div>

    </div>
  ) : (
    <div
      className={`flex flex-col w-full h-full items-start`}
      style={{ background: "#f2f2f2" }}
    >
      <div className="mt-24 pb-24 md:mt-36 lg:mt-52" style={{ background: "#f2f2f2" }} >
        <div className="flex flex-row items-center" style={{ paddingLeft: "3vw" }}>
          <img
            className="h-14 md:h-20 lg:h-32 mr-2 md:mr-4 lg:mr-6"
            src={RepositorioLogo}
            alt="Agenda Logo"
          />
          <p className="text-xl md:text-2xl lg:text-4xl font-montserrat-bold">Ver Presentaciones</p>
        </div>
        <div className="flex flex-col pt-6 gap-12">
          <div className="flex flex-col justify-center items-center">
            <div className="flex w-4/5 flex-col mb-4">
              <img
                className="rounded-lg"
                src={Miercoles}
                alt="Inicio"
              />
            </div>
            <div className="flex w-full flex-col justify-center items-center gap-2 font-montserrat-normal" style={{ fontSize: "3vw" }}>
              <div className="flex flex-row w-3/4 items-center gap-3 align-items">
                <div className="font-montserrat-bold w-6 md:w-20 lg:w-24">11:00</div>
                <div>Calatayud Destino Turístico Sostenible 2023</div>
              </div>
              <div className="justify-start items-start">
                <button className="border-2 border-red-600 bg-red-600 text-white rounded-full" 
                onClick={()=>{
                  window.open("https://youtu.be/-QAKw3LMhWw", "_blank")
                }}>
                  <div style={{fontSize:"3vw", padding:"2vw 2.5vw 2vw 2.5vw"}}>
                    Ver directo
                  </div>
                  
                </button>
              </div>
              <div className="flex flex-row w-3/4 items-center gap-3">
                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">11:30</div>
                <div>Premios Excelencia al Desarrollo del Turismo Sostenible. Premios Edelweis</div>
              </div>
              <div className="justify-center">
                <button className="border-2 border-red-600 bg-red-600 text-white rounded-full" 
                onClick={()=>{
                  window.open("https://youtu.be/GdETf-1siRU", "_blank")
                }}>
                  <div style={{fontSize:"3vw", padding:"2vw 2.5vw 2vw 2.5vw"}}>
                    Ver directo
                  </div>
                  
                </button>
              </div>
              <div className="flex flex-row w-3/4 items-center gap-3">
                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">12:30</div>
                <div>Cata a ciegas</div>
              </div>
              <div className="flex flex-row w-3/4 items-center gap-3">
                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">13:30</div>
                <div>Un destino una cocina: Huesca</div>
              </div>
              <div className="flex flex-row w-3/4 items-center gap-3">
                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">13:30</div>
                <div>Cata Enoturismo Aragón</div>
              </div>
              <div className="flex flex-row w-3/4 items-center gap-3">
                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">14:00</div>
                <div>Un destino una cocina: Zaragoza</div>
              </div>
              <div className="flex flex-row w-3/4 items-center gap-3">
                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">16:00</div>
                <div>La Carrasca milenaria de Lecina, punto de partida de una gran historia</div>
              </div>
              <div className="justify-center">
                <button className="border-2 border-red-600 bg-red-600 text-white rounded-full" 
                onClick={()=>{
                  window.open("https://youtu.be/Nix7FxCy3wA", "_blank")
                }}>
                  <div style={{fontSize:"3vw", padding:"2vw 2.5vw 2vw 2.5vw"}}>
                    Ver directo
                  </div>
                  
                </button>
              </div>
              <div className="flex flex-row w-3/4 items-center gap-3">
                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">16:30</div>
                <div>Comarca SOMONTANO: El Valle de Rodellar</div>
              </div>
              <div className="justify-center">
                <button className="border-2 border-red-600 bg-red-600 text-white rounded-full" 
                onClick={()=>{
                  window.open("https://youtu.be/4snlXbVOzEs", "_blank")
                }}>
                  <div style={{fontSize:"3vw", padding:"2vw 2.5vw 2vw 2.5vw"}}>
                    Ver directo
                  </div>
                  
                </button>
              </div>
              <div className="flex flex-row w-3/4 items-center gap-3">
                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">16:30</div>
                <div>Cata a ciegas</div>
              </div>
              <div className="flex flex-row w-3/4 items-center gap-3">
                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">17:30</div>
                <div>Un destino una cocina: Teruel</div>
              </div>
              <div className="flex flex-row w-3/4 items-center gap-3">
                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">18:00</div>
                <div>Cata enoturismo Aragón</div>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-center items-center">
            <div className="flex w-4/5 flex-col mb-4">
              <img
                className="rounded-lg"
                src={Jueves}
                alt="Inicio"
              />
            </div>
            <div className="flex w-full flex-col justify-center items-center gap-2 font-montserrat-normal" style={{ fontSize: "3vw" }}>
              <div className="flex flex-row w-3/4 items-center gap-3 align-items">
                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">11:00</div>
                <div>Dinópolis: 20 Aniversario</div>
              </div>
              <div className="flex flex-row w-3/4 items-center gap-3">
                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">11:30</div>
                <div>Categorización Jamón y Paleta D.O.</div>
              </div>
              <div className="justify-center">
                <button className="border-2 border-red-600 bg-red-600 text-white rounded-full" 
                onClick={()=>{
                  window.open("https://youtu.be/u1XLIev3NBE", "_blank")
                }}>
                  <div style={{fontSize:"3vw", padding:"2vw 2.5vw 2vw 2.5vw"}}>
                    Ver directo
                  </div>
                  
                </button>
              </div>
              <div className="flex flex-row w-3/4 items-center gap-3">
                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">12:00</div>
                <div>El Origen de un Genio</div>
              </div>
              <div className="justify-center">
                <button className="border-2 border-red-600 bg-red-600 text-white rounded-full" 
                onClick={()=>{
                  window.open("https://youtu.be/hTeQ6VmwSfY", "_blank")
                }}>
                  <div style={{fontSize:"3vw", padding:"2vw 2.5vw 2vw 2.5vw"}}>
                    Ver directo
                  </div>
                  
                </button>
              </div>
              <div className="flex flex-row w-3/4 items-center gap-3">
                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">12:30</div>
                <div>Cata a ciegas</div>
              </div>
              <div className="flex flex-row w-3/4 items-center gap-3">
                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">13:00</div>
                <div>Un destino una cocina: Teruel</div>
              </div>
              <div className="flex flex-row w-3/4 items-center gap-3">
                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">13:30</div>
                <div>Cata Enoturismo Aragón</div>
              </div>
              <div className="flex flex-row w-3/4 items-center gap-3">
                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">14:00</div>
                <div>Un destino una cocina: Huesca</div>
              </div>
              <div className="flex flex-row w-3/4 items-center gap-3">
                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">16:00</div>
                <div>Birding Aragón. Esperiencias de ecoturismo</div>
              </div>
              <div className="justify-center">
                <button className="border-2 border-red-600 bg-red-600 text-white rounded-full" 
                onClick={()=>{
                  window.open("https://youtu.be/qrqueQOcShk", "_blank")
                }}>
                  <div style={{fontSize:"3vw", padding:"2vw 2.5vw 2vw 2.5vw"}}>
                    Ver directo
                  </div>
                  
                </button>
              </div>
              <div className="flex flex-row w-3/4 items-center gap-3">
                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">16:30</div>
                <div>Teruel Es</div>
              </div>
              <div className="justify-center">
                <button className="border-2 border-red-600 bg-red-600 text-white rounded-full" 
                onClick={()=>{
                  window.open("https://youtu.be/JPuac7h0dE8", "_blank")
                }}>
                  <div style={{fontSize:"3vw", padding:"2vw 2.5vw 2vw 2.5vw"}}>
                    Ver directo
                  </div>
                  
                </button>
              </div>
              <div className="flex flex-row w-3/4 items-center gap-3">
                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">16:30</div>
                <div>Cata a ciegas</div>
              </div>
              <div className="flex flex-row w-3/4 items-center gap-3">
                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">17:00</div>
                <div>Guía Siente Teruel</div>
              </div>
              <div className="justify-center">
                <button className="border-2 border-red-600 bg-red-600 text-white rounded-full" 
                onClick={()=>{
                  window.open("https://youtu.be/zlfiz-9dOlY", "_blank")
                }}>
                  <div style={{fontSize:"3vw", padding:"2vw 2.5vw 2vw 2.5vw"}}>
                    Ver directo
                  </div>
                  
                </button>
              </div>
              <div className="flex flex-row w-3/4 items-center gap-3">
                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">17:30</div>
                <div>Dos lenguajes y una experiencia única</div>
              </div>
              <div className="justify-center">
                <button className="border-2 border-red-600 bg-red-600 text-white rounded-full" 
                onClick={()=>{
                  window.open("https://youtu.be/VQWX2rUwVQ8", "_blank")
                }}>
                  <div style={{fontSize:"3vw", padding:"2vw 2.5vw 2vw 2.5vw"}}>
                    Ver directo
                  </div>
                  
                </button>
              </div>
              <div className="flex flex-row w-3/4 items-center gap-3">
                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">17:30</div>
                <div>Un destino una cocina: Zaragoza</div>
              </div>
              <div className="flex flex-row w-3/4 items-center gap-3">
                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">18:00</div>
                <div>Cata Enoturismo Aragón</div>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-center items-center">
            <div className="flex w-4/5 flex-col mb-4">
              <img
                className="rounded-lg"
                src={Viernes}
                alt="Inicio"
              />
            </div>
            
            <div className="flex w-full flex-col justify-center items-center gap-2 font-montserrat-normal" style={{ fontSize: "3vw" }}>
              <div className="justify-center">
                <button className="border-2 border-red-600 bg-red-600 text-white rounded-full" 
                onClick={()=>{
                  window.open("https://youtu.be/Ay5gx3KfjoA", "_blank")
                }}>
                  <div style={{fontSize:"3vw", padding:"2vw 2.5vw 2vw 2.5vw"}}>
                    Ver directo de todo el día
                  </div>
                  
                </button>
              </div>
              <div className="flex flex-row w-3/4 items-center gap-3 align-items">
                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">11:30</div>
                <div>Recorrido Institucional</div>
              </div>
              <div className="flex flex-row w-3/4 items-center gap-3">
                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">12:00</div>
                <div>Presentación de Goya</div>
              </div>
              <div className="flex flex-row w-3/4 items-center gap-3">
                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">12:05</div>
                <div>Bienvenida a cargo de la Directora General de Turismo, Elena Allué</div>
              </div>
              <div className="flex flex-row w-3/4 items-center gap-3">
                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">12:25</div>
                <div>Goya: Intervención artística</div>
              </div>
              <div className="flex flex-row w-3/4 items-center gap-3">
                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">12:30</div>
                <div>Presentación del Camino de Santiago</div>
              </div>
              <div className="flex flex-row w-3/4 items-center gap-3">
                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">12:45</div>
                <div>Discurso del Vicepresidente de Aragón, Arturo Aliaga</div>
              </div>
              <div className="flex flex-row w-3/4 items-center gap-3">
                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">13:00</div>
                <div>Presentación Enoturismo: Vinos de Aragón</div>
              </div>
              <div className="flex flex-row w-3/4 items-center gap-3">
                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">13:15</div>
                <div>Cata a ciegas y experiencia culinaria</div>
              </div>
              <div className="flex flex-row w-3/4 items-center gap-3">
                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">16:00</div>
                <div>Ruta del vino de Somontano. No te lo puedes perder</div>
              </div>
              <div className="flex flex-row w-3/4 items-center gap-3">
                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">16:30</div>
                <div>Aragón Incoming</div>
              </div>
              <div className="flex flex-row w-3/4 items-center gap-3">
                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">17:30</div>
                <div>Un destino una cocina</div>
              </div>
              <div className="flex flex-row w-3/4 items-center gap-3">
                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">18:00</div>
                <div>Cata Enoturismo Aragón</div>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-center items-center">
            <div className="flex w-4/5 flex-col mb-4">
              <img
                className="rounded-lg"
                src={Sabado}
                alt="Inicio"
              />
            </div>
            <div className="flex w-full flex-col justify-center items-center gap-2 font-montserrat-normal" style={{ fontSize: "3vw" }}>
              <div className="flex flex-row w-3/4 items-center gap-3 align-items">
                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">12:30</div>
                <div>Cata a ciegas</div>
              </div>
              <div className="flex flex-row w-3/4 items-center gap-3">
                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">13:00</div>
                <div>Un destino una cocina: Huesca</div>
              </div>
              <div className="flex flex-row w-3/4 items-center gap-3">
                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">13:30</div>
                <div>Cata Enoturismo Aragón</div>
              </div>
              <div className="flex flex-row w-3/4 items-center gap-3">
                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">14:00</div>
                <div>Un destino una cocina: Zaragoza</div>
              </div>
              <div className="flex flex-row w-3/4 items-center gap-3">
                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">16:30</div>
                <div>Cata a ciegas</div>
              </div>
              <div className="flex flex-row w-3/4 items-center gap-3">
                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">17:30</div>
                <div>Un destino una cocina: Teruel</div>
              </div>
              <div className="flex flex-row w-3/4 items-center gap-3">
                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">18:00</div>
                <div>Cata Enoturismo Aragón</div>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-center items-center">
            <div className="flex w-4/5 flex-col mb-4">
              <img
                className="rounded-lg"
                src={Domingo}
                alt="Inicio"
              />
            </div>
            <div className="flex w-full flex-col justify-center items-center gap-2 font-montserrat-normal" style={{ fontSize: "3vw" }}>
              <div className="flex flex-row w-3/4 items-center gap-3 align-items">
                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">12:30</div>
                <div>Cata a ciegas</div>
              </div>
              <div className="flex flex-row w-3/4 items-center gap-3">
                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">13:00</div>
                <div>Un destino una cocina: Teruel</div>
              </div>
              <div className="flex flex-row w-3/4 items-center gap-3">
                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">13:30</div>
                <div>Cata Enoturismo Aragón</div>
              </div>
              <div className="flex flex-row w-3/4 items-center gap-3">
                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">14:00</div>
                <div>Un destino una cocina: Huesca</div>
              </div>
              {/* <div className="flex flex-row w-3/4 items-center gap-3">
                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">16:30</div>
                <div>Cata a ciegas</div>
              </div> */}
              <div className="flex flex-row w-3/4 items-center gap-3">
                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">16:30</div>
                <div>Un destino una cocina: Zaragoza</div>
              </div>
              {/* <div className="flex flex-row w-3/4 items-center gap-3">
                <div className="font-montserrat-bold w-6 md:w-16 lg:w-24">18:00</div>
                <div>Cata Enoturismo Aragón</div>
              </div> */}
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default Repositorio;
