import React, { CSSProperties, useContext, useEffect, useRef } from "react";
import { isMobile, log } from "../../utilities/utils";
import useStore from "../../stores/store";
import booth1 from "../assets/images/booth1.png";
import booth2 from "../assets/images/booth2.png";
import booth3 from "../assets/images/booth3.png";
import booth4 from "../assets/images/booth4.png";
import booth5 from "../assets/images/booth5.png";
import Mode2D from "../assets/images/mode_2d.svg";
import Mode3D from "../assets/images/mode_3d.svg";
import panelSmall from "../assets/images/panelSmall.png";
import panelLarge from "../assets/images/panelLarge.png";
import conferenceRoom from "../assets/images/conferenceRoom.png";
import { AnimatePresence, motion } from "framer-motion";
import { useHistory } from "react-router-dom";
import AwesomeSlider from "react-awesome-slider";
import "react-awesome-slider/dist/styles.css";
import arrow from "../assets/images/flecha_linea.svg";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { StreamingContext } from "../streaming/StreamingContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { isEmpty, isNull, isUndefined } from "lodash";
import FiturLogo from "../../assets/images/SVG/fitur_logo_txt.svg";
import Space3d from "../../assets/images/SVG/3d.svg";
import Directo from "../../assets/images/SVG/directo.svg";
import Repositorio from "../../assets/images/SVG/repositorio.svg";
import Agenda from "../../assets/images/SVG/agenda.svg";
import "../../styles/custom.css";

const SceneSelector: React.FC = () => {
  const history = useHistory();

  const setActiveScene = useStore((state) => state.setActiveScene);

  const setVisualizationMode3D = useStore(
    (state) => state.setVisualizationMode3D
  );

  const visualizationMode3D = useStore<boolean>(
    (state) => state.visualizationMode3D
  );

  const activeScene = useStore<number>((state) => state.activeScene);

  /** @see {@tutorial https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state} */
  function usePrevious<T>(value: T) {
    const ref = useRef<T>();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current as T;
  }

  const setShowLoadingScreen = useStore((state) => state.setShowLoadingScreen);

  //const streamingLive = useStore<boolean>((state) => state.streamingLive);

  const { isStreamingOn } = useContext(StreamingContext);

  function LoadScene(scene: number) {
    log("Button Scene " + scene + " Clicked!");

    setActiveScene(scene);

    visualizationMode3D
      ? history.push("/virtualspace")
      : history.push("/virtualspacesimplified");

    setShowLoadingScreen(true);
  }

  useEffect(() => {
    if (activeScene !== 0) {
      setActiveScene(0);
    }
  });

  const visualizationMode3DKey = useStore<string>(
    (state) => state.visualizationMode3DKey
  );

  // const setShowModalVideoWelcome = useStore(
  //   (state) => state.setShowModalVideoWelcome
  // );
  // const setModalVideoWelcomeTitle = useStore(
  //   (state) => state.setModalVideoWelcomeTitle
  // );
  // const setModalVideoWelcomeVideoUrl = useStore(
  //   (state) => state.setModalVideoWelcomeVideoUrl
  // );

  // useEffect(() => {
  //   if (isNull(localStorage.getItem("firstTime"))) {
  //     setModalVideoWelcomeTitle(
  //       "I CONGRESO VIRTUAL PARA AUXILIARES CLÍNICOS DE VETERINARIA"
  //     );
  //     setModalVideoWelcomeVideoUrl("https://www.youtube.com/embed/98lIFrNP7H4");
  //     setShowModalVideoWelcome(true);
  //   }
  // }, []);

  return !isMobile() ? (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className={`flex flex-col ${isMobile() ? "gap-4" : "flex-col gap-8"} w-full justify-center items-center text-center`}
        style={{ backgroundColor: "#f2f2f2" }}
      >
        <div className={`flex text-center justify-center`}>
          <div className="flex flex-col">
            {isMobile() ? (
              <div className="-mt-4 mb-2">
                <img
                  style={{ height: "25vw" }}
                  src={FiturLogo}
                  alt="Menu Main"
                />
              </div>

            ) : (
              <div className="">
                <img
                  style={{ height: "10vw" }}
                  src={FiturLogo}
                  alt="Menu Main"
                />
              </div>
            )}

          </div>
        </div>
        <div
          className={`flex w-1/2 text-center justify-center items-center`}
        >
          <div className={`flex ${isMobile() ? "flex-col" : "flex-col gap-4"}`}>
            <div
              className={`flex ${isMobile() ? "flex-col" : "flex-col gap-4"
                } justify-center items-center`}
            >
              <button
                className={`flex w-full border rounded shadow-md transform hover:-translate-y-1 hover:shadow-xl duration-500`}
                style={{ backgroundColor: "#e1f0ec" }}
                onClick={() => {
                  history.push("/virtualspace");
                  setShowLoadingScreen(true);
                }}
              >
                <div
                  className={`flex flex-row w-full items-center fill-current text-black`}
                >
                  <div className={`flex justify-center items-center`} >
                    <img
                      style={{ width: "6vw", paddingLeft: "1vw", paddingRight: "1vw" }}
                      src={Space3d}
                      alt="3d imagen"
                    />
                  </div>
                  <div className="bg-white h-full w-full">
                    <div className={`flex flex-col`} style={{ padding: "1vw 2vw 1vw 2vw" }}>
                      <div className="flex justify-start">
                        <p
                          className={"font-montserrat-bold"}
                          style={{ fontSize: "2vw" }}
                        >
                          Entorno 3D
                      </p>
                      </div>
                      {!isMobile() && (
                        <div>
                          <p className={`text-lg font-montserrat-normal text-left`} style={{ fontSize: "1vw" }}>Explora nuestro entorno virtual 3D y accede a la retransmisión en vivo</p>
                        </div>
                      )}

                    </div>
                  </div>

                </div>
              </button>

              <button
                className={`flex w-full border rounded shadow-md transform hover:-translate-y-1 hover:shadow-xl duration-500`}
                style={{ backgroundColor: "#e1f0ec" }}
                onClick={() => {
                  history.push("/virtualspacesimplified");
                  setShowLoadingScreen(true);
                }}
              >
                <div
                  className={`flex flex-row w-full items-center fill-current text-black`}
                >
                  <div className={`flex justify-center items-center`} >
                    <img
                      style={{ width: "6vw", paddingLeft: "1vw", paddingRight: "1vw" }}
                      src={Directo}
                      alt="3d imagen"
                    />
                  </div>
                  <div className="bg-white h-full w-full">
                    <div className={`flex flex-col`} style={{ padding: "1vw 2vw 1vw 2vw" }}>
                      <div className="flex justify-start">
                        <p
                          className={"font-montserrat-bold"}
                          style={{ fontSize: "1.7vw" }}
                        >
                          Evento en directo
                      </p>
                      </div>
                      {!isMobile() && (
                        <div>
                          <p className={`text-lg font-montserrat-normal text-left`} style={{ fontSize: "0.9vw" }}>Accede directamente a la retransmisión en vivo</p>
                        </div>
                      )}

                    </div>
                  </div>
                </div>
              </button>
              <div
                className={`flex ${isMobile() ? "flex-row gap-1" : "flex-row gap-4"
                  } items-stretch`}
              >
                <button
                  className={`flex w-full border rounded shadow-md ${!isMobile() ? "transform hover:-translate-y-1 hover:shadow-xl duration-500" : null}`}
                  style={{ backgroundColor: "#e1f0ec"}}
                  onClick={() => {
                    history.push("/repositorio");
                    setShowLoadingScreen(true);
                  }}
                >
                  <div
                    className={`flex flex-row w-full items-center fill-current text-black`}
                  >
                    <div className={`flex justify-center items-center`} >
                      <img
                        style={{ width: "5vw", paddingLeft: "1vw", paddingRight: "1vw" }}
                        src={Repositorio}
                        alt="3d imagen"
                      />
                    </div>
                    <div className="bg-white h-full w-full">
                      <div className={`flex flex-col`} style={{ padding: "0.5vw 2vw 0.5vw 2vw" }}>
                        <div className="flex justify-start">
                          <p
                            className={"font-montserrat-bold"}
                            style={{ fontSize: "1.3vw" }}
                          >
                            Ver Presentaciones
                          </p>
                        </div>
                        {!isMobile() && (
                          <div>
                            <p className={`text-lg font-montserrat-normal text-left`} style={{ fontSize: "0.8vw" }}>Streamings de los días anteriores</p>
                          </div>
                        )}

                      </div>
                    </div>

                  </div>
                </button>
                <button
                  className={`flex w-full border rounded shadow-md transform hover:-translate-y-1 hover:shadow-xl duration-500`}
                  style={{ backgroundColor: "#e1f0ec" }}
                  onClick={() => {
                    history.push("/agenda");
                    setShowLoadingScreen(true);
                  }}
                >
                  <div
                    className={`flex flex-row w-full items-center fill-current text-black`}
                  >
                    <div className={`flex justify-center items-center`} >
                      <img
                        style={{ width: "5vw", paddingLeft: "1vw", paddingRight: "1vw" }}
                        src={Agenda}
                        alt="3d imagen"
                      />
                    </div>
                    <div className="bg-white h-full w-full">
                      <div className={`flex flex-col`} style={{ padding: "0.5vw 2vw 0.5vw 2vw" }}>
                        <div className="flex justify-start">
                          <p
                            className={"font-montserrat-bold"}
                            style={{ fontSize: "1.3vw" }}
                          >
                            Agenda
                          </p>
                        </div>
                        {!isMobile() && (
                          <div>
                            <p className={`text-lg font-montserrat-normal text-left`} style={{ fontSize: "0.8vw" }}>Consulta los eventos programados</p>
                          </div>
                        )}

                      </div>
                    </div>

                  </div>
                </button>
              </div>
            </div>
            {/* <div className={`mb-32`}></div> */}
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  ) : (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className={`flex flex-col ${isMobile() ? "gap-4" : "flex-col gap-8"} w-full justify-center items-center text-center`}
        style={{ backgroundColor: "#f2f2f2" }}
      >
        <div className={`flex text-center justify-center`}>
          <div className="flex flex-col">
            {isMobile() ? (
              <div className="-mt-4 mb-2">
                <img
                  style={{ height: "25vw" }}
                  src={FiturLogo}
                  alt="Menu Main"
                />
              </div>
            ) : (
              <div className="">
                <img
                  style={{ height: "10vw" }}
                  src={FiturLogo}
                  alt="Menu Main"
                />
              </div>
            )}

          </div>
        </div>
        <div
          className={`flex ${isMobile() ? "w-5/6 md:w-5/6 " : "w-1/2"
            }  text-center justify-center items-center`}
        >
          <div className={`flex ${isMobile() ? "flex-col gap-4" : "flex-col "}`}>
            <div
              className={`flex ${isMobile() ? "flex-col gap-4" : "flex-col"
                } justify-center items-center`}
            >
              <button
                className={`flex w-full border rounded shadow-md ${!isMobile() ? "transform hover:-translate-y-1 hover:shadow-xl duration-500" : null}`}
                style={{ backgroundColor: "#e1f0ec" }}
                onClick={() => {
                  history.push("/virtualspace");
                  setShowLoadingScreen(true);
                }}
              >
                <div
                  className={`flex flex-row w-full items-center fill-current text-black`}
                >
                  <div className={`flex justify-center items-center ${isMobile() ? "px-2 md:px-4 lg:px-6" : " px-4"}`} >
                    <img
                      className={` ${isMobile() ? " w-12 h-12 md:w-16 md:h-16 lg:w-20 lg:h-20" : " w-24 h-24"}`}
                      src={Space3d}
                      alt="3d imagen"

                    />
                  </div>
                  <div className="bg-white h-full w-full">
                    <div className={`flex flex-col ${isMobile() ? "py-3 px-2 md:px-4 md:py-4 lg:px-6 lg:py:6" : "py-6 px-4"}`}>
                      <div className="flex justify-start">
                        <p
                          className={`${isMobile() ? "text-lg md:text-3xl lg:text-5xl" : "text-3xl"
                            } font-montserrat-bold`}
                        >
                          Entorno 3D
                      </p>
                      </div>
                      {!isMobile() && (
                        <div>
                          <p className={`${isMobile() ? "text-sm md:text-xl lg:text-3xl" : "text-lg"
                            } font-montserrat-normal text-left`}>Explora nuestro entorno virtual 3D y accede a la retransmisión en vivo</p>
                        </div>
                      )}

                    </div>
                  </div>

                </div>
              </button>
              <button
                className={`flex w-full border rounded shadow-md ${!isMobile() ? "transform hover:-translate-y-1 hover:shadow-xl duration-500" : null}`}
                style={{ backgroundColor: "#e1f0ec" }}
                onClick={() => {
                  history.push("/virtualspacesimplified");
                  setShowLoadingScreen(true);
                }}
              >
                <div
                  className={`flex flex-row w-full items-center fill-current text-black`}
                >
                  <div className={`flex justify-center items-center ${isMobile() ? "px-2 md:px-4 lg:px-6" : " px-4"}`} >
                    <img
                      className={` ${isMobile() ? " w-12 h-12 md:w-16 md:h-16 lg:w-20 lg:h-20" : " w-24 h-24"}`}
                      src={Directo}
                      alt="3d imagen"

                    />
                  </div>
                  <div className="bg-white h-full w-full">
                    <div className={`flex flex-col ${isMobile() ? " py-3 px-2 md:py-4 md:px-4 lg:px-6 lg:py-6" : "py-6 px-4"}`}>
                      <div className="flex justify-start">
                        <p
                          className={`${isMobile() ? "text-base md:text-2xl lg:text-4xl" : "text-2xl"
                            } font-montserrat-bold`}
                        >
                          Evento en directo
                        </p>
                      </div>
                      {!isMobile() && (
                        <div>
                          <p className={`${isMobile() ? "text-sm md:text-lg lg:text-2xl" : "text-base"
                            } font-montserrat-normal text-left`}>Accede directamente a la retransmisión en vivo</p>
                        </div>
                      )}
                    </div>
                  </div>

                </div>
              </button>
            </div>
            <div
              className={`flex ${isMobile() ? "flex-row gap-1" : "flex-row gap-2"
                }`}
            >
              <button
                className={`flex w-3/5 border rounded shadow-md ${!isMobile() ? "transform hover:-translate-y-1 hover:shadow-xl duration-500" : null}`}
                style={{ backgroundColor: "#e1f0ec"}}
                onClick={() => {
                  history.push("/repositorio");
                  setShowLoadingScreen(true);
                }}
              >
                <div
                  className={`flex flex-row w-full items-center fill-current text-black`}
                >
                  <div className={`flex justify-center items-center ${isMobile() ? "px-1 md:px-4 lg:px-6" : " px-4"}`} >
                    <img
                      className={` ${isMobile() ? " w-7 h-6 md:w-12 md:h-12 lg:w-16 lg:h-16" : " w-12 h-12"}`}
                      src={Repositorio}
                      alt="3d imagen"

                    />
                  </div>
                  <div className="bg-white h-full w-full">
                    <div className={`flex flex-col ${isMobile() ? "py-3 px-2 md:py-3 md:px-8 lg:px-6 lg:py-4" : " py-4 px-4"} `}>
                      <div className="flex justify-start">
                        <p
                          className={`${isMobile() ? "md:text-lg lg:text-2xl" : "text-lg"
                            } font-montserrat-bold`}
                          style={{fontSize:"2.8vw"}}
                        >
                          Ver Presentaciones
                        </p>
                      </div>

                      {isMobile() ? (
                        <></>
                        // <div>
                        //   <p className={`${isMobile() ? "text-xs md:text-sm lg:text-base" : "text-sm"
                        //     } font-montserrat-normal text-left`}
                        //     style={{ whiteSpace: "nowrap" }}>Streamings de</p>
                        //   <p className={`${isMobile() ? "text-xs md:text-sm lg:text-base" : "text-sm"
                        //     } font-montserrat-normal text-left`}
                        //     style={{ whiteSpace: "nowrap" }}>los días</p>
                        //   <p className={`${isMobile() ? "text-xs md:text-sm lg:text-base" : "text-sm"
                        //     } font-montserrat-normal text-left`}
                        //     style={{ whiteSpace: "nowrap" }}>anteriores</p>
                        // </div>
                      ) : (
                        <p className={`${isMobile() ? "text-xs md:text-sm lg:text-base" : "text-sm"
                          } font-montserrat-normal text-left`}
                        >Streamings de los días anteriores</p>
                      )}

                    </div>
                  </div>

                </div>
              </button>
              <button
                className={`flex w-2/5 border rounded shadow-md ${!isMobile() ? "transform hover:-translate-y-1 hover:shadow-xl duration-500" : null}`}
                style={{ backgroundColor: "#e1f0ec" }}
                onClick={() => {
                  history.push("/agenda");
                  setShowLoadingScreen(true);
                }}
              >
                <div
                  className={`flex flex-row w-full items-center fill-current text-black`}
                >
                  <div className={`flex justify-center items-center ${isMobile() ? "px-1 md:px-4 lg:px-6" : "px-4"}`} >
                    <img
                      className={` ${isMobile() ? " w-10 h-10 md:w-12 md:h-12 lg:w-16 lg:h-16" : " w-12 h-12"}`}
                      src={Agenda}
                      alt="3d imagen"

                    />
                  </div>
                  <div className="bg-white h-full w-full">
                    <div className={`flex flex-col ${isMobile() ? "px-2 py-3 md:px-8 md:py-3 lg:px-6 lg:py-4" : "px-4 py-4"}`}>
                      <div className="flex justify-start">
                        <p
                          className={`${isMobile() ? "md:text-lg lg:text-2xl" : "text-lg"
                            } font-montserrat-bold`}
                          style={{fontSize:"2.8vw"}}

                        >
                          Agenda
                        </p>
                      </div>
                      {isMobile() ? (
                        <></>
                        // <div>
                        //   <p className={`${isMobile() ? "text-xs md:text-sm lg:text-base" : "text-sm"
                        //     } font-montserrat-normal text-left`}
                        //     style={{ whiteSpace: "nowrap" }}>Consulta los</p>
                        //   <p className={`${isMobile() ? "text-xs md:text-sm lg:text-base" : "text-sm"
                        //     } font-montserrat-normal text-left`}
                        //     style={{ whiteSpace: "nowrap" }}>eventos</p>
                        //   <p className={`${isMobile() ? "text-xs md:text-sm lg:text-base" : "text-sm"
                        //     } font-montserrat-normal text-left`}
                        //     style={{ whiteSpace: "nowrap" }}>programados</p>
                        // </div>
                      ) : (
                        <p className={`${isMobile() ? "text-xs md:text-sm lg:text-base" : "text-sm"
                          } font-montserrat-normal text-left`}
                        >Consulta los eventos programados</p>
                      )}

                    </div>
                  </div>

                </div>
              </button>
            </div>
            {/* <div className={`mb-32`}></div> */}
          </div>
        </div>
      </motion.div>
    </AnimatePresence>







  );
};

export default SceneSelector;