import React, { ReactSVGElement } from "react";
import { isMobile } from "../../../utilities/utils";

interface InstructionTipProp {
  imgKey: any;
  // ImgAction: JSX.Element;
  imgHeight?: number;
  lblAction: string;
  txtAction?: string;
  className?: string;
}

const InstructionTip: React.FC<InstructionTipProp> = ({
  imgKey,
  // ImgAction,
  imgHeight = 12,
  lblAction,
  txtAction,
  className,
  children,
}) => {
  return (
    <div className={`${className} flex flex-col items-center`}>
      {/* <img
        className={`h-${imgHeight} fill-current stroke-current text-red-600`}
        src={imgKey}
        alt=""
      /> */}
      <div className={`flex items-center h-${imgHeight}`}>{imgKey}</div>
      <div className="mt-2 flex items-center">
        {children}
        {/* <img className="mr-2 w-3 text-3xs" src={imgAction} alt="" /> */}
        <p
          className={`${
            isMobile() ? "text-sm" : "text-base"
          }  text-rc-dark-gray`}
        >
          {lblAction}
        </p>
      </div>
      {txtAction && txtAction !== "" && (
        <p className={`text-xs text-center text-rc-dark-gray`}>{txtAction}</p>
      )}
    </div>
  );
};

export default InstructionTip;
