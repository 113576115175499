import React, { Suspense, useEffect } from "react";
import useStore from "../../../stores/store";
import SceneConferenceRoom2D from "../../scenes/SceneConferenceRoom2D";

function SceneFallback() {
  const sceneHasLoaded = useStore((state) => state.sceneHasLoaded);

  // We wait until the fallback is unmounted + 1 second to mark the scene as loaded
  useEffect(() => {
    return () => {
      setTimeout(() => {
        sceneHasLoaded();
      }, 1000);
    };
  }, [sceneHasLoaded]);

  return null;
}

function SceneSimplified() {
  return (
    <Suspense fallback={<SceneFallback />}>
      <SceneConferenceRoom2D />
    </Suspense>
  );
}

export default SceneSimplified;
