import React, { useEffect, useRef } from "react";
import Scene from "../../three/common/Scene";
import useStore from "../../../stores/store";
import { isMobile } from "../../../utilities/utils";
import Crosshair from "../../user_interface/layouts/Crosshair";
import DesktopUI from "../../user_interface/devices/DesktopUI";
import Footer from "../../user_interface/layouts/Footer";
import MobileUI from "../../user_interface/devices/MobileUI";
import SceneSimplified from "./SceneSimplified";
import { useHistory } from "react-router-dom";

const VirtualSpaceSimplified = () => {
  const activeScene = useStore<number>((state) => state.activeScene);

  return (
    <div className="absolute inset-0">
      <SceneSimplified />
    </div>
  );
};

export default VirtualSpaceSimplified;
