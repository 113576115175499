import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useThree, useFrame } from "react-three-fiber";
import { useBox } from "use-cannon";
import PlayerBehaviour from "../../../library/PlayerBehaviour";
import { isMobile, log } from "../../../utilities/utils";
import useStore from "../../../stores/store";

interface PlayerControllerProps {
  height: number;
}

const PlayerController: React.FC<PlayerControllerProps> = ({ height }) => {
  const { camera, gl } = useThree();
  const [bodyRef, bodyApi] = useBox(() => ({
    mass: 1,
    args: [0.25, height, 0.25],
    position: startPos,
    material: { friction: 0 },
    fixedRotation: true,
    allowSleep: false,
  }));
  const playerBehaviour = useStore<PlayerBehaviour>(
    (state) => state.playerBehaviour
  );

  const activeScene = useStore<number>((state) => state.activeScene);

  const startPos = useMemo(() => [-8, 0, 0], [
    activeScene,
  ]);
  const startRot = useMemo(() => [0, -Math.PI, 0], [
    activeScene,
  ]);

  useEffect(() => {
    log("Init Player Behaviour");
    playerBehaviour.init(350, camera, gl.domElement, isMobile());

    bodyApi.position.set(startPos[0], startPos[1], startPos[2]);
    camera.rotation.set(startRot[0], startRot[1], startRot[2]);

    return () => {
      log("Clearing Player Listeners");
      playerBehaviour.removeDesktopListeners();
    };
  }, [
    playerBehaviour,
    camera,
    gl,
    activeScene,
    bodyApi.position,
    startPos,
    startRot,
  ]);

  useEffect(() => {
    camera.rotation.set(startRot[0], startRot[1], startRot[2]);
  }, [camera, startRot]);

  useEffect(() => {
    bodyApi.position.subscribe((pos) =>
      camera.position.set(pos[0], height, pos[2])
    );
  }, [bodyApi, camera, height]);

  useFrame((_, delta) => {
    const vel = playerBehaviour.getPlayerVelocity().multiplyScalar(delta);
    bodyApi.velocity.copy(vel);
  });

  return (
    <mesh ref={bodyRef} visible={false}>
      <boxBufferGeometry attach="geometry" args={[0.5, height, 0.5]} />
      <meshBasicMaterial attach="material" color="blue" />
    </mesh>
  );
};

export default PlayerController;
