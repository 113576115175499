import React from "react";

import tipKeys from "../../assets/images/arrows.svg";
import SvgMover from "../../assets/images/arrows_icon.svg";
import tipMouse from "../../assets/images/mouse.svg";
import SvgGirar from "../../assets/images/mouse_icon.svg";
import tipEsc from "../../assets/images/escape.svg";
import SvgSalir from "../../assets/images/escape_icon.svg";
import InstructionTip from "./InstructionTip";
import { isMobile } from "../../../utilities/utils";
import Arrows from "../../../assets/images/SVG/avanzar_teclas.svg";
import Mouse from "../../../assets/images/SVG/girar_raton.svg";
import Esc from "../../../assets/images/SVG/verpuntero_esc.svg";

interface InstructionsProps {
  showDescriptions?: boolean;
}

const Instructions: React.FC<InstructionsProps> = ({ showDescriptions }) => {
  return (
    <div className="flex pt-2 w-full">
      <InstructionTip
        className={isMobile() ? "flex w-1/3 ml-4 mr-4" : "ml-4 mr-4"}
        imgKey={
          <img
            style={{
              height: "100%",
              objectFit: "contain",
            }}
            src={Arrows}
            alt="Arrows"
          />
        }
        imgHeight={isMobile() ? 6 : showDescriptions ? 12 : 8}
        lblAction={"Mover"}
        txtAction={
          showDescriptions ? "Utiliza las flechas del teclado para moverte" : ""
        }
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 18.26 18.26"
          className="mr-2 w-3 text-3xs h-4"
          style={{ fill: "#1D1D1B" }}
        >
          <g data-name="Capa 2">
            <g data-name="Capa 1">
              <path d="M8.68.66h1v16.25h-1z" />
              <path d="M1.05 8.28H17.3v1H1.05zM11.62 3.91l-2.49-2.5-2.5 2.5-.7-.71L9.13 0l3.2 3.2-.71.71zM9.13 18.26l-3.2-3.21.7-.7 2.5 2.49 2.49-2.49.71.7-3.2 3.21z" />
              <path d="M15.05 12.33l-.7-.71 2.49-2.49-2.49-2.5.7-.7 3.21 3.2-3.21 3.2zM3.2 12.33L0 9.13l3.2-3.2.71.7-2.5 2.5 2.5 2.49-.71.71z" />
            </g>
          </g>
        </svg>
      </InstructionTip>

      <InstructionTip
        className={isMobile() ? "flex w-1/3 ml-4 mr-4" : "ml-4 mr-4"}
        imgKey={
          <img
            style={{
              height: "100%",
              objectFit: "contain",
            }}
            src={Mouse}
            alt="Puntero"
          />
        }

        imgHeight={isMobile() ? 6 : showDescriptions ? 12 : 8}
        lblAction={"Girar"}
        txtAction={
          showDescriptions ? "Mueve el ratón para cambiar la vista" : ""
        }
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 13.15 16.43"
          className="mr-2 w-3 text-3xs h-4"
          style={{
            fill: "#ae2b42",
          }}
        >
          <g data-name="Capa 2">
            <g data-name="Capa 1">
              <path d="M6.57 16.43a6.58 6.58 0 010-13.15h2.9v1h-2.9a5.58 5.58 0 105.58 5.57h1a6.59 6.59 0 01-6.58 6.58z" />
              <path d="M6.39 7.56l-.7-.71 3.07-3.07L5.69.71l.7-.71 3.78 3.78-3.78 3.78z" />
            </g>
          </g>
        </svg>
      </InstructionTip>

      <InstructionTip
        className={isMobile() ? "flex w-1/3 ml-4 mr-4" : "ml-4 mr-4"}
        imgKey={
          <img
            style={{
              height: "100%",
              objectFit: "contain",
            }}
            src={Esc}
            alt="Arrows"
          />
        }
        imgHeight={isMobile() ? 6 : showDescriptions ? 12 : 8}
        lblAction={"Ver puntero"}
        txtAction={
          showDescriptions ? "Recupera el puntero para interactuar" : ""
        }
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 11.69 19.6"
          className="mr-2 w-3 text-3xs h-4"
          style={{
            fill: "#ae2b42",
          }}
        >
          <g data-name="Capa 2">
            <path
              d="M5.46 19.6l-1.69-5.07L0 17.07.81 0l10.88 13.17-4.64.27 1.69 5.07zM4.31 13l1.79 5.33 1.37-.45-1.78-5.37 4-.22-8-9.66-.6 12.5z"
              data-name="Capa 1"
            />
          </g>
        </svg>
      </InstructionTip>
    </div>
  );
};

export default Instructions;
